import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { useLocation, Redirect } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import "moment/locale/es";
import { Container } from "@material-ui/core";
import Moment from "react-moment";
import cx from "clsx";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { getCursosUsuario } from "../../../../services/database/usuario";
import { getCurrentUser } from "../../../../services/auth";
import { getUsuario } from "../../../../services/database/v3/usuario";
import { getZoomLink } from "../../../../services/database/cursos";
import CursoView from "./CursoView";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";

//const tech = require('../../img/teaching.png');

import tech from "../../../../img/logo_2.jpg";
import { loadConfig } from "../../../../services/database/v3/config";

const useStyles = makeStyles(({ spacing, palette }) => {
  const family =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
  return {
    root: {
      maxWidth: 345,
    },
    card: {
      display: "flex",
      padding: spacing(2),
      // minWidth: 288,
      borderRadius: 25,
      boxShadow: "0 2px 4px 0 rgba(138, 148, 159, 0.2)",
      "& > *:nth-child(1)": {
        marginRight: spacing(2),
      },
      "& > *:nth-child(2)": {
        flex: "auto",
      },
    },
    avatar: {},
    heading: {
      // fontFamily: family,
      fontSize: 16,
      marginBottom: 0,
    },
    subheader: {
      fontFamily: family,
      fontSize: 14,
      color: palette.grey[600],
      letterSpacing: "1px",
      marginBottom: 4,
    },
    value: {
      marginLeft: 8,
      fontSize: 14,
      color: palette.grey[500],
    },
  };
});
const useSliderStyles = makeStyles(() => ({
  root: {
    height: 4,
  },
  rail: {
    borderRadius: 10,
    height: 4,
    backgroundColor: "rgb(202,211,216)",
  },
  track: {
    borderRadius: 10,
    height: 4,
    backgroundColor: "rgb(117,156,250)",
  },
  thumb: {
    display: "none",
  },
}));

function AcesoAsistentes(props) {
  const classes = useStyles();
  const styles = useStyles();
  const sliderStyles = useSliderStyles();
  let location = useLocation();
  const [cursos, setCursos] = useState(null);
  const [user, setUser] = useState(null);
  const [hayCursoSence, setHayCursoSence] = useState(false);
  const [config, setConfig] = useState(null);
  const {
    rutUsurio,
    nombreUsuario,
    userID,
    correoUsuario,
    apellido,
    isCursoSense,
  } = props;

  const loadData = async () => {
    let resultConfig = await loadConfig();
    setConfig(resultConfig);
    let user = await getCurrentUser();
    let listadoCursos = await getCursosUsuario(user.claims.user_id);
    console.log(listadoCursos);
    if (listadoCursos) {
      setCursos(listadoCursos);
      if (
        !(
          typeof listadoCursos.find(
            (e) => e.is_sence === true && e.estado !== "no_visible"
          ) === "undefined"
        )
      ) {
        setHayCursoSence(true);
      }

      console.log("hay cursos sense", hayCursoSence);
      let userObj = await getUsuario(user.claims.user_id);
      console.log(userObj);
      setUser(userObj);
    }
  };

  const onClickZoom = (e) => {
    console.log(e);
    e.then((result) => {
      console.log(result);
      window.open(
        result + "&uname=" + user.nombre + " " + user.apellidos,
        "_blank"
      );
    });
  };

  const onClickDeclaracion = () => {
    window.location = "https://lce.sence.cl/CertificadoAsistencia/";
  };
  useEffect(() => {
    loadData();
  }, []);

  console.log(cursos);
  const preventDefault = (event) => event.preventDefault();

  return (
    <Fragment>
      <Container>
        <Container fixed>
          <Grid container spacing={3}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={1}
              alignItems="flex-start"
            >
              <Grid item md={3}>
                <Card
                  className={cx(styles.card)}
                  style={{ borderRadius: 25 }}
                  elevation={3}
                >
                  {/* <Avatar src={'https://i.pravatar.cc/300'} className={styles.avatar} /> */}
                  <Box>
                    <h3 className={styles.heading}>Hola {user?.nombre}</h3>
                    <div>
                      <p className={styles.heading}> Tu RUT es </p>
                      <p className={styles.subheader}> {user?.rut}</p>
                    </div>
                    <p className={styles.heading}>Tu email es</p>
                    <p className={styles.subheader}> {user?.email}</p>
                    <br />
                    <Divider variant="middle" />
                    <br />
                    {
                      <div>
                        <Typography>Comunícate con nosotros</Typography>

                        <Grid
                          container
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} sm={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                borderRadius: 18,
                                backgroundColor: "#128c7e",
                              }}
                              onClick={() => {
                                console.log(user);
                                  window.open(
                                    `https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola mi nombre es ${user?.nombre} ${user.apellidos}`,
                                    "_blank"
                                  );
                              }}
                              fullWidth
                              startIcon={<WhatsAppIcon />}
                            >
                              Comunicate con nosotros por WhatsApp
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    }
                  </Box>
                </Card>
              </Grid>

              <Grid
                item
                md={9}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {typeof cursos !== "undefined" ? (
                  cursos?.map((curso, i) => {
                    console.log(curso);
                    moment.locale("es");
                    let fechaFormateada = moment(curso.fecha_inicio).format(
                      "LL"
                    );
                    let fechaFormateadaTermino = moment(
                      curso.fecha_finalizacion
                    ).format("LL");
                    let urlZoom =  getZoomLink(curso.uid);
                    // let idSesionSence = await getUsuario(user.uid)
                    console.log(urlZoom);
                    console.log(curso.url_zoom);
                    let idSesionSence;
                    let cursoFinalizado;
                    if (user) {
                      try {
                        if (user.cursos) {
                          idSesionSence = user.cursos[curso.uid]?.idSesionSence;
                          cursoFinalizado =
                            user.cursos[curso.uid]?.cursoFinalizado;
                        } else {
                          idSesionSence = undefined;
                          cursoFinalizado = false;
                        }
                      } catch (error) {
                        idSesionSence = undefined;
                        cursoFinalizado = false;
                      }
                    } else {
                      idSesionSence = undefined;
                      cursoFinalizado = false;
                    }
                    // let idSesionSence = user ? user.cursos[curso.uid]?.idSesionSence : "undefined"

                    console.log(curso.url_zoom);
                    console.log(curso.nombre_curso,curso.uid);
                    
                    if (curso.estado !== "no_visible") {

                      if (user)
                        return (
                          <CursoView
                            id={i}
                            user={user}
                            curso={curso}
                            key={i}
                            token={config.token}
                            rutOtec={config.rutOtec}
                            lineaCapacitacion={config.lineaCapacitacion}
                          />
                        );
                    }
                  })
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    "Sin datos"
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Fragment>
  );
}

export default AcesoAsistentes;
