
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  Container,
  Grid,
  TextField,
  Divider,
  Typography,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import { useRelatores } from '../../cursos2/hooks';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker } from "@material-ui/pickers";
import { guardarCurso } from "../../../services/database/cursos"
import { guardarPrograma } from "../../../services/database/programas"




const useStyles = makeStyles(theme => ({
  main: {
    // marginBottom: '50px',
    paddingLeft: theme.spacing(0),
    // paddingTop: theme.spacing(11)
  },
  formControl: {
    // paddingTop: theme.spacing(1)
  },
  dialogPaper: {
    overflow: 'visible'
  },
  hidden: {
    display: "none"
  },
  textField: {

  },
  buttonSuccess: {
    backgroundColor: "#223440",
    '&:hover': {
      backgroundColor: "#223440",
    },
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: "#223440",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 0,
    paddingBottom: 10
  },

  subtitleHead: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }


}));


function FormularioPrograma(props) {
  const classes = useStyles();

  const {
    onSavePrograma
  } = props;



  const [loadingSave, setLoadingSave] = React.useState(false);
  const [isSence, setIsSence] = React.useState(true);
  const notisnack = useSnackbar();
  const [formik] = React.useState(null);



  const handleChange = (e, { name, value }) => {
    formik.setFieldValue(name, value);
    e.persist();
  };
  const dateChange = (e) => {
    console.log(e)
    // formik.handleChange(name);
    // formik.setFieldTouched(name, true, false);
    formik.setFieldValue(e);
    e.persist();
  };
  const values = {
    nombre_programa: "",
    descripcion_prorgama: "",
    url_imagen: "",
    fecha_inicio: "",
    fecha_finalización: "",
    cantidad_horas: ""
  }
  const validationSchemaUsuario = Yup.object({

  })



  const handleOnSubmit = async (values, formikBag) => {

    guardarPrograma(values).then(result => {
      console.log(result);
      onSavePrograma(result)
    })

  }

  const handleResetForm = (values, formikBag) => {

  }

  const buttonClassname = clsx({
    // [classes.buttonSuccess]: success,
  });

  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">

          </Grid>
          <Grid container alignContent="center" alignItems="center">
            <Grid item lg style={{ paddingBottom: 30 }} >
              <Typography className={classes.title} variant="h5">Ingresa Programa</Typography>
              <Typography className={classes.subtitleHead} variant="subtitle1">Modulo encargado de ingresar un nuevo programa </Typography>
              <Divider />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                <Grid item>
                  <Formik
                    onReset={handleResetForm}
                    onSubmit={handleOnSubmit}
                    initialValues={values}
                    validationSchema={validationSchemaUsuario}
                    onChange={handleChange}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                    }) => (
                        <Container maxWidth="xl" className={classes.main}>
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container
                              direction="column">
                              <Typography className={classes.subtitle} variant="subtitle1">Datos Basicos</Typography>
                              <Divider />
                              <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                spacing={1}
                                alignItems="flex-start">
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="nombre_programa"
                                    label="Nombre Programa"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.nombre_programa}
                                    error={touched.nombre_programa && Boolean(errors.nombre_programa)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="descripcion_prorgama"
                                    label="Descripción Programa"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.descripcion_prorgama}
                                    error={touched.descripcion_prorgama && Boolean(errors.descripcion_prorgama)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="url_imagen"
                                    label="URL imagen"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.url_imagen}
                                    error={touched.url_imagen && Boolean(errors.url_imagen)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="cantidad_horas"
                                    label="Cantidad de horas"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      },
                                      shrink: true,
                                    }}

                                    className={classes.textField}
                                    value={values.cantidad_horas}
                                    error={touched.cantidad_horas && Boolean(errors.cantidad_horas)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    type="number"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <DateTimePicker
                                      id="fecha_inicio"
                                      name={`fechaInicio`}
                                      label="Fecha de Inicio"
                                      autoOk
                                      fullWidth
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.inputLabel,
                                          shrink: "shrink"
                                        }
                                      }}
                                      margin="normal"
                                      className={classes.textField}
                                      helperText={touched.fechaInicio && errors.fechaInicio ? errors.fechaInicio : ""}
                                      error={Boolean(errors.fechaInicio)}
                                      value={values.fechaInicio}
                                      inputVariant="outlined"
                                      size="small"
                                      format="dd/MM/yyyy HH:mm"

                                      // InputAdornmentProps={{ position: "start" }}
                                      onChange={(date) => { console.log(date); setFieldValue(`fecha_inicio`, date.toDateString()) }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <DateTimePicker
                                      id="fecha_finalización"
                                      name={`fechaInicio`}
                                      label="Fecha de finalización"
                                      autoOk
                                      fullWidth
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.inputLabel,
                                          shrink: "shrink"
                                        }
                                      }}
                                      margin="normal"
                                      className={classes.textField}
                                      helperText={touched.fechaInicio && errors.fechaInicio ? errors.fechaInicio : ""}
                                      error={Boolean(errors.fechaInicio)}
                                      value={values.fechaInicio}
                                      inputVariant="outlined"
                                      size="small"
                                      format="dd/MM/yyyy HH:mm"
                                      // InputAdornmentProps={{ position: "start" }}
                                      onChange={(date) => { setFieldValue(`fecha_finalización`, date.toDateString()) }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                              </Grid>
                            </Grid>
                            <Grid>
                              <div className={classes.wrapper}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={buttonClassname}
                                  disabled={!isValid || loadingSave}>
                                  Agregar Programa
                                </Button>
                                {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                              </div>
                            </Grid>
                          </form>
                        </Container>
                      )}
                  </Formik>

                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Container>
    </Fragment>
  );

}

export default FormularioPrograma;
