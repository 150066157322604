

import React, { useEffect } from 'react';
import { getCurso,getCursosDeUnPrograma, getSesionesCurso } from '../../../../services/database/v3/cursos';



export const useSesionesCurso = (idCurso,reload) => {
  const [errorSesiones, seterrorSesiones] = React.useState(false)
  const [loadingSesiones, setloadingSesiones] = React.useState(true)
  const [sesionesArray, setsesionesArray] = React.useState(null)


  useEffect(() => {
    console.log(idCurso)
    if (idCurso) {
      setloadingSesiones(true)
      getSesionesCurso(idCurso).then(result => {
        setloadingSesiones(false)
        console.log(result)
        setsesionesArray(result)
      }).catch(error => {
        seterrorSesiones(error);
      })
    }
  }, [idCurso,reload])

  return {
    errorSesiones,
    loadingSesiones,
    sesionesArray,
  }
}


export const useCurso = (idCurso,reload) => {
  const [errorCurso, setErrorCurso] = React.useState(false)
  const [loadingCurso, setLoadingCurso] = React.useState(true)
  const [curso, setCurso] = React.useState(null)


  useEffect(() => {
    console.log(idCurso)
    if (idCurso) {
      setLoadingCurso(true)
      getCurso(idCurso).then(result => {
        setLoadingCurso(false)
        console.log(result)
        setCurso(result)
      }).catch(error => {
        setErrorCurso(error);
      })
    }
  }, [idCurso,reload])

  return {
    errorCurso,
    loadingCurso,
    curso,
  }
}


export const useCursosDelPrograma = (idPrograma,reload) => {
  const [errorCursos, setErrorCursos] = React.useState(false)
  const [loadingCursos, setLoadingCursos] = React.useState(true)
  const [cursos, setCursos] = React.useState(null)


  useEffect(() => {
    console.log("reload useCursosDelPrograma")
    if (idPrograma) {
      setLoadingCursos(true)
      getCursosDeUnPrograma(idPrograma).then(result => {
        setLoadingCursos(false)
        setCursos(result)
      }).catch(error => {
        setErrorCursos(error);
      })
    }
  }, [idPrograma,reload])

  return {
    errorCursos,
    loadingCursos,
    cursos,
  }
}
