
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  Container,
  DialogActions,
  Grid,
  TextField,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import { useProgramas } from './hooks';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker } from "@material-ui/pickers";
import { AddCursoPrograma } from '../../../services/database/v3/cursos';
import { useRelatores } from '../usuarios/hooks';
import Moment from 'moment';




const useStyles = makeStyles(theme => ({
  main: {
    // marginBottom: '50px',
    // paddingLeft: theme.spacing(9),
    // paddingTop: theme.spacing(11)
  },
  formControl: {
    // paddingTop: theme.spacing(1)
  },
  dialogPaper: {
    overflow: 'visible'
  },
  hidden: {
    display: "none"
  },
  textField: {

  },
  buttonSuccess: {
    backgroundColor: "#223440",
    '&:hover': {
      backgroundColor: "#223440",
    },
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: "#223440",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }


}));


function FormularioCurso(props) {
  const classes = useStyles();

  const {
    onSaveCurso,
    idPrograma
  } = props;


  const { errorRelatores, loadingRelatores, relatores } = useRelatores()




  const [loadingSave, setLoadingSave] = React.useState(false);
  const [isSence, setIsSence] = React.useState(true);
  const notisnack = useSnackbar();
  const [formik] = React.useState(null);



  const handleChange = (e, { name, value }) => {
    formik.setFieldValue(name, value);
    e.persist();
  };
  const dateChange = (e) => {
    console.log(e)
    // formik.handleChange(name);
    // formik.setFieldTouched(name, true, false);
    formik.setFieldValue(e);
    e.persist();
  };
  const values = {
    nombre_curso: "",
    descripcion: "",
    url_zoom: "",
    is_sence: true,
    codigo_curso: "",
    codigo_sence: "",
    relator: "",
    fecha_inicio: Moment(),
    fecha_finalizacion: Moment(),
  }
  const validationSchemaUsuario = Yup.object({

  })



  const handleOnSubmit = async (values, formikBag) => {
    setLoadingSave(true)
    values.estado = "no_visible"
    AddCursoPrograma(values, idPrograma).then(result => {
      console.log(result);
      onSaveCurso(result)
      setLoadingSave(false)
    }).catch(error => {
      console.error(error)
    })
  }

  const handleResetForm = (values, formikBag) => {

  }

  const handleChangeIsSense = (event) => {
    console.log(event.target.checked);
    setIsSence(event.target.checked)
  };

  const buttonClassname = clsx({
    // [classes.buttonSuccess]: success,
  });

  return (
    <Fragment>
      <Container fixed>
        <Grid container alignContent="center" alignItems="center">
          <Grid item lg style={{ paddingBottom: 30 }} >
            <Typography className={classes.title} variant="h5">Agregar Curso</Typography>
            <Typography className={classes.subtitle} variant="subtitle1">Ingrese la información requeridad en caso de no tener informacion solo omita el campo</Typography>
            <Divider />
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
              alignItems="center">
              <Grid item>
                <Formik
                  onReset={handleResetForm}
                  onSubmit={handleOnSubmit}
                  initialValues={values}
                  validationSchema={validationSchemaUsuario}
                  onChange={handleChange}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    isValid
                    /* and other goodies */
                  }) => (
                      <Container maxWidth="xl" className={classes.main}>
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <Grid container
                            direction="column">
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              spacing={1}
                              alignItems="flex-start">
                              <Grid item xs={4} lg={4}>
                                <TextField
                                  name="nombre_curso"
                                  label="Nombre Curso"
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.inputLabel,
                                      shrink: "shrink"
                                    }
                                  }}
                                  className={classes.textField}
                                  value={values.nombre_curso}
                                  error={touched.nombre_curso && Boolean(errors.nombre_curso)}
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  size="small"
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <TextField
                                  name="descripcion"
                                  label="Descripción Curso"
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.inputLabel,
                                      shrink: "shrink"
                                    }
                                  }}
                                  className={classes.textField}
                                  value={values.descripcion}
                                  error={touched.descripcion && Boolean(errors.descripcion)}
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  size="small"
                                  onChange={handleChange}
                                />
                              </Grid>
                              {/* <Grid item xs={4} lg={4}>
                                <TextField
                                  name="url_zoom"
                                  label="URL Zoom del curso a"
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.inputLabel,
                                      shrink: "shrink"
                                    }
                                  }}
                                  className={classes.textField}
                                  value={values.url_zoom}
                                  error={touched.url_zoom && Boolean(errors.url_zoom)}
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  size="small"
                                  onChange={handleChange}
                                />
                              </Grid> */}
                              <Grid item xs={4} lg={4}>
                                <TextField
                                  id="relator"
                                  select
                                  label="Relator"
                                  margin="normal"
                                  variant="outlined"
                                  className={classes.textField}
                                  value={values.relator}
                                  onChange={handleChange("relator")}
                                  margin="normal"
                                  size="small"
                                  fullWidth>
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {relatores ? relatores.map((x, id) => <MenuItem key={id} value={x.uid}>{x.nombre} {x.apellidos}</MenuItem>) : []}
                                </TextField>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                  <DateTimePicker
                                    id="fecha_inicio"
                                    name={`fecha_inicio`}
                                    label="Fecha de Inicio"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    margin="normal"
                                    className={classes.textField}
                                    helperText={touched.fecha_inicio && errors.fecha_inicio ? errors.fecha_inicio : ""}
                                    error={Boolean(errors.fecha_inicio)}
                                    value={values.fecha_inicio}
                                    inputVariant="outlined"
                                    size="small"
                                    format="dd/MM/yyyy HH:mm"
                                    // InputAdornmentProps={{ position: "start" }}
                                    onChange={e => {
                                      setFieldValue("fecha_inicio", e.toString()); // Access it from props
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                  <DateTimePicker
                                    id="fecha_finalizacion"
                                    name={`fecha_finalizacion`}
                                    label="Fecha de finalización"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    margin="normal"
                                    className={classes.textField}
                                    helperText={touched.fecha_finalizacion && errors.fecha_finalizacion ? errors.fecha_finalizacion : ""}
                                    error={Boolean(errors.fecha_finalizacion)}
                                    value={values.fecha_finalizacion}
                                    inputVariant="outlined"
                                    size="small"
                                    format="dd/MM/yyyy HH:mm"
                                    // InputAdornmentProps={{ position: "start" }}
                                    onChange={e => {
                                      setFieldValue("fecha_finalizacion", e.toString()); // Access it from props
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>

                            </Grid>
                            {/* <Divider /> */}
                            <Typography className={classes.subtitle} variant="subtitle1">Información Adicional</Typography>
                            <Divider />
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              spacing={1}
                              alignItems="flex-start">
                              <Grid item xs={12} lg={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={isSence}
                                      onChange={(event) => {
                                        setIsSence(event.target.checked)
                                        setFieldValue(`is_sence`, event.target.checked)
                                      }} name="isSence" />
                                  }
                                  label="Curso es con codigo SENCE"
                                />
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <TextField
                                  name="codigo_curso"
                                  label="Código del Curso"
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.inputLabel,
                                      shrink: "shrink"
                                    }
                                  }}
                                  className={classes.textField}
                                  value={values.codigo_curso}
                                  error={touched.codigo_curso && Boolean(errors.codigo_curso)}
                                  margin="normal"
                                  disabled={!isSence}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  size="small"
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <TextField
                                  name="codigo_sence"
                                  label="Código Sense"
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.inputLabel,
                                      shrink: "shrink"
                                    }
                                  }}
                                  className={classes.textField}
                                  value={values.codigo_sence}
                                  error={touched.codigo_sence && Boolean(errors.codigo_sence)}
                                  margin="normal"
                                  variant="outlined"
                                  disabled={!isSence}
                                  fullWidth
                                  multiline
                                  size="small"
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <div className={classes.wrapper}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={buttonClassname}
                                disabled={!isValid || loadingSave}>
                                Agregar Curso
                                </Button>
                              {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                          </Grid>
                        </form>
                      </Container>
                    )}
                </Formik>

              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );

}

export default FormularioCurso;
