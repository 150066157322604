import Axios from "../axios";
import { auth, realTime } from "../../firebase";
import uuid from "react-uuid";
import moment from "moment";
import { ref, child, get, set,update } from "firebase/database";
import { getDatabase } from "firebase/database";

const referencia = "usuarios/";

const dbRef = ref(realTime);

/**
 *
 * @param {usuario object} usuarioSuper
 */
export const addSuperUsuario = async (usuarioSuper) => {
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuarioSuper.nombre,
      apellidos: usuarioSuper.apellidos,
      rol: usuarioSuper.rol,
      rut: usuarioSuper.rut,
      is_activo: true,
      pass: usuarioSuper.pass,
      email: usuarioSuper.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status == 200) {
            const db = getDatabase();
            set(ref(db, "/usuarios/" + result.data.usuario), persona)
              .then((result) => {
                resolve(persona);
              })
              .catch((error) => {
                reject({ msj: "Problemas para agregar usario", data: null });
              });
          }
        } else {
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.log(error);
        reject({
          msj: error.response?.data?.msj,
          data: error.response.data?.usuario,
        });
      });
  });
};

export const addRelator = async (usuarioSuper) => {
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuarioSuper.nombre,
      apellidos: usuarioSuper.apellidos,
      rol: usuarioSuper.rol,
      rut: usuarioSuper.rut,
      is_activo: true,
      pass: usuarioSuper.pass,
      email: usuarioSuper.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status == 200) {
            const db = getDatabase();
            set(ref(db, "relatores/" + result.data.usuario), persona)
              .then((result) => {
                resolve(persona);
              })
              .catch((error) => {
                reject({ msj: "Problemas para agregar usario", data: null });
              });
          }
        } else {
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.log(error);
        reject({
          msj: error.response?.data?.msj,
          data: error.response.data?.usuario,
        });
      });
  });
};

/**
 *
 * @param {usuario id } idUsuario
 */
export const getUsuario = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    get(child(ref(db), "usuarios/" + idUsuario)).then(async (snapshot) => {
      const registros = snapshot.val();
      if (registros) {
        registros.uid = idUsuario;
        resolve(registros);
      }
      resolve([]);
    });
  });
};

/**
 * eliminar usuario
 * @param {usuario id} uid
 */
export const eliminarUsuario = async (uid, idPrograma, idCurso) => {
  return new Promise(async (resolve, reject) => {
    let updateUsuario = {};
    updateUsuario["usuarios/" + uid] = null;
    updateUsuario["usuariosCurso/" + uid] = null;
    updateUsuario["cursosUsuarios/" + idCurso + "/" + uid] = null;
    updateUsuario["programaUsuarios/" + idPrograma + "/" + uid] = null;
    updateUsuario["usuariosPrograma/" + uid] = null;
    const db = getDatabase();
    update(ref(db), updateUsuario)
      .then((_) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 *
 * @param {usuario} usuario
 * @param {id del program} idPrograma
 */
export const actualizarUsuario = async (usuario) => {
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuario.nombre,
      apellidos: usuario.apellidos,
      rol: usuario.rol,
      rut: usuario.rut,
      is_activo: true,
      pass: usuario.pass,
      email: usuario.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/updateUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status == 200) {
            const db = getDatabase();
            let personaRef;
            personaRef = await set(
              ref(db, referencia + result.data.usuario),
              persona
            );
            resolve(personaRef);
          }
        } else {
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        reject({
          msj: error.response?.data?.msj,
          data: error.response.data?.usuario,
        });
      });
  });
};

export const getRelatores = async () => {
  return new Promise(async (resolve, reject) => {
    get(child(dbRef, "relatores/"))
      .then(async (relatores) => {
        const registrosList = Object.keys(relatores.val()).map(
          (key, index) => ({
            ...relatores.val()[key],
            uid: key,
            count: index + 1,
          })
        );
        resolve(registrosList);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRelator = async (idRelator) => {
  return new Promise(async (resolve, reject) => {
    const dbRef = ref(realTime);
    let path = "relatores/" + idRelator;

    get(child(dbRef, path))
      .then((relator) => {
        console.log(relator.val());
        if (relator.val()) {
          let result = relator.val();
          result["id"] = relator.key;
          resolve(result);
        } else {
          resolve({
            nombre: "",
            apellidos: "",
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addUsuarioCurso = async (usuario, idCurso, idPrograma) => {
  console.log("=======================");
  console.log(idCurso, idPrograma);
  console.log("=======================");
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuario.nombre,
      cargo: usuario.cargo === undefined ? "" : usuario.cargo,
      apellidos: usuario.apellidos,
      rol: usuario.rol,
      rut: usuario.rut,
      is_activo: true,
      pass: usuario.pass,
      email: usuario.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status === 200) {
            let personasRef;
            let cursosRef;
            let personaRef;
            const db = getDatabase();
            if (result.data.msj === "usuario ya exite") {
              personaRef = await update(ref(db,"usuarios/" + result.data.usuario), persona)
            } else {
              personaRef = await update(ref(db,"usuarios/" + result.data.usuario), persona)
            }
            let curso = await get(child(ref(db), "cursos/" + idCurso))
            let programa = await  get(child(ref(db), "programas/" + idPrograma))

            await set(ref(db,"usuariosCurso/" + result.data.usuario + "/" + idCurso),curso.val())
            await set(ref(db,"cursosUsuarios/" + idCurso + "/" + result.data.usuario),persona)
            await set(ref(db,"programaUsuarios/" + idPrograma + "/" + result.data.usuario),persona)
            await set(ref(db,"usuariosPrograma/" + result.data.usuario + "/" + idPrograma),programa.val())
            resolve();
          } else {
            reject({ msj: "problemas para ingresar usuario", data: null });
          }
        } else {
          console.error(result);
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.error(error);
        reject({ msj: error.response?.data?.msj, data: [] });
      });
  });
};
