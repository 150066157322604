import React, { Fragment, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import MaterialTable, { MTableAction, MTableBody } from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Link as LinkIcon, CloudDownload } from '@material-ui/icons';

function AsistenciaSinSence(props) {
  const classes = style();
  const {
    listadoUsuario,
    idSesion,
    idCurso

  } = props;



  const [userSelected, setUserSelected] = useState(null);
  return (
    <Fragment>
      <Container >
        <Card style={{ width: "100%" }} className={classes.card}>
          <CardHeader className={classes.title}
            title=""
            titleTypographyProps={{
              align: "center",
              variante: "h4"
            }}
          />

          <MaterialTable
            title="Registro de Asistencia"
            options={{
              filtering: true,
              pageSizeOptions: [20, 30, 40, 50, 60],
              pageSize: 20,
              grouping: false,
              exportButton: false,
              actionsColumnIndex: -1,
            }}


            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
                exportName: "Exportar CSV"
              },

              pagination: {
                labelRowsSelect: "Filas",
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              grouping: {
                placeholder: " Arrastre los encabezados aquí para agrupar "
              },
              header: {
                actions: "Acción"
              }

            }}
            components={{
              Action: props => {

                if (props.action.isFreeAction) {
                  return (
                    <Tooltip title={props.action.tooltip} aria-label="add">
                      <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                        color="primary"
                        variant="contained"
                        style={{ textTransform: 'none' }}
                        size="small">
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )
                } else {
                  return (<MTableAction {...props} />);
                }
              },
            }}

            columns={[
              {
                title: 'Mombre',
                field: 'nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  width: '3px',
                },

              },

              {
                title: 'Apellidos',
                field: 'apellidos',
                cellStyle: {
                  // fontSize: '0.8em'
                },
              },
              {
                title: 'Email',
                field: 'email',
                cellStyle: {
                  // fontSize: '0.8em'
                },

              },
              {
                title: 'RUT',
                field: 'rut',
                cellStyle: {
                  // fontSize: '0.8em'
                },

              },

              {
                title: 'Assitencia',
                field: 'empresa.nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  textAlign: "center"
                },
                render: rowData => {
                  if (rowData[`${idSesion}`]?.idSesionSence) {
                    return (
                      <DoneIcon style={{ color: "#27ae60" }}></DoneIcon>
                    )
                  } else {
                    return (
                      <ClearIcon style={{ color: "#e74c3c" }} />
                    )
                  }

                }

              },
             
              {
                title: 'Marcado Asistencia',
                field: 'empresa.nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  textAlign: "center"
                },
                render: rowData => {
                  console.log(rowData[`${idSesion}`]?.idSesion)
                  return <DoneIcon style={{ color: "#27ae60" }}></DoneIcon>

                }
              },

            ]}
            data={listadoUsuario}
          />
        </Card>
      </Container>

    </Fragment >
  );
}

const style = makeStyles(theme => ({
  main: {
    overflow: "auto",
    maxWidth: "95vw"
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  // card: {
  //     height: "100%"
  // },
  title: {
    width: "100%",
    color: theme.palette.primary.main,
    backgroundColor: "#FFF"

  },
  cardContainer: {
    minHeight: "76px"
  },
  progress: {
    margin: theme.spacing(2),
  },
  fullwith: {
    width: "100%",
    height: "100%"
  },
  loadDetalles: {
    // height: "100%",
    // width: "100%",
    paddingLeft: "50%"
  },
  button: {
    marginLeft: 'auto',
  },
  appsHeader: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main
  },
  card: {
    marginBottom: theme.spacing(2)
  }
}));

export default AsistenciaSinSence;