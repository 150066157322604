import Axios from "../axios";
import { auth, realTime } from "../../firebase";
import uuid from "react-uuid";
import moment from "moment";
import { getUsuario } from "../usuario";
import { getDatabase } from "firebase/database";
import {
  ref,
  child,
  get,
  set,
  update,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";

const dbRef = ref(getDatabase());

export const obtenerCursosDeUnPrograma = async (idPrograma) => {
  return new Promise((resolve, reject) => {
    get(child(dbRef, "programas/" + idPrograma + "/cursos")).then(
      async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));

          let mapeoDeResultados = [];
          for (var i = 0; i < registrosList.length; i++) {
            let item = registrosList[i];

            let relator = await getUsuario(item.relator);

            relator.nombre_completo = relator.nombre + " " + relator.apellidos;
            item.relator = relator;
            mapeoDeResultados.push(item);
          }

          resolve(mapeoDeResultados);
        }
        resolve([]);
      }
    );
  });
};

export const getCursos = async (estado) => {
  return new Promise((resolve, reject) => {
    get(
      child(query(dbRef, orderByChild("isActivo"), equalTo(estado)), "cursos")
    ).then(async (snapshot) => {
      const registros = snapshot.val();
      if (registros) {
        const registrosList = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          uid: key,
          count: index + 1,
        }));
        resolve(registrosList);
      }
      resolve([]);
    });
  });
};

export const getZoomLink = async (idCurso) => {
  let snapshot = await get(child(dbRef, "cursos/" + idCurso + "/url_zoom"));

  const registros = snapshot.val();
  if (registros) {
    return registros;
  }
  return "";
};

export const addCurso = async (values) => {
  return new Promise(async (resolve, reject) => {
    values.fechaFinal = moment(values.fechaFinal).format();
    values.fechaInicio = moment(values.fechaInicio).format();

    let id = uuid();
    var empresaRef = await set(ref(dbRef, "/cursos/" + id), values);

    resolve(empresaRef);
  });
};
export const guardarCurso = async (values, idPrograma) => {
  return new Promise(async (resolve, reject) => {
    values.fechaFinal = moment(values.fechaFinal).format();
    values.fechaInicio = moment(values.fechaInicio).format();
    values.programa = idPrograma;

    let id = uuid();
    await set(ref(dbRef, "/cursos/" + id), values);
    await set(ref(dbRef, "/programas/" + idPrograma + "/cursos/" + id), values);

    resolve(id);
  });
};
