import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from "yup";
import { Formik } from 'formik';
import Page from "./page"
import { getCurrentUser } from "../../services/auth"
import { useSnackbar } from 'notistack';

import { validarUsuario } from "../../services/auth";
import logo from '../../img/logo.png'


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://metodoconsultores.cl">
                Método Consultores
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    img: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const notisnack = useSnackbar();
    const [formik, setFormik] = useState(null);

    const validationSchema = Yup.object({
        usuario: Yup.string().email().min(3).required("Debe ingresar correo valido "),
        // password: Yup.string().min(6).required("Debe ingresar una contraseña valida"),

    });

    const handleOnSubmitSinContraseña = async (values, formikBag) => {
        let user = validarUsuario(values.usuario, "metodo");
        console.log(user);
        user.then((result) => {
            console.log(result)
            console.log(result.rol)
            window.location = window.location.origin;
        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            
            console.log(errorCode, errorMessage)
            let mensaje = ""
            if (errorCode === "auth/wrong-password") {
                mensaje = "La contraseña no es válida o el usuario no tiene una contraseña."
            } else {
                mensaje = "Problemas para iniciar sesion."
            }
            // ...
            const key = notisnack.enqueueSnackbar(mensaje, {
                persist: false,
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                }
            });
        });
    }

    const handleOnSubmit = async (values, formikBag) => {
        console.log(values);

        let user = validarUsuario(values.usuario, values.password);
        user.then((result) => {
            console.log(result)
            console.log(history)

            window.location = window.location.origin;
        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode, errorMessage)
            let mensaje = ""
            if (errorCode === "auth/wrong-password") {
                mensaje = "La contraseña no es válida o el usuario no tiene una contraseña."
            } else {
                mensaje = "Problemas para iniciar sesion."
            }
            // ...
            const key = notisnack.enqueueSnackbar(mensaje, {
                persist: false,
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                }
            });
        });
        console.log(user);
        if (formik) {
            formik.resetForm({
                usuario: "",
                password: ""
            });
        }

    }
    const limpiarFormulario = () => {
        if (formik) {
            formik.resetForm(values);
        }
    }
    const values = {
        usuario: "",
        password: ""
    };
    console.log(logo);
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.img} src={logo} alt="logo" />

                <Formik
                    onSubmit={handleOnSubmitSinContraseña}
                    initialValues={values}
                    validationSchema={validationSchema}
                    render={props =>
                        <Page
                            {...props}
                            limpiarFormulario={limpiarFormulario}
                        />}
                />

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}