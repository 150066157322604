import React from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: "inline-block"
  },
}));

function MenuDropdown(props) {

  const classes = useStyles();

  const { icon, items, tooltip } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e, onClick) => {
    setAnchorEl(null);
    onClick(e);
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      {tooltip &&
        <Tooltip title={tooltip}>
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
            {icon}
          </IconButton>
        </Tooltip>
      }
      {!tooltip &&
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
          {icon}
        </IconButton>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, index) => (
          <MenuItem key={index} onClick={(e) => handleClick(e, item.onClick)} {...item.props}>
            {item.content}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default MenuDropdown;