import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    ExpandMore,
    Menu as MenuIcon,
    More,
    Help,
    Info,
} from '@material-ui/icons';
import {
    AppBar as MaterialAppBar,
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { cerrarSesion } from '../../services/auth';
import CustomizedSnackbars from '../../components/customizedSnackbar';
import './style.css';

const logo_cyd = require('../../img/logo.png');
const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
    },

    grow: {
        flexGrow: 1,
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 3,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    infoButton: {
        marginLeft: theme.spacing(2),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },

    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    contenedorUser: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '20px',
    },
    titleNoMargin: {
        margin: 0,
        textAlign: 'end'
    },
    titleBold: {
        fontWeight: 'bold',
    },
    hide: {
        display: 'none',
    },
    button: {
        zIndex: theme.zIndex.drawer + 2,
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    homeLink: {
        color: "white",
        textDecoration: "none"
    },
    img: {
        height: "40px",
        marginTop: "12px",
        marginBottom: "12px",
        marginRight: theme.spacing(2),
    },
    cyd_div: {
        height: "40px",
        backgroundColor: "#fff",
        marginTop: "12px",
        marginBottom: "12px",
        paddingTop: "5px",
        // marginRight: theme.spacing(1),
        borderRadius: "20px"
    },
    img_cyd: {
        height: "30px",


    }
}));

export default function AppBar(props) {

    const classes = useStyles();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [contrato, setContrato] = useState(props.contrato);
    const [usuarioSesion, setUsuarioSesion] = useState(props.usuarioSesion);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const menuId = 'primary-search-account-menu';
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        if (props.contrato !== contrato) {
            setContrato(props.contrato);
        }
        if (props.usuarioSesion !== usuarioSesion) {
            setUsuarioSesion(props.usuarioSesion);
        }
    });

    const showNombreProyecto = () => {
        const { proyecto } = props;
        setSnackbarOpen(true);
        setSnackbarMessage(proyecto.nombre);
    }

    const snackbarHandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const onClickMenuLateral = () => {
        const { handleOpenMainDrawer, openMainDrawer, handleCloseMainDrawer } = props;
        openMainDrawer ? handleCloseMainDrawer() : handleOpenMainDrawer();
    }

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
    }
    function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget);
    }
    function handleMenuClose() {
        setAnchorEl(null);
        handleMobileMenuClose();
    }
    async function handleCerrarSession() {
        setAnchorEl(null);
        await cerrarSesion();

        handleMobileMenuClose();
    }

    const renderMenu = () => {
        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuClose}>Perfíl</MenuItem>
                <MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
            </Menu>
        )
    };

    const renderMobileMenu = () => {
        return (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}

            >
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton
                        aria-label="Account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Avatar alt="Remy Sharp" src={usuarioSesion ? usuarioSesion.photoUrl : ""} className={classes.bigAvatar} />
                    </IconButton>
                    <p>Perfil</p>
                </MenuItem>
            </Menu>
        )
    };

    const { proyecto, nombreModulo, handleOpenSelector } = props;

    return (
        <Fragment>
            <div className={classes.root}>
                <MaterialAppBar position="fixed" className={classes.appBar}  >
                    <Toolbar >
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={onClickMenuLateral}>
                            <MenuIcon />
                        </IconButton>
                        
                        <Typography className={classes.title} variant="h6" color="inherit">
                            <Link className={classes.homeLink} to="/">{nombreModulo}</Link>
                        </Typography>
        
                        <Typography className={classes.title} variant="h6" color="inherit">

                        </Typography>
                        <div className={classes.grow} />
                        <div className={clsx({ [classes.hide]: usuarioSesion ? false : true, }, classes.sectionDesktop)}>
                            <Grid
                                container
                                direction="column">
                                <div className={classes.contenedorUser}>
                                    <Grid item xs>
                                        <Typography className={classes.titleNoMargin} variant="body2" gutterBottom color="inherit">
                                            Bienvenido
                                        </Typography>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <Box component="div" textOverflow="ellipsis" overflow="hidden">
                                                <Typography className={classes.titleBold} variant="body2" color="inherit">
                                                    {usuarioSesion ? usuarioSesion.nombre : ""}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>

                            <IconButton
                                edge="end"
                                aria-label="Account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit">

                                <Avatar alt="Remy Sharp" src={usuarioSesion ? usuarioSesion.photoUrl : ""} className={classes.bigAvatar} />
                            </IconButton>
                        </div>
                        <div className={clsx(classes.sectionMobile, { [classes.hide]: usuarioSesion ? false : true, })}>
                            <IconButton
                                aria-label="Show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <More />
                            </IconButton>
                        </div>
                        <Button color="inherit" className={clsx({ [classes.hide]: usuarioSesion ? true : false, })} >Login</Button>

                    </Toolbar>
                </MaterialAppBar>
                {renderMobileMenu()}
                {renderMenu()}

            </div>
            <CustomizedSnackbars
                open={snackbarOpen}
                variant='default'
                handleClose={snackbarHandleClose}
                message={snackbarMessage}
                hideButton={true}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </Fragment>
    );

}