import React, { useEffect } from 'react';
import { getProgramasAbiertos, getPrograma } from '../../../../services/database/v3/programas';


export const useProgramas = (reload) => {
  const [loadingPrograma, setLoadingPrograma] = React.useState(true)
  const [programas, setProgramas] = React.useState(null)

  useEffect(() => {
    console.log("render useProramas")
    getProgramasAbiertos().then(result => {
      setProgramas(result);
      setLoadingPrograma(false)
    })
  }, [reload])

  return {
    loadingPrograma, programas
  }
}

export const usePrograma = (programaId) => {
  const [loadingPrograma, setLoadingPrograma] = React.useState(true)
  const [programa, setPrograma] = React.useState(null)

  useEffect(() => {
    console.log("render useProramas")
    getPrograma(programaId).then(result => {
      setPrograma(result);
      setLoadingPrograma(false)
    })
  }, [programaId])

  return {
    loadingPrograma, programa
  }
}



