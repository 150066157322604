
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  Container,
  DialogActions,
  Grid,
  TextField,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from "yup";
import { useList, useListVals } from 'react-firebase-hooks/database';
import firebase from 'firebase/compat/app';
import { addSuperUsuario, deleteSuperUsuario } from '../../services/database/usuario';
import { green } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';




const useStyles = makeStyles(theme => ({
  main: {
    // marginBottom: '50px',
    // paddingLeft: theme.spacing(9),
    // paddingTop: theme.spacing(11)
  },
  formControl: {
    // paddingTop: theme.spacing(1)
  },
  dialogPaper: {
    overflow: 'visible'
  },
  hidden: {
    display: "none"
  },
  textField: {

  },
  buttonSuccess: {
    backgroundColor: "#223440",
    '&:hover': {
      backgroundColor: "#223440",
    },
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: "#223440",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },


}));


function FormularioSuperUsuario(props) {
  const classes = useStyles();

  const {

    openAgregarSuper,
    onCloseModal

  } = props;

  const [roles, loading, error] = useListVals(firebase.database().ref('roles'), { keyField: "id" });
  const [loadingSave, setLoadingSave] = React.useState(false);
  const notisnack = useSnackbar();
  const [formik] = React.useState(null);


  console.log(roles)
  const handleChange = (e, { name, value }) => {
    formik.setFieldValue(name, value);
    e.persist();
  };

  const values = {
    nombre: "",
    apellidos: "",
    rut: "",
    email: "",
    rol: "",
    pass: ""
  }
  const validationSchemaUsuario = Yup.object({
    nombre: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
    apellidos: Yup.string().required("nombre Requerido").typeError("apellido paterno Requerido"),
    rut: Yup.string().required("cargo Requerido").typeError("cargo Requerido"),
    email: Yup.string().required("email Requerido").email().typeError("Email incorrecto"),
    rol: Yup.string().required("email Requerido").typeError("Email incorrecto"),
    pass: Yup.string().required("email Requerido").typeError("Email incorrecto"),
  })



  const handleOnSubmit = async (values, formikBag) => {
    console.log(values);
    setLoadingSave(true)
    addSuperUsuario(values).then((result) => {
      console.log(result);
      notisnack.enqueueSnackbar("Usuario ingresado con exito", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      setLoadingSave(false)
      onCloseModal()
    }).catch(error => {
      console.log(error)
      setLoadingSave(false)
      const key = notisnack.enqueueSnackbar(error.msj, {
        persist: false,
        variant: 'error',
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    })
  }

  const handleResetForm = (values, formikBag) => {

  }

  const buttonClassname = clsx({
    // [classes.buttonSuccess]: success,
  });


  return (

    <Dialog open={openAgregarSuper} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar Información super usuario</DialogTitle>
      <DialogContent>
        <Formik
          onReset={handleResetForm}
          onSubmit={handleOnSubmit}
          initialValues={values}
          validationSchema={validationSchemaUsuario}
          onChange={handleChange}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid
            /* and other goodies */
          }) => (
              <Container maxWidth="xl" className={classes.main}>
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container
                    direction="row">
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      spacing={2}
                      alignItems="flex-start">
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="nombre"
                          label="Nombre"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.nombre}
                          error={touched.nombre && Boolean(errors.nombre)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          helperText="* Nombre obligatorio "
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="apellidos"
                          label="Apellidos"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.apellidos}
                          error={touched.apellidos && Boolean(errors.apellidos)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          helperText="* Apellido "
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="rut"
                          label="Rut"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.rut}
                          error={touched.rut && Boolean(errors.rut)}
                          margin="normal"
                          variant="outlined"
                          helperText="* rut "
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="email"
                          label="Email"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          margin="normal"
                          variant="outlined"
                          helperText="* Email "
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          id="rol"
                          select
                          label="Tipo de Usuario"
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={values.rol}
                          onChange={handleChange("rol")}
                          margin="normal"
                          fullWidth>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {roles ? roles.map((x, id) => <MenuItem key={id} value={x.id}>{x.nombre}</MenuItem>) : []}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="pass"
                          label="Contraseña"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.pass}
                          error={touched.pass && Boolean(errors.pass)}
                          margin="normal"
                          variant="outlined"
                          helperText="* pass "
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={onCloseModal}>
                      Cancelar
                    </Button>
                    <div className={classes.wrapper}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={!isValid || loadingSave}>
                        Agregar Contacto
                      </Button>
                      {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>


                  </DialogActions>
                </form>
              </Container>
            )}
        </Formik>

      </DialogContent>
    </Dialog >


  );
}

export default FormularioSuperUsuario;
