import React, { Fragment, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    CircularProgress,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import MaterialTable, { MTableAction, MTableBody } from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import FormUsuario from "./formUsuario/index"
import FormEditarUsuario from "./formEditarUser/index"

import FormAsignarUsuarioCurso from "./formAsignarUser/index"
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Link as LinkIcon, CloudDownload } from '@material-ui/icons';

function Page(props) {
    const classes = style();
    const {
        listadoUsuario,
        showForm,
        openForm,
        setOpenForm,
        openAsignarUsuario,
        setOpenAsignarUsuario,
        openEditarUsuario,
        setOpenEditarUsuario,
        openDeleteUsuario,
        setOpenDeleteUsuario,
        onOpenAsignarUsuario,

    } = props;

    const handleCloseModal = () => {
        setOpenForm(false)
    }

    const handleCloseModalAsignar = () => {
        setOpenAsignarUsuario(false);
    }
    const handleCloseModalEditar = () => {
        setOpenEditarUsuario(false);
    }
    const handleCloseModalDelete = () => {
        setOpenDeleteUsuario(false);
    }

    const eliminarUsuario = () => {

    }
    const [userSelected, setUserSelected] = useState(null);
    return (
        <Fragment>
            <Container >
                <Card style={{ width: "100%" }} className={classes.card}>
                    <CardHeader className={classes.title}
                        title="Usuarios"
                        titleTypographyProps={{
                            align: "center",
                            variante: "h4"
                        }}
                        action={
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={showForm}
                                    startIcon={<AddIcon />}>
                                    Agregar Usuarios
                                </Button>
                            </div>

                        }
                    />

                    <MaterialTable
                        title="Listado Usuarios"
                        options={{
                            filtering: true,
                            pageSizeOptions: [20, 30, 40, 50, 60],
                            pageSize: 20,

                            grouping: true,
                            exportButton: false,
                            actionsColumnIndex: -1,
                        }}

                        actions={[

                            {
                                icon: 'group_add',
                                tooltip: "Asignar Usuario",

                                iconProps: {
                                    color: "primary"
                                },
                                onClick: (event, rowData) => {

                                    setOpenAsignarUsuario(true)
                                    setUserSelected(rowData)

                                }
                            }, {
                                icon: 'edit',
                                tooltip: "Editar Usuario",

                                iconProps: {
                                    color: "primary"
                                },
                                onClick: (event, rowData) => {
                                    setOpenEditarUsuario(true)
                                    setUserSelected(rowData)

                                }
                            }, {
                                icon: 'delete',
                                tooltip: "Eliminar Usuario",

                                iconProps: {
                                    color: "primary"
                                },
                                onClick: (event, rowData) => {
                                    setOpenDeleteUsuario(true
                                    )
                                    setUserSelected(rowData)

                                }
                            },

                        ]}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Buscar",
                                exportName: "Exportar CSV"
                            },

                            pagination: {
                                labelRowsSelect: "Filas",
                                labelDisplayedRows: '{from}-{to} de {count}'
                            },
                            grouping: {
                                placeholder: " Arrastre los encabezados aquí para agrupar "
                            },
                            header: {
                                actions: "Acción"
                            }

                        }}
                        components={{
                            Action: props => {

                                if (props.action.isFreeAction) {
                                    return (
                                        <Tooltip title={props.action.tooltip} aria-label="add">
                                            <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                                                color="primary"
                                                variant="contained"
                                                style={{ textTransform: 'none' }}
                                                size="small">
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                } else {
                                    return (<MTableAction {...props} />);
                                }
                            },
                        }}

                        columns={[
                            {
                                title: 'Nobre',
                                field: 'nombre',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                    width: '3px',
                                },

                            },

                            {
                                title: 'Apellidos',
                                field: 'apellidos',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },
                            },
                            {
                                title: 'Email',
                                field: 'email',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },

                            },
                            {
                                title: 'RUT',
                                field: 'rut',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },

                            },
                            {
                                title: 'empresa',
                                field: 'empresa.nombre',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },

                            },

                        ]}
                        data={listadoUsuario}
                    />
                </Card>
            </Container>
            <Dialog
                open={openForm}
                maxWidth="md">
                <DialogTitle id="alert-dialog-title">Ingreso personas</DialogTitle>
                <DialogContent>
                    <FormUsuario handleCloseModal={handleCloseModal}></FormUsuario>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openAsignarUsuario}
                maxWidth="md">
                <DialogTitle id="alert-dialog-title">Asigna Cursos</DialogTitle>
                <DialogContent>
                    <FormAsignarUsuarioCurso handleCloseModalAsignar={handleCloseModalAsignar} userSelected={userSelected} handleCloseModal={handleCloseModal}></FormAsignarUsuarioCurso
                    >
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEditarUsuario}
                maxWidth="md">
                <DialogTitle id="alert-dialog-title">Asigna Cursos</DialogTitle>
                <DialogContent>
                    <FormEditarUsuario handleCloseModalEditar={handleCloseModalEditar} userSelected={userSelected} handleCloseModal={handleCloseModalEditar}></FormEditarUsuario
                    >
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeleteUsuario}
                maxWidth="md">
                {/* <DialogTitle id="alert-dialog-title">Asigna Cursos</DialogTitle> */}
                <DialogContent>
                    Está seguro de eliminar usuario.
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleCloseModalDelete}>
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ background: "#ee2738" }}
                        onClick={eliminarUsuario}
                    >
                        Eliminar
                    </Button>

                </DialogActions>
            </Dialog>
        </Fragment >
    );
}

const style = makeStyles(theme => ({
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    // card: {
    //     height: "100%"
    // },
    title: {
        width: "100%",
        color: theme.palette.primary.main,
        backgroundColor: "#FFF"

    },
    cardContainer: {
        minHeight: "76px"
    },
    progress: {
        margin: theme.spacing(2),
    },
    fullwith: {
        width: "100%",
        height: "100%"
    },
    loadDetalles: {
        // height: "100%",
        // width: "100%",
        paddingLeft: "50%"
    },
    button: {
        marginLeft: 'auto',
    },
    appsHeader: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

export default Page;