import Axios from "../axios";
import { auth, realTime } from "../../firebase";
import moment from "moment";
import uuid from "react-uuid";
import { ref, child, get, set, update } from "firebase/database";
var generator = require("generate-password");

const dbRef = ref(realTime);

export const guardarPrograma = async (values) => {
  return new Promise(async (resolve, reject) => {
    values.fechaFinal = moment(values.fechaFinal).format();
    values.fechaInicio = moment(values.fechaInicio).format();

    let correlativoPrograma = await get(
      child(dbRef, "/correlativos/programa")
    ).then();

    let correlativo = correlativoPrograma.val() + 1;
    values.codigo = "MET-PRO-" + correlativo;
    let id = uuid();

    var empresaRef = await set(ref(dbRef, "/programas/" + id), values);
    var empresaRef = await set(
      ref(dbRef, "/correlativos/programa/"),
      correlativo
    );
    console.log(empresaRef);
    resolve(id);
  });
};

export const cambiarEstadoPrograma = (estado, id) => {
  return new Promise((resolve, reject) => {
    set(ref(dbRef, "programas/" + id + "/estado"), estado).then(() => {
      resolve();
    });
  });
};

export const getProgramasAbiertos = async (IDrol) => {
  return new Promise((resolve, reject) => {
    get(ref(dbRef, "programas")).then(async (snapshot) => {
      console.log(snapshot.val());
      const registros = snapshot.val();
      if (registros) {
        const registrosList = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          id: key,
        }));
        resolve(registrosList);
      }
      resolve([]);
    });
  });
};
