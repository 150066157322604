import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid
} from '@material-ui/core';

import formatNumber from 'format-number';

const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });

export default function NumberInput(props) {

  const { input, label, meta, custom, style, textVariant, inputLabelProps, inputName, disabled, value, handleOnChange } = props;

  const [maskedValue, setMaskedValue] = useState(0);
  const [initialValue, setInitialValue] = useState(0);

  // useEffect(() => {
  //   setInitialValue(value);
  // }, []);

  // useEffect(() => {
  //   const masked = numberFormater(initialValue.toString().replace(/\./g, "").replace(/,/g, "."));
  //   if (masked !== maskedValue) {
  //     setMaskedValue(masked);
  //   }
  // }, [initialValue, setMaskedValue, numberFormater]);
  

  const onChange = (e) => {
    setMaskedValue(e.target.value ? numberFormater(e.target.value.replace(/\./g, "").replace(/,/g, ".")) : 0);
    e.target.value = e.target.value.replace(/\./g, "").replace(/,/g, ".");
    if (handleOnChange) handleOnChange(e);
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <TextField
          {...input}
          {...custom}
          
          style={{
            ...style,
            width: "100%"
          }}
          name={inputName}
          InputLabelProps={inputLabelProps}
          label={label ? label : ""}
          value={value ? value : ""}
          onChange={onChange}
          error={meta && (meta.touched && (meta.error ? true : false))}
          helperText={meta && meta.touched && meta.error}
          fullWidth={false}
          disabled={disabled ? true : false}
          variant={textVariant ? textVariant : "standard"}
        />
      </Grid>
    </Grid>
  );
}