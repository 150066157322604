import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { addUsuario, addSuperUsuario, addUsuarioCurso } from '../../../services/database/usuario';
import { useLocalStorage } from '../hooks';


const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));
function AgregarUsuarios(props) {
  const classes = useStyles();
  const history = useHistory();
  const [openAgregarSuper, setOpenAgregarSuper] = React.useState(false);
  const {
    idCurso
  }=props;
  const [name, setName] = useLocalStorage();
  console.log(name)
  const [columns, setColumns] = React.useState([
    { title: 'Nombre', field: 'nombre' },
    { title: 'Apellidos', field: 'apellidos' },
    { title: 'Rut', field: 'rut' },
    { title: 'Cargo', field: 'cargo' },
    { title: 'Email', field: 'email' },
  ]);

  const [data, setData] = React.useState([

  ]);
  const saveUsuarios = () => {
    console.log(data)
    history.push("/cursos")
  }
  const handlerCloseDialogAgregarSuper = (event) => {
    setOpenAgregarSuper(false);
  }

  const addUsuario = (data_p,index_p) => {

    return new Promise((resolve, reject) => {
      data_p["rol"] = "basico";
      data_p["pass"] = "metodo";
      console.log(data_p)
      console.log(idCurso)
      addUsuarioCurso(data_p,idCurso,).then((result) => {
        setData([...data, data_p]);
        resolve(result);
      }).catch(error => {
        console.log(error)
        resolve();
      })
    })

  }
  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">

          </Grid>
          <Grid container alignContent="center" alignItems="center">
            <Grid item lg style={{ paddingBottom: 30 }} >
              <Typography className={classes.title} variant="h5">Ingreso de asistentes</Typography>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item >
                  <Typography className={classes.subtitle} variant="subtitle1">ingrese los datos de los asistentes al curso</Typography>
                </Grid>
                <Grid item style={{ paddingRight: 24, paddingBottom: 12 }} >
                  <Button variant="contained" color="primary" onClick={saveUsuarios}>
                    Finalizar
                </Button>
                </Grid>
              </Grid>

              <Divider />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                <Grid item xs={12} lg={12}>
                  <MaterialTable
                    title=""
                    options={{
                      paging: false
                    }}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Buscar",
                        exportName: "Exportar CSV"
                      },

                      pagination: {
                        labelRowsSelect: "Filas",
                        labelDisplayedRows: '{from}-{to} de {count}'
                      },
                      grouping: {
                        placeholder: " Arrastre los encabezados aquí para agrupar "
                      },
                      header: {
                        actions: "Acción"
                      },
                      body: { editRow: { deleteText: "Realmente quiere eliminar al usuario" } }

                    }}
                    columns={columns}
                    data={data}
                    editable={{
                      onRowAdd: newData => {
                        return addUsuario(newData)
                      },
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);

                            resolve();
                          }, 1000)
                        }),
                      onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setData([...dataDelete]);

                            resolve()
                          }, 1000)
                        }),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Container>
    </Fragment>
  );
}

export default AgregarUsuarios;
