import React, { useState, useEffect } from 'react';
import Page from './page';
import { useSnackbar } from 'notistack';
import {getEmpresas} from '../../services/database/empresas'

export default function Empresas(props) {

    const notisnack = useSnackbar();

    const [openForm,setOpenForm] =  useState(false);
    const [listadoEmpresas, setListadoEmpresas] = useState([]);

    const loadData = async () => {
        let listadoempresas = await getEmpresas(true);
        setListadoEmpresas(listadoempresas)
    }
    useEffect(() => {

        loadData();
    }, []);
    const showForm =  () =>{
        setOpenForm(true);
    }

    useEffect(() => {
        loadData();
    }, [openForm]);
    
    return (
        <Page
            listadoEmpresas={listadoEmpresas}
            showForm={showForm}
            setOpenForm={setOpenForm}
            openForm={openForm}
        />
    );
}
