import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button, Dialog, DialogContent, Input, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { addUsuarioPrograma, updateUsuario, getUsuarioDelPrograma } from '../../../services/database/usuario';

import { addUsuarioCurso, eliminarUsuario } from '../../../services/database/v3/usuario'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getUsuariosDeUnCurso } from '../../../services/database/v3/cursos'
import * as XLSX from 'xlsx'

function TablaUsuarios(props) {
  const {
    idPrograma,
    idCurso,
    exportInformacionUsuario,
    exportData
  } = props;



  const [data, setData] = React.useState([]);

  const [openImport, setOpenImport] = React.useState(false);
  const [dataImporta, setDataImport] = React.useState(null);
  const [loadCarga, setLoadCarga] = React.useState(false);

  const showModalImport = (data) => {
    setOpenImport(true);

  }
  const handleCloseImport = () => {
    setOpenImport(false);


  };

  useEffect(() => {

    getUsuariosDeUnCurso(idCurso).then(result => {
      setData(result)
    })
  }, [idCurso])

  const updateUsuarioRow = (data_p, oldData) => {
    return new Promise((resolve, reject) => {
      data_p["rol"] = "basico";
      data_p["pass"] = "metodo";
      updateUsuario(data_p, idPrograma).then((result) => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = data_p;
        setData([...dataUpdate]);
        resolve(result);
      }).catch(error => {
        console.error(error)
      })

    })
  }

  const importUsuario = (data_p) => {
    return new Promise((resolve, reject) => {
      data_p["rol"] = "basico";
      data_p["pass"] = "metodo";
      addUsuarioCurso(data_p, idCurso, idPrograma).then(result => {
        setTimeout(() => {
          resolve(result);
        }, 500);
      }).catch(error => {
        console.error(error)
        resolve();
      })
    })
  }
  const addUsuario = (data_p) => {

    return new Promise((resolve, reject) => {
      data_p["rol"] = "basico";
      data_p["pass"] = "metodo";
      addUsuarioCurso(data_p, idCurso, idPrograma).then((result) => {
        return getUsuariosDeUnCurso(idCurso)
      }).then(dataResult => {
        setData(dataResult)
        resolve();
      }).catch(error => {
        resolve();
      })
    })

  }

  const deleteUsuarioRow = (oldData) => {
    return new Promise((resolve, reject) => {
      console.log(oldData)
      eliminarUsuario(oldData.uid, idPrograma, idCurso).then((result) => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve(result);
      }).catch(error => {
        console.error(error)
        resolve();
      })
    })
  }

  const eliminarUsuarioMasivo = (data) => {
    console.log(data)

    data.forEach(item => {
      setTimeout(async () => {
        await deleteUsuarioRow(item)
        getUsuariosDeUnCurso(idCurso).then(result => {
          setData(result)
        })
      }, 500)

    })
  }

  const onClickImport = async (e) => {
    setLoadCarga(true);
    let arrayUsersAdds = []
    for (let i = 0; i < dataImporta[0].data.length; i++) {
      let item = dataImporta[0].data[i]
      let usuario = {
        nombre: item.nombre,
        apellidos: item.apellido_p + " " + item.apellido_m,
        rut: item.rut.replaceAll('.', ''),
        cargo: item.cargo ? item.cargo : null,
        email: item.email
      }
      console.log(usuario)
      arrayUsersAdds.push(importUsuario(usuario));


      // { title: 'Nombre', field: 'nombre' },
      // { title: 'Apellidos', field: 'apellidos' },
      // { title: 'Rut', field: 'rut' },
      // { title: 'Cargo', field: 'cargo' },
      // { title: 'Email', field: 'email' },
    }
    Promise.all(arrayUsersAdds).then(values => {
      console.log(values); // [3, 1337, "foo"] 
      getUsuarioDelPrograma(idPrograma).then(result => {
        setData(result)
      })
      setLoadCarga(false);
      setOpenImport(false)
    }).catch(error => {

      console.log(error)
    });


  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    console.log(name, value)

    let hojas = []
    if (name === 'file') {
      let reader = new FileReader()
      reader.readAsArrayBuffer(target.files[0])
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);


          hojas.push({
            data: XL_row_object,
            sheetName
          })
        })
        setDataImport(hojas)
        console.log(hojas)
      }
    }
  }


  return <Fragment>
    <Grid item xs={12} lg={12}>
      <MaterialTable
        components={{
          Container: props => <Paper {...props} elevation={0} />
        }}
        title=""
        options={{
          paging: false,
          actionsColumnIndex: -1,
          selection: true,
          exportButton: exportData ? true : false,
          exportCsv: (columns, data) => {
            exportInformacionUsuario(columns, data)
          }
        }}
        actions={[
          {
            tooltip: 'Ver listado Usuarios',
            isFreeAction: true,
            icon: () =>
              <ArrowUpwardIcon />,
            onClick: (event, rowData) => { showModalImport(rowData) }
            // onClick: (event, rowData) => alert("You saved " + rowData.name)
          },

          {
            tooltip: 'Eliminar usuarios seleccionados',
            icon: 'delete',
            onClick: (evt, data) => {
              eliminarUsuarioMasivo(data)
            }
          }

        ]}
        localization={{
          toolbar: {
            searchPlaceholder: "Buscar",
            exportName: "Exportar Excel"
          },

          pagination: {
            labelRowsSelect: "Filas",
            labelDisplayedRows: '{from}-{to} de {count}'
          },
          grouping: {
            placeholder: " Arrastre los encabezados aquí para agrupar "
          },
          header: {
            actions: "Acción"
          },
          body: { editRow: { deleteText: "Realmente quiere eliminar al usuario" } }

        }}
        columns={[
          { title: '#', field: 'count', editable: "never" },
          { title: 'Nombre', field: 'nombre' },
          { title: 'Apellidos', field: 'apellidos' },
          { title: 'Rut', field: 'rut' },
          { title: 'Cargo', field: 'cargo' },
          { title: 'Email', field: 'email' },
        ]}
        data={data}
        editable={{
          onRowAdd: newData => {
            return addUsuario(newData)
          },
          onRowUpdate: (newData, oldData) => {

            return updateUsuarioRow(newData, oldData)
          },
          onRowDelete: oldData => {
            return deleteUsuarioRow(oldData)
          },
          onBulkUpdate: changes =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                /* setData([...data, newData]); */
                getUsuariosDeUnCurso(idCurso).then(result => {
                  setData(result)
                  resolve();
                })

              }, 1000);
            }),
        }}
      />
    </Grid>
    <Dialog fullWidth={true} onClose={handleCloseImport} style={{ borderRadius: 15 }} maxWidth={"sm"} open={openImport} aria-labelledby="form-dialog-title">
      <DialogContent style={{ background: "none!importantb", boxShadow: "none" }}>
        <Grid
          container
          direction="column"
          justify="center"
          spacing={2}
          alignItems="center">
          <Grid item>
            <input
              required
              type="file"
              name="file"
              onChange={handleInputChange}
              placeholder="Archivo de excel"
              style={{ display: 'none' }}
              id="contained-button-file"
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Cargar Achivos
              </Button>
            </label>
          </Grid>
          <Grid item>
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                color="default"
                style={{ background: "#f6f6f6" }}
                startIcon={<CloudUploadIcon />}
                disabled={dataImporta ? false : true} onClick={onClickImport} >
                Ingresar Usuarios
              </Button>
            </label>
          </Grid>
          <Grid item>
            {
              loadCarga ?
                <CircularProgress /> : console.log("")

            }

          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  </Fragment>
}
export default TablaUsuarios;