import { auth, realTime } from "../../firebase";
import uuid from "react-uuid";
import moment from "moment";
import { getRelator } from "./usuario";
import Moment from "react-moment";
import {
  ref,
  child,
  get,
  set,
  update,
  query,
  orderByChild,
  equalTo,
  limitToFirst,
  getDatabase,
} from "firebase/database";

/**
 * @param {usuario id } 
 */
export const loadConfig = async () => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    get(child(ref(db), "config")).then(async (snapshot) => {
      const registros = snapshot.val();
      if (registros) {
        resolve(registros);
      }
      resolve([]);
    });
  });
};
