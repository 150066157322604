import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    FormHelperText,
    DialogActions,
    Grid,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
    InputLabel
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    NavigateNext as NavigateNextIcon
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import esLocale from 'date-fns/locale/es'
import NumberInput from '../../../components/numberInput'
import formatNumber from 'format-number';
import './style.css';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    main: {
        // marginBottom: '50px',
        // paddingLeft: theme.spacing(9),
        // paddingTop: theme.spacing(11)
    },
    formControl: {
        // paddingTop: theme.spacing(1)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    },
    textField: {

    }

}));


function CursosAddPage(props) {
    const classes = useStyles();
    const {
        errors,
        handleSubmit,
        isValid,
        limpiarFormulario,
        touched,
        values,
        handleCloseModal
    } = props;
    const change = (name, e) => {
        const { setFieldTouched, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        if (e.persist) e.persist();
        if (name === "estado" && e.target.value === "Cerrado") {
            dateChange("fecha_respuesta", values.fecha_emision);
        }
    };


    const dateChange = (name, e) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        handleChange(name);
        setFieldTouched(name, true, false);
        setFieldValue(name, e);
    };
    const inputLabel = React.useRef(null);
    return (
        <Container maxWidth="xl" className={classes.main}>

            <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container
                    direction="row"
                    spacing={1}>
                    <Grid item lg={8}>
                        <TextField
                            name="nombre"
                            label="Nombre"
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    shrink: "shrink"
                                }
                            }}
                            className={classes.textField}
                            value={values.nombre}
                            helperText={touched.nombre ? errors.nombre : ""}
                            error={touched.nombre && Boolean(errors.nombre)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline
                            onChange={change.bind(null, "nombre")}
                        />
                    </Grid>
                    <Grid item lg={8}>
                        <TextField
                            name="rut"
                            label="RUT"
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    shrink: "shrink"
                                }
                            }}
                            className={classes.textField}
                            value={values.rut}
                            helperText={touched.rut ? errors.rut : ""}
                            error={touched.rut && Boolean(errors.rut)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline
                            onChange={change.bind(null, "rut")}
                        />
                    </Grid>
                 
                </Grid>
                <DialogActions>

                    <Button color="primary" onClick={limpiarFormulario}>
                        Limpiar
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}>
                        Guardar
                    </Button>

                </DialogActions>
            </form>
        </Container>
    );
}

export default CursosAddPage;
