import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";





// const config = {
//   apiKey: "AIzaSyBnc5ZDTBuqY8AOQ54IWQ7UqAItN_mrmGA",
//   authDomain: "ads-aula.firebaseapp.com",
//   databaseURL: "https://ads-aula.firebaseio.com",
//   projectId: "ads-aula",
//   storageBucket: "ads-aula.appspot.com",
//   messagingSenderId: "749940049276",
//   appId: "1:749940049276:web:23e85c899e7e154edad0f9",
//   databaseURL: "http://localhost:9000?ns=ads-aula"
// };
// firebase.initializeApp(config);


const config = {
  apiKey: "AIzaSyBpN_Mv4BhTrVknjGxqDqQrkJx2DUHv9eU",
  authDomain: "https://accounts.google.com/o/oauth2/auth",
  databaseURL: "https://metodo-aula-v2-test.firebaseio.com",
  projectId: "metodo-aula-v2",
//   storageBucket: "ads-aula.appspot.com",
//   messagingSenderId: "749940049276",
  appId: "1:408274142658:web:1a9a0214bc03b93510825b",
//   databaseURL: "http://localhost:9000?ns=ads-aula"
};

var serviceAccount = require("../cuenta-servicio.json");
const firebaseConfig = {
  apiKey: "AIzaSyBpN_Mv4BhTrVknjGxqDqQrkJx2DUHv9eU",
  authDomain: "metodo-aula-v2.firebaseapp.com",
  databaseURL: "https://metodo-aula-v2-test.firebaseio.com",
  projectId: "metodo-aula-v2",
  storageBucket: "metodo-aula-v2.appspot.com",
  messagingSenderId: "408274142658",
  appId: "1:408274142658:web:1a9a0214bc03b93510825b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



export const auth = getAuth()
export const realTime =  getDatabase(app);
export function onAuthStateChange(callback) {

  onAuthStateChanged(auth,user => {
    console.log(user?.email);
    if (user) {
      callback({loggedIn: true, email: user.email});
    } else {
      console.log({loggedIn: false});
      callback({loggedIn: false});
    }
  });
}