import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    FormHelperText,
    DialogActions,
    Grid,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
    InputLabel
} from '@material-ui/core';

const logo = require('../../logo.svg');


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        zIndex: 2,
    },
    z2: {
        zIndex: 2
    }
});


function Page(props) {
    const classes = useStyles();
    const {
        errors,
        estados,
        handleSubmit,
        isValid,
        limpiarFormulario,
        estadosFactura,
        estadoCursos,
        touched,
        values,
    } = props;
    const change = (name, e) => {
        const { setFieldTouched, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        if (e.persist) e.persist();
    };

    return (
        <Fragment>
            <Container fixed>
                <TextField
                    variant="outlined"
                    margin="normal"
                    value={values.usuario}
                    required
                    fullWidth
                    id="usuario"
                    label="Email"
                    name="usuario"
                    error={touched.usuario && Boolean(errors.usuario)}
                    helperText={touched.usuario ? errors.usuario : ""}
                    autoComplete="usuario"
                    onChange={change.bind(null, "usuario")}
                    autoFocus
                />
                {/* <TextField
                    variant="outlined"
                    margin="normal"
                    value={values.password}
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                    id="password"
                    onChange={change.bind(null, "password")}
                    autoComplete="current-password"
                /> */}

                <Button
                    type="submit"
                    disabled={!isValid}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.submit}
                >
                    Acceder
                    </Button>
            </Container>
        </Fragment>
    );
}

export default Page;
