import React, { useEffect } from 'react';
import { useListVals } from 'react-firebase-hooks/database';
import * as firebase from 'firebase/app';
import { getUsuarioSegunRol, obtenerRoles } from '../../../services/database/usuario';
import { obtenerCursosDeUnPrograma } from '../../../services/database/cursos';


// Hook
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const useCursos = (idPrograma) => {
  const [loadingCursos, setLoadingCursos] = React.useState(true)
  const [cursos, setCursos] = React.useState(null)


  useEffect(() => {
    obtenerCursosDeUnPrograma(idPrograma).then(result=>{
      console.log(result)
      setCursos(result)
      setLoadingCursos(false)
    })

  }, [idPrograma])

  return {
    loadingCursos,
    cursos,
  }
}

export const useRelatores = () => {
  const [errorRelatores, setError] = React.useState(false)
  const [loadingRelatores, setLoadingRelatores] = React.useState(true)
  const [relatores, setRelatore] = React.useState(null)

  const [idRol, setRol] = React.useState(null)

  useEffect(() => {

    obtenerRoles().then(async roles => {
      if (roles) {
        console.log(roles)
        let idRol = roles.find(rol => rol.nombre == "relator")
        let AdminRol = roles.find(rol => rol.nombre == "Administrador")
        console.log(idRol?.uid)
        let relatoresArray = []
        if (idRol.uid) {
          let relatoresResult = await getUsuarioSegunRol(idRol.uid);
          let adminResult = await getUsuarioSegunRol(AdminRol.uid);
          console.log(relatoresResult)
          console.log(adminResult)
          let finalRelator = relatoresArray.concat(relatoresResult, adminResult)
          setRelatore(finalRelator)
          console.log(finalRelator)
        } else {
          setError("error")
        }
        setLoadingRelatores(false)
      }
    }).catch(error => {
      setError(error)
    })

  }, [])

  return {
    errorRelatores,
    loadingRelatores,
    relatores,
  }
}
