import React, { useState, useEffect } from 'react';
import Page from './page';
import { useSnackbar } from 'notistack';
import { getUsuariosPorCursoID } from '../../../../services/database/usuario'
import { useHistory, useParams } from 'react-router';
import { getUsuariosDeUnCurso } from '../../../../services/database/v3/cursos';
import { useCurso } from '../../cursos/hooks';
import { CircularProgress } from '@material-ui/core';
import AsistenciaSinSence from './asistenciaSinSence';
export default function Usuario(props) {

    const notisnack = useSnackbar();
    const history = useHistory();
    const { id } = useParams();
    const { idCurso, idSesion } = useParams();

    const [listadoUsuariosActivos, setListadoUsuariosActivos] = useState([]);
    const { curso, loadingCurso } = useCurso(idCurso)

    const loadData = async () => {
        let listadoUsuario = await getUsuariosDeUnCurso(idCurso)
        console.log(listadoUsuario)
        setListadoUsuariosActivos(listadoUsuario)
    }
    useEffect(() => {
        console.log(curso)
        loadData();
    }, [curso]);



    console.log(listadoUsuariosActivos)

    return (
        <>
            {!loadingCurso && curso?
                curso.is_sence ?
                    <Page
                        listadoUsuario={listadoUsuariosActivos} idCurso={idCurso} idSesion={idSesion}
                    /> :
                    <AsistenciaSinSence listadoUsuario={listadoUsuariosActivos} idCurso={idCurso} idSesion={idSesion} />
                        :
                <CircularProgress />
            }
        </>

    );
}
