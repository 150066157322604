
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  Container,
  DialogActions,
  Grid,
  TextField,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from "yup";
import { useList, useListVals } from 'react-firebase-hooks/database';
import * as firebase from 'firebase/app';
import { addSuperUsuario, deleteSuperUsuario } from '../../../services/database/usuario';
import { green } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import { useRelatores, useProgramas } from '../hooks';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker } from "@material-ui/pickers";
import { guardarCurso } from "../../../services/database/cursos"




const useStyles = makeStyles(theme => ({
  main: {
    // marginBottom: '50px',
    // paddingLeft: theme.spacing(9),
    // paddingTop: theme.spacing(11)
  },
  formControl: {
    // paddingTop: theme.spacing(1)
  },
  dialogPaper: {
    overflow: 'visible'
  },
  hidden: {
    display: "none"
  },
  textField: {

  },
  buttonSuccess: {
    backgroundColor: "#223440",
    '&:hover': {
      backgroundColor: "#223440",
    },
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: "#223440",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }


}));


function FormularioCurso(props) {
  const classes = useStyles();

  const {
    onSaveCurso,
    idPrograma
  } = props;


  const { errorRelatores, loadingRelatores, relatores } = useRelatores()
  
  

  const [loadingSave, setLoadingSave] = React.useState(false);
  const [isSence, setIsSence] = React.useState(true);
  const notisnack = useSnackbar();
  const [formik] = React.useState(null);



  const handleChange = (e, { name, value }) => {
    formik.setFieldValue(name, value);
    e.persist();
  };
  const dateChange = (e) => {
    console.log(e)
    // formik.handleChange(name);
    // formik.setFieldTouched(name, true, false);
    formik.setFieldValue(e);
    e.persist();
  };
  const values = {
    nombre_curso: "",
    descripcion: "",
    url_zoom: "",
    is_sence: true,
    codigo_curso: "",
    codigo_sence: "",
    relator: "",
    fecha_inicio: "",
    fecha_finalización: ""
  }
  const validationSchemaUsuario = Yup.object({

  })



  const handleOnSubmit = async (values, formikBag) => {
    guardarCurso(values,idPrograma).then(result => {
      console.log(result);
      onSaveCurso(result)
    })
  }

  const handleResetForm = (values, formikBag) => {

  }

  const handleChangeIsSense = (event) => {
    console.log(event.target.checked);
    setIsSence(event.target.checked)
  };

  const buttonClassname = clsx({
    // [classes.buttonSuccess]: success,
  });

  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">

          </Grid>
          <Grid container alignContent="center" alignItems="center">
            <Grid item lg style={{ paddingBottom: 30 }} >
              <Typography className={classes.title} variant="h5">Agregar Sesiones</Typography>
              <Typography className={classes.subtitle} variant="subtitle1">Ingrese el numero de sesiones que tendra el curso</Typography>
              <Divider />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                <Grid item>
                  <Formik
                    onReset={handleResetForm}
                    onSubmit={handleOnSubmit}
                    initialValues={values}
                    validationSchema={validationSchemaUsuario}
                    onChange={handleChange}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                    }) => (
                        <Container maxWidth="xl" className={classes.main}>
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container
                              direction="column">
                              <Typography className={classes.subtitle} variant="subtitle1">Datos Basicos</Typography>
                              <Divider />
                              <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                spacing={1}
                                alignItems="flex-start">
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="nombre_curso"
                                    label="Nombre Curso"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.nombre_curso}
                                    error={touched.nombre_curso && Boolean(errors.nombre_curso)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="descripcion"
                                    label="Descripción Curso"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.descripcion}
                                    error={touched.descripcion && Boolean(errors.descripcion)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="url_zoom"
                                    label="URL Zoom del curso-"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.url_zoom}
                                    error={touched.url_zoom && Boolean(errors.url_zoom)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    hidden
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    id="relator"
                                    select
                                    label="Tipo de Usuario"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={values.relator}
                                    onChange={handleChange("relator")}
                                    margin="normal"
                                    size="small"
                                    fullWidth>
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {relatores ? relatores.map((x, id) => <MenuItem key={id} value={x.uid}>{x.nombre} {x.apellidos}</MenuItem>) : []}
                                  </TextField>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <DateTimePicker
                                      id="fecha_inicio"
                                      name={`fechaInicio`}
                                      label="Fecha de Inicio"
                                      autoOk
                                      fullWidth
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.inputLabel,
                                          shrink: "shrink"
                                        }
                                      }}
                                      margin="normal"
                                      className={classes.textField}
                                      helperText={touched.fechaInicio && errors.fechaInicio ? errors.fechaInicio : ""}
                                      error={Boolean(errors.fechaInicio)}
                                      value={values.fechaInicio}
                                      inputVariant="outlined"
                                      size="small"
                                      format="dd/MM/yyyy HH:mm"
                                      // InputAdornmentProps={{ position: "start" }}
                                      onChange={(date) => { console.log(date); setFieldValue(`fecha_inicio`, date.toDateString()) }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <DateTimePicker
                                      id="fecha_finalización"
                                      name={`fechaInicio`}
                                      label="Fecha de finalización"
                                      autoOk
                                      fullWidth
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.inputLabel,
                                          shrink: "shrink"
                                        }
                                      }}
                                      margin="normal"
                                      className={classes.textField}
                                      helperText={touched.fechaInicio && errors.fechaInicio ? errors.fechaInicio : ""}
                                      error={Boolean(errors.fechaInicio)}
                                      value={values.fechaInicio}
                                      inputVariant="outlined"
                                      size="small"
                                      format="dd/MM/yyyy HH:mm"
                                      // InputAdornmentProps={{ position: "start" }}
                                      onChange={(date) => { setFieldValue(`fecha_finalización`, date.toDateString()) }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                              </Grid>
                              {/* <Divider /> */}
                              <Typography className={classes.subtitle} variant="subtitle1">Información Adicional</Typography>
                              <Divider />
                              <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                spacing={1}
                                alignItems="flex-start">
                                <Grid item xs={12} lg={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={isSence}
                                        onChange={(event) => {
                                          setIsSence(event.target.checked)
                                          setFieldValue(`is_sence`, event.target.checked)
                                        }} name="isSence" />
                                    }
                                    label="Curso es con codigo SENCE"
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="codigo_curso"
                                    label="Código del Curso"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.codigo_curso}
                                    error={touched.codigo_curso && Boolean(errors.codigo_curso)}
                                    margin="normal"
                                    disabled={!isSence}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <TextField
                                    name="codigo_sence"
                                    label="Código Sense"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                      }
                                    }}
                                    className={classes.textField}
                                    value={values.codigo_sence}
                                    error={touched.codigo_sence && Boolean(errors.codigo_sence)}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={!isSence}
                                    fullWidth
                                    multiline
                                    size="small"
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid>
                              <div className={classes.wrapper}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={buttonClassname}
                                  disabled={!isValid || loadingSave}>
                                  Agregar Curso
                                </Button>
                                {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                              </div>
                            </Grid>
                          </form>
                        </Container>
                      )}
                  </Formik>

                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Container>
    </Fragment>
  );

}

export default FormularioCurso;
