import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button, Dialog, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ color: "#fff" }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon color="#fff" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
function DashBoardMetodo(props) {
  const classes = useStyles();
  const history = useHistory();
  const [openAgregarPrograma, setOpenAgregarPrograma] = React.useState(false);
  const [openAgregarSuper, setOpenAgregarSuper] = React.useState(false);

  const [reload, setReload] = React.useState(false)
  const [idProgramaSelect, setIdProgramaSelect] = React.useState(null)
  const [programasParaTabla, setProgramasParaTabla] = React.useState(null)



  const handlerCloseDialogAgregarPrograma = (event) => {
    setOpenAgregarPrograma(false);
  }



  const showModalAddPrograma = (data) => {
    setOpenAgregarPrograma(true);

  }

  const handlerCloseDialogAgregarSuper = (id) => {
    setOpenAgregarSuper(false)
  }

  const handlerSavePrograma = (id) => {
    // history.push("/programas/"+id)
    setOpenAgregarPrograma(false)
    setReload(!reload)
  }





  return (
    <Fragment>
      <Container spacing={1}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}>

          <Grid item md={12}>
            <Paper item elevation={3} style={{ borderRadius: 15 }}>
              <Grid container alignContent="center" alignItems="center">
                <Grid item lg style={{ paddingBottom: 30 }} >
                  <Typography className={classes.title} variant="h5">Bienvenido a Aula Método</Typography>
                  <Typography className={classes.subtitle} variant="subtitle1">Módulo de mantenedor de plataforma. Uso exlusivo para personal Método</Typography>
                  <Divider />
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-start"
                    style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                    alignItems="center">
                    <Grid item md>
                      <Paper elevation={3} style={{ backgroundColor: "#01a3a4" }}>
                        <Grid
                          container
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start">
                          <Grid item>
                            <Typography className={classes.title} style={{ paddingTop: 60, color: "#fff" }} variant="h5">Módulo Programa</Typography>
                          </Grid>
                          <Grid item >
                            <Grid
                              item
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start">
                              <Grid item md class={classes.content}>
                                <Typography className={classes.subtitle}
                                  style={{ color: "#fff", paddingTop: 15, paddingBottom: 0 }}
                                  paragraph={true}
                                  variant="subtitle1">
                                  
                                </Typography>
                              </Grid>
                              <Grid item class={classes.action} style={{ padding: 8, flex: '0 0 auto', alignSelf: 'flex-start', }}  >
                                <Tooltip title="Agregar Curso">
                                  <IconButton onClick={() => history.push("/programas")} >
                                    <ArrowForwardIcon style={{ color: "#fff" }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item md>
                      <Paper elevation={3} style={{ backgroundColor: "#ff9f43" }}>
                        <Grid
                          container
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start">
                          <Grid item>
                            <Typography className={classes.title} style={{ paddingTop: 60, color: "#fff" }} variant="h5">Módulo Cursos</Typography>
                          </Grid>
                          <Grid item >
                            <Grid
                              item
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start">
                              <Grid item md class={classes.content}>
                                <Typography className={classes.subtitle} style={{ color: "#fff", paddingTop: 15, paddingBottom: 0, textAlign: "justify" }} paragraph={true} variant="subtitle1"></Typography>
                              </Grid>
                              <Grid item class={classes.action} style={{ padding: 8, flex: '0 0 auto', alignSelf: 'flex-start', }}  >
                                <Tooltip title="Agregar Curso">
                                  <IconButton onClick={() => history.push("/programas/metodo")} >
                                    <ArrowForwardIcon style={{ color: "#fff" }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>

                    </Grid>

                    <Grid item md>
                      <Paper elevation={3} style={{ backgroundColor: "#222f3e" }}>
                        <Grid
                          container
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start">
                          <Grid item>
                            <Typography className={classes.title} style={{ paddingTop: 60, color: "#fff" }} variant="h5">Módulo Finanzas</Typography>
                          </Grid>
                          <Grid item >
                            <Grid
                              item
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start">
                              <Grid item md class={classes.content}>
                                <Typography className={classes.subtitle} style={{ color: "#fff", paddingTop: 15, paddingBottom: 0, textAlign: "justify" }} paragraph={true} variant="subtitle1"> </Typography>
                              </Grid>
                              <Grid item class={classes.action} style={{ padding: 8, flex: '0 0 auto', alignSelf: 'flex-start', }}  >
                                <Tooltip title="Agregar Curso">
                                  <IconButton onClick={() => history.push("/")} >
                                    <ArrowForwardIcon style={{ color: "#fff" }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment >
  );
}

export default DashBoardMetodo;
