import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Paper,
  Container,
  Divider,
  Button,
  Dialog,
  DialogContent,
  
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";

import {
  deleteSesionCurso,
  getSesionesCurso,
  updateSesionCurso,
} from "../../../services/database/v3/cursos";
import * as XLSX from "xlsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuDropdown from "../../../components/menuDropDown";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import FormularioSesiones from "./FormularioSesiones";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    borderRadius: "15px",

    backgroundColor: "rgb(255,255,255,0.5)",
  },
  img: {
    height: 100,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linearProgress: {
    marginLeft: "60vh",
    marginRight: "60vh",
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24,
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10,
  },
  action: {
    flex: "0 0 auto",
    alignSelf: "flex-start",
    marginTop: "auto",
    marginBottom: "auto",
  },
  content: {
    flex: "1 1 auto",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ color: "#fff" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function TablaSesion(props) {
  const { idCurso, reload } = props;
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [load, setload] = React.useState(false);

  const [openShowAddSesion, setOpenShowAddSesion] = React.useState(false);

  useEffect(() => {
    const load = async () => {
      setload(true);
      let result = await getSesionesCurso(idCurso);
      setData(result);
      setload(false);
    };
    load();
  }, [reload]);

  const handlerSaveSesion = async () => {
    setOpenShowAddSesion(false);
    let result = await getSesionesCurso(idCurso);
    setData(result);
    console.log("se ha guardado la sesion");
  };

  useEffect(() => {
    const load = async () => {
      setload(true);
      let result = await getSesionesCurso(idCurso);
      setData(result);
      setload(false);
    };
    load();
  }, []);

  const activarSesion = (idSesion) => {
    getSesionesCurso(idCurso)
      .then(async (result) => {
        setload(true);
        console.log(idCurso);
        console.log(result);
        let sesionActiva = result.find((elm) => elm.isActiva);
        let idSesionActiva = null;
        console.log(sesionActiva);
        if (typeof sesionActiva !== "undefined") {
          idSesionActiva = sesionActiva.uid;
        }
        console.log(sesionActiva?.uid);
        await updateSesionCurso(idSesionActiva, idSesion, idCurso).catch(
          (error) => {
            console.log(error);
          }
        );
        let newData = await getSesionesCurso(idCurso);
        console.log(newData);
        setData(newData);
        setload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpenShowAddSesion(false);
  };

  return (
    <Fragment>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item className={classes.content}>
          <Typography
            className={classes.title}
            style={{ margin: 0 }}
            variant="h6"
          >
            Sesiones
          </Typography>
        </Grid>
        <Grid item className={classes.action}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenShowAddSesion(true)}
          >
            Agregar Sesion
          </Button>
        </Grid>
        <Grid item className={classes.action} style={{ padding: 8 }}>
          <Tooltip title="Agregar Curso">
            <IconButton onClick={() => console.log(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} lg={12}>
            <MaterialTable
              title=""
              options={{
                paging: false,
                // actionsColumnIndex: -1,
                search: false,
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.isActiva && "#00b894",
                  color: rowData.isActiva && "#FFF",
                }),
              }}
              localization={{
                toolbar: {
                  searchPlaceholder: "Buscar",
                  exportName: "Exportar CSV",
                },

                pagination: {
                  labelRowsSelect: "Filas",
                  labelDisplayedRows: "{from}-{to} de {count}",
                },
                grouping: {
                  placeholder: " Arrastre los encabezados aquí para agrupar ",
                },
                header: {
                  actions: "Acción",
                },
                body: {
                  editRow: {
                    deleteText: "Realmente quiere eliminar al usuario",
                  },
                },
              }}
              columns={[
                { title: "Nombre", field: "nombre" },
                { title: "url zoom", field: "url_zoom" },
                {
                  title: "Fecha Inicio",
                  field: "fecha_inicio",
                  defaultSort: "asc",
                },
                {
                  title: "Fecha Finalización",
                  field: "fecha_finalizacion",
                },
                {
                  title: "Activación",
                  field: "isActiva",
                  render: (rowData) => {
                    if (!rowData.isActiva) {
                      return (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          style={{ borderRadius: 18 }}
                          onClick={(e) => activarSesion(rowData.uid)}
                        >
                          Activa Sesion
                        </Button>
                      );
                    }
                  },
                },
                {
                  title: "Cursos",
                  filed: "id",
                  render: (rowData) => {
                    const accionesConfiguracion = [];

                    // accionesConfiguracion.push({
                    //   content: 'Publicar Curso',
                    //   onClick: (e) => publicarCurso(rowData.id)
                    // });

                    // if (rowData.estado === "en_curso") {
                    //   accionesConfiguracion.push({
                    //     content: 'Finalizar Curso',
                    //     onClick: (e) => finalizarCurso(rowData.id)
                    //   });
                    // }
                    // accionesConfiguracion.push({
                    //   content: 'Inhabilitar curso',
                    //   onClick: (e) => deHabilitarCurso(rowData.id)
                    // });
                    // accionesConfiguracion.push({
                    //   content: 'Ocultar Curso',
                    //   onClick: (e) => ocultarCurso(rowData.id)
                    // });

                    accionesConfiguracion.push({
                      content: "Ver asistencia de la sesión",
                      onClick: (e) =>
                        history.push(
                          "/asistencia/" + idCurso + "/" + rowData.uid
                        ),
                    });
                    accionesConfiguracion.push({
                      content: "Eliminar sesión",
                      onClick: async (e) =>{
                        console.log(rowData);
                        let result  =  await deleteSesionCurso(rowData.uid,idCurso)
                        if(result){
                          handlerSaveSesion();
                        }
                      }
                    });

                    // accionesConfiguracion.push({
                    //   content: 'Ver Asistencia',
                    //   // onClick: (e) => showModalListaCursos(rowData)
                    // });

                    return (
                      <div>
                        {accionesConfiguracion.length > 0 && (
                          <MenuDropdown
                            icon={<MoreVertIcon />}
                            tooltip="Modulos/Cursos"
                            items={accionesConfiguracion}
                          />
                        )}
                      </div>
                    );
                  },
                },
              ]}
              isLoading={load}
              data={data}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        onClose={handleClose}
        style={{ borderRadius: 15 }}
        maxWidth={"xs"}
        PaperProps={{ style: { borderRadius: 15 } }}
        open={openShowAddSesion}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ background: "#253746" }}
        >
          Agregar Sesion
        </DialogTitle>
        <DialogContent>
          <FormularioSesiones
            onSaveSesion={handlerSaveSesion}
            idCurso={idCurso}
          ></FormularioSesiones>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
export default TablaSesion;
