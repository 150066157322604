import React, { useState, useEffect } from "react";

import { useSnackbar } from "notistack";
import { getCursosUsuario } from "../../services/database/usuario";
import { getCurrentUser } from "../../services/auth";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import logo from "../../img/computer.png";

import { getZoomLink } from "../../services/database/cursos";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import Link from "@material-ui/core/Link";
import CardMedia from "@material-ui/core/CardMedia";
const queryString = require("query-string");
const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function Reportabilidad(props) {
  const notisnack = useSnackbar();
  let history = useHistory();
  const classes = useStyles();
  let { codigo } = useParams();
  const params = new URLSearchParams(props.location.search);
  console.log(codigo);

  const [zoomLink, setZoomLink] = useState(null);

  const getMensajeError = (cod) => {
    let listado = [
      {
        codigo: "100",
        msj: "Contraseña incorrecta o el usuario no tiene Clave SENCE.",
      },
      {
        codigo: "200",
        msj: "El POST tiene uno o más parámetros mandatorios sin información. Esto también ocurre cuando un parámetro está mal escrito(por ejemplo, RutAlumno en lugar de RunAlumno), o cuando se ingresan sólo espacios en blanco en un parámetro obligatorio.",
      },
      {
        codigo: "201",
        msj: " La URL de Retoma y/o URL de Error no tienen información. Ambos parámetros son obligatorios en todos los POST.",
      },
      {
        codigo: "202",
        msj: "La URL de Retoma tiene formato incorrecto.",
      },
      {
        codigo: "203",
        msj: "La URL de Error tiene formato incorrecto.",
      },
      {
        codigo: "204",
        msj: " El Código SENCE tiene menos de 10 caracteres y/o no es código válido.",
      },
      {
        codigo: "205",
        msj: "El Código Curso tiene menos de 7 caracteres y/o no es código válido.",
      },
      {
        codigo: "206",
        msj: "La línea de capacitación es incorrecta.",
      },
      {
        codigo: "207",
        msj: "El Run Alumno tiene formato incorrecto, o tiene el dígito verificador incorrecto.",
      },
      {
        codigo: "208",
        msj: "El Run Alumno no está autorizado para realizar el curso.",
      },
      {
        codigo: "209",
        msj: "El Rut OTEC tiene formato incorrecto, o tiene el dígito verificador incorrecto.",
      },
      {
        codigo: "210",
        msj: "Expiró el tiempo disponible para el ingreso de RUT y Contraseña. El tiempo disponible es de tres minutos.",
      },
      {
        codigo: "211",
        msj: "El Token no pertenece al OTEC.",
      },
      {
        codigo: "212",
        msj: "El Token no está vigente.",
      },
      {
        codigo: "300",
        msj: "Error interno no clasificado, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles.",
      },
      {
        codigo: "301",
        msj: "No se pudo registrar el ingreso o cierre de sesión. Esto ocurre cuando la Línea de Capacitación es incorrecta, o el Código de Curso es incorrecto.",
      },
      {
        codigo: "302",
        msj: "No se pudo validar la información del Organismo, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles",
      },
      {
        codigo: "303",
        msj: "El Token no existe, o su formato es incorrecto.",
      },
      {
        codigo: "304",
        msj: "No se pudieron verificar los datos enviados, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles.",
      },
      {
        codigo: "305",
        msj: " No se pudo registrar la información, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles.",
      },
      {
        codigo: "306",
        msj: "El Código Curso no corresponde al Código SENCE.",
      },
      {
        codigo: "307",
        msj: "El Código Curso no tiene modalidad E-Learning",
      },
      {
        codigo: "308",
        msj: "El Código Curso no corresponde al RUT OTEC.",
      },
      {
        codigo: "309",
        msj: "Las fechas de ejecución comunicadas para el Código Curso no corresponden a la fecha actual",
      },
      {
        codigo: "310",
        msj: "El Código Curso está en estado Terminado o Anulado.",
      },
      {
        codigo: "311",
        msj: "Run ingresado en el Login de Clave Única no corresponde con Run alumno informado por el ejecutor",
      },
      {
        codigo: "312",
        msj: "No se pudo completar la autenticación con Clave Única.",
      },
      
    ];
    let mensaje = listado.find((e) => {
      return e.codigo === cod;
    });
    console.log(mensaje.msj, cod);
    return mensaje.msj;
  };

  const onClickIngresoZoom = async () => {
    console.log(zoomLink);
    let user = await getCurrentUser();
    let nombreUsuario = await getCursosUsuario(user.claims.user_id);
    console.log(nombreUsuario);
    // window.location = zoomLink + "&uname=" + nombreUsuario.nombre  + " " + nombreUsuario.apellidos;
    window.open(
      zoomLink +
        "&uname=" +
        nombreUsuario.nombre +
        " " +
        nombreUsuario.apellidos,
      "_blank"
    );
  };
  const onClickVolverHome = () => {
    history.push("/");
  };

  const loadData = async () => {
    const idCurso = params.get("id_curso");
    var urlZoom = await getZoomLink(idCurso);
    console.log(urlZoom);
    setZoomLink(urlZoom);
  };

  useEffect(() => {
    loadData();
  }, []);

  const preventDefault = (event) => event.preventDefault();
  return (
    <Container maxWidth="sm">
      <Card>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={logo}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography className={classes.pos}>
            Se originó un problema para poder registrar tu asistencia con SENCE,
            el mensaje entregado por SENCE es :
          </Typography>
          <Grid
            item
            container
            spacing={2}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="body1" component="p" className={classes.pos}>
              <br />
              <em>"{getMensajeError(codigo)}"</em>
              <br />
              <br />
              Si tienes problemas para poder registrar tu asistencia. Ponte en
              contacto con nosotros
              <br />
              <br />
            </Typography>
          </Grid>

          <Grid container justify="center" spacing={3}>
            <Grid
              item
              container
              spacing={2}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ borderRadius: 18, backgroundColor: "#128c7e" }}
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=56990478714&text=Hola",
                      "_blank"
                    )
                  }
                  fullWidth
                  startIcon={<WhatsAppIcon />}
                >
                  Comunicate con nosotros por WhatsApp
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>

            <Grid item xs={12} sm={6}></Grid>

            <Divider variant="middle" />

            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item container justify="center" xs={6}>
                <Button
                  color="primary"
                  style={{ borderRadius: 18 }}
                  onClick={(e) => onClickVolverHome()}
                >
                  Volver Aula Virtual
                </Button>
              </Grid>
              <Grid item container justify="center" xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ borderRadius: 18 }}
                  onClick={(e) => onClickIngresoZoom()}
                >
                  Ingresa a salon virtual
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
