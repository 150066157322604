import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { addUsuarioPrograma, updateUsuario, deleteUsuario, getUsuarioDelPrograma } from '../../../services/database/usuario';

import { useLocalStorage } from '../../cursos2/hooks';
// import TablaUsuarios from '../component/TablaUsuarios_ob'


const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));
function AgregarUsuarios(props) {
  const classes = useStyles();
  const history = useHistory();

  const [visibleBotonFinalizar, setVisibleBotonFinalizar] = React.useState(true);
  const {
    idPrograma,
    showFinalizar
  } = props;


  useEffect(() => {
    console.log(showFinalizar)
    if (typeof showFinalizar !== "undefined") {
      setVisibleBotonFinalizar(showFinalizar)
    }
  }, [])


  const saveUsuarios = () => {
    history.push("/programa")
  }




  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">

          </Grid>
          <Grid container alignContent="center" alignItems="center">
            <Grid item lg style={{ paddingBottom: 30 }} >
              <Typography className={classes.title} variant="h5">Ingreso de asistentes</Typography>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item >
                  <Typography className={classes.subtitle} variant="subtitle1">ingrese los datos de los asistentes al curso</Typography>
                </Grid>
                <Grid item style={{ paddingRight: 24, paddingBottom: 12 }} >
                  {visibleBotonFinalizar &&
                    <Button variant="contained" color="primary" onClick={saveUsuarios} >
                      Finalizar
                    </Button>
                  }
                </Grid>
              </Grid>

              <Divider />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                {/* <TablaUsuarios idPrograma={idPrograma} /> */}
              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Container>
    </Fragment>
  );
}

export default AgregarUsuarios;
