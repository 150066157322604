import { auth, realTime } from "../../firebase";
import uuid from "react-uuid";
import moment from "moment";
import {
  ref,
  child,
  get,
  set,
  getDatabase,
} from "firebase/database";

const dbRef = ref(realTime);

export const addPrograma = async (values) => {
  return new Promise(async (resolve, reject) => {
    const db = getDatabase();
    values.fecha_finalización = moment(values.fecha_finalización).format();
    values.fecha_inicio = moment(values.fecha_inicio).format();
    get(child(ref(db), "correlativos/programa"))
      .then(async (correlativoPrograma) => {
        let correlativo = correlativoPrograma.val() + 1;
        values.codigo = "MET-PRO-" + correlativo;
        let id = uuid();
        values.estado = "en_espera";
        set(ref(db, "/programas/" + id), values)
          .then(async (_) => {
            await set(ref(db, "/correlativos/programa/"), correlativo);
            resolve(id);
          })
          .catch((error) => {
            reject(error);
          });
      });
  });
};

export const getProgramasAbiertos = async (IDrol) => {
  return new Promise((resolve, reject) => {
    console.log("resolve ");
    const dbRef = ref(realTime);
    let path = "programas";

    get(child(dbRef, path)).then(async (snapshot) => {
      console.log(snapshot.val());
      const registros = snapshot.val();
      if (registros) {
        const registrosList = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          id: key,
        }));
        resolve(registrosList);
      }
      resolve([]);
    });
  });
};

export const getPrograma = async (idPrograma) => {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realTime);
    let path = "programas/" + idPrograma;
    console.log(path);
    get(child(dbRef, path)).then((snapshot) => {
      console.log(snapshot.val());
      const registros = snapshot.val();
      if (snapshot.exists()) {
        resolve(registros);
      } else {
        resolve([]);
      }
    });
  });
};
