import React, { useState, useEffect } from 'react';

import * as PERMISSIONS from "./../../constants/permissions"
import { useSnackbar } from 'notistack';
import { getCurrentUser } from "../../services/auth"
import LoadPage from '../loadPage';
import { Typography } from '@material-ui/core';
import SuperAdminDash from './SuperAdminDash'
import PanelCoordinador from './PanelCoordinador'

import { useSelector, useDispatch } from 'react-redux';
import AcesoAsistentes from '../v3/usuarios/homeAsistente/AcesoAsistentes';
import { useHistory } from 'react-router-dom';
export default function Home(props) {

  const notisnack = useSnackbar();
  const [loadSuperAdmin, setLoadSuperAdmin] = useState(false);
  const [loadCoordinador, setLoadCoordinador] = useState(false);
  const [loadAsisente, setLoadAsisente] = useState(false);
  const { history } = useHistory();

  const { usuarioSesion } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion,
  }));
  const loadData = async () => {
    // let listadoCursos = await getCursosUsuario(true);
    let user = await getCurrentUser();

    console.log(usuarioSesion)
    if (user.claims.email === "javiermalebran@gmail.com") {
      setLoadCoordinador(true)
      window.location = window.location.origin+"/dashmetodo";
    } else if (usuarioSesion?.permisos?.permisos[PERMISSIONS.VER_PANEL_ADMIN]) {
      window.location = window.location.origin+"/dashmetodo";
    } else if (usuarioSesion?.permisos?.permisos[PERMISSIONS.VER_PANEL_COORDINADOR]) {
      window.location = window.location.origin+"/dashmetodo";
    } else {
      setLoadAsisente(true)
      // window.location = window.location.origin+"/programas";
      // history.push("/")
    }
  }
  useEffect(() => {
    console.log("hola mundo ")
    loadData();
  }, []);


  return (

    loadSuperAdmin ?
      <SuperAdminDash /> :
      loadCoordinador ?
        <PanelCoordinador /> :
        loadAsisente ? <AcesoAsistentes /> :
          <Typography variant="h5">Accesos no Autorizado</Typography>

  );
}
