import React, { useEffect } from 'react';

import { getRelatores } from '../../../../services/database/v3/usuario';
import { getSesionActivaCurso } from '../../../../services/database/v3/cursos';


export const useRelatores = () => {
  const [errorRelatores, setError] = React.useState(false)
  const [loadingRelatores, setLoadingRelatores] = React.useState(true)
  const [relatores, setRelatores] = React.useState(null)


  useEffect(() => {
    getRelatores().then(async relatores => {
      console.log(relatores)
      setRelatores(relatores)
      setLoadingRelatores(false)
    }).catch(error => {
      setLoadingRelatores(false)
      setRelatores(null)
    })

  }, [])

  return {
    errorRelatores,
    loadingRelatores,
    relatores,
  }
}

export const useSesionActiva = (idCurso) => {

  const [sesionActia, setSesionActia] = React.useState(null)
  const [loadingSesionActiva, setLoadingSesionActiva] = React.useState(true)


  useEffect(() => {
    console.log("==========================")
    setLoadingSesionActiva(true)
    if (idCurso) {
      console.log("==========================")
      getSesionActivaCurso(idCurso).then(async relatores => {
        console.log(relatores)
        setSesionActia(relatores)
        console.log("==========================")
        setLoadingSesionActiva(false)

      }).catch(error => {
        console.error(error)
      })
    }


  }, [idCurso])

  return {
    sesionActia,
    loadingSesionActiva
  }
}
