import React, { useState, useEffect } from 'react';


import Page from "./page"
import * as Yup from "yup";
import { Formik } from 'formik';
import { Container, Breadcrumbs, Link, Typography, Button } from '@material-ui/core';
import { updateUsuario } from '../../../services/database/usuario'
import { getEmpresas } from '../../../services/database/empresas'
import {
    Close as CloseIcon,
    NavigateNext as NavigateNextIcon
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/styles';
const style = makeStyles(theme => ({

    main: {
        paddingLeft: theme.spacing(2),


    }
}))
export default function CursosAdd(props) {

    const {
        handleCloseModal,
        userSelected
    } = props;

    const classes = style();
    const [formik, setFormik] = useState(null);
    const notisnack = useSnackbar();
    const validationSchema = Yup.object({
        nombre: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
        apellidos: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
        rut: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
        email: Yup.string().email("debe ser un correo valido").required("nombre Requerido").typeError("nombre Requerido"),
        rol: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
        empresa: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
    });
    const [empresas,setEmpresas] =  useState(false);

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const loadData = async () => {
        let listadoEmpresas = await getEmpresas(true);
        userSelected.empresa = userSelected.empresa.nombre;
        setEmpresas(listadoEmpresas)
    }

    useEffect(() => {
        loadData();
    }, []);


    const roles = [
        {
            id: 1,
            nombre: "admin"
        },
        {
            id: 2,
            nombre: "usuario"
        }
    ];

  
    const handleOnSubmit = async (values, formikBag) => {
        updateUsuario(values).then(() => {
            formikBag.resetForm({
                nombre: "",
                apellidos: "",
                rut: "",
                email: "",
                rol: "",
                empresa: ""

            });
            handleCloseModal();
        }).catch((error) => {
            const key = notisnack.enqueueSnackbar("Usuario Ya existe", {
                persist: false,
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                }
            });
            console.log(error);
        });

    }
    const values = {
        nombre: "",
        apellidos: "",
        rut: "",
        email: "",
        rol: "",
        empresa: ""


    };
    const limpiarFormulario = () => {
        if (formik) {
            formik.resetForm(values);
        }
    }


    return (
        <Container maxWidth="xl" className={classes.main}>
            <React.Fragment>
                <Formik
                    ref={(ref) => setFormik(ref)}
                    onSubmit={handleOnSubmit}
                    initialValues={userSelected}
                    validationSchema={validationSchema}
                    onChange={handleChange}
                    render={props =>
                        <Page
                            {...props}
                            limpiarFormulario={limpiarFormulario}
                            empresas={empresas}
                            roles={roles}
                            userSelected={userSelected}
                            handleCloseModal={handleCloseModal}
                            titulo="Ingresar Carta"
                        // validationSchema={validationSchema}
                        />}
                />
            </React.Fragment>
        </Container>

    );
}