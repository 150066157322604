import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Paper,
  Container,
  Divider,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { useCurso, useSesionesCurso } from "./hooks";
import TablaUsuarios from "./../usuarios/TablaUsuarios";
import TablaSesiones from "./TablaSesiones";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AdjustIcon from "@material-ui/icons/Adjust";
import AddIcon from "@material-ui/icons/Add";
import * as XLSX from "xlsx";

import Moment from "moment";
import {
  addSesionCurso,
  getSesionActivaCurso,
  updateCursoEstado,
  getSesionesCurso,
  updateSesionCurso,
} from "../../../services/database/v3/cursos";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    borderRadius: "15px",

    backgroundColor: "rgb(255,255,255,0.5)",
  },
  img: {
    height: 100,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linearProgress: {
    marginLeft: "60vh",
    marginRight: "60vh",
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24,
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10,
  },
  action: {
    flex: "0 0 auto",
    alignSelf: "flex-start",
    marginTop: "auto",
    marginBottom: "auto",
  },
  content: {
    flex: "1 1 auto",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function DetalleCurso(props) {
  const classes = useStyles();
  const history = useHistory();

  const { id, idPrograma } = useParams();

  const [sesiones, setSesiones] = React.useState([]);
  const [loadData, setLoadData] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [reloadSesion, setReloadSesion] = React.useState(false);
  const [openDialogSesion,setOpenDialogSesion] =React.useState(false);

  const { errorCurso, loadingCurso, curso } = useCurso(id, reload);

  console.log(curso);

  const generarViewEstado = (rowData) => {
    if (rowData?.estado === "en_curso") {
      return (
        <Grid
          container
          spacing={1}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <FiberManualRecordIcon style={{ color: "#2e86de" }} />
          </Grid>
          <Grid item>En Curso</Grid>
        </Grid>
      );
    } else if (rowData?.estado === "finalizado") {
      return (
        <Grid
          container
          spacing={1}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <CheckCircleIcon style={{ color: "#10ac84" }} />
          </Grid>
          <Grid item>Finalizado</Grid>
        </Grid>
      );
    } else if (rowData?.estado === "no_visible") {
      return (
        <Grid
          container
          direction="row"
          spacing={1}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <VisibilityOffIcon />
          </Grid>
          <Grid item>
            <Typography style={{ textAlign: "center" }}>No visible</Typography>
          </Grid>
        </Grid>
      );
    } else if (rowData?.estado === "inhabilitado") {
      return (
        <Grid
          container
          spacing={1}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <CheckCircleIcon style={{ color: "#8395a7" }} />
          </Grid>
          <Grid item>
            <Typography style={{ textAlign: "center" }}>
              Curso deshabilitado{" "}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (!rowData?.estado) {
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <AdjustIcon style={{ color: "#95a5a6" }} />
          </Grid>
          <Grid item>
            <Typography style={{ textAlign: "center" }}>
              no publicado
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };
  const publicarCurso = async () => {
    setLoadData(true);
    console.log(curso);
    let sesionAciva = await getSesionActivaCurso(curso.uid);
    console.log(sesionAciva);
    
    if (sesionAciva) {
      await updateCursoEstado("en_curso", idPrograma, id);
    } else {
      setOpenDialogSesion(true);  
    }

    setLoadData(false);
    setReload(!reload);
  };
  const finalizarCurso = async () => {
    setLoadData(true);
    let result = await updateCursoEstado("finalizado", idPrograma, id);
    setLoadData(false);
    setReload(!reload);
  };
  const ocultarCurso = async () => {
    setLoadData(true);
    let result = await updateCursoEstado("no_visible", idPrograma, id);
    setLoadData(false);
    setReload(!reload);
  };
  const deHabilitarCurso = async () => {
    setLoadData(true);
    let result = await updateCursoEstado("inhabilitado", idPrograma, id);
    setLoadData(false);
    setReload(!reload);
  };
  const generarViewIsSence = (isSence) => {
    console.log("Curso es SENCE", isSence);
    return isSence ? "SI" : "NO";
  };

  const guardarSesiones = () => {
    console.log(id, sesiones);
    addSesionCurso(id, sesiones).then((_) => {});
  };

  const handlerExporDataUsaurio = (columns, data) => {
    console.log(columns, data);
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "SheetJS Tutorial",
      Subject: "Test",
      Author: "Red Stapler",
      CreatedDate: new Date(2017, 12, 19),
    };
    let result = data.map((item) => {
      return {
        Nombre: item.nombre,
        Apellidos: item.apellidos,
        Rut: item.rut,
        Cargo: item.cargo,
        Email: item.email,
        "Código Curso": curso.codigo_curso,
        "Código Sence": curso.codigo_sence,
        Curso: curso.nombre_curso,
        "ID Curso": curso.uid,
      };
    });

    let ws = XLSX.utils.json_to_sheet(result);
    XLSX.utils.book_append_sheet(wb, ws, curso.nombre_curso);
    XLSX.writeFile(
      wb,
      curso.nombre_curso +
        "-" +
        curso.descripcion +
        "-" +
        curso.codigo_curso +
        ".xlsx"
    );
  };

  const handleClose = () => {
    setOpenDialogSesion(false);
  };

  return (
    <Fragment>
      {curso && (
        <Container fixed>
          <Paper elevation={3} style={{ borderRadius: 10 }}>
            <Backdrop open={loadData} className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
            <Grid container alignContent="center" alignItems="center">
              <Grid item lg style={{ paddingBottom: 30 }}>
                <Typography className={classes.title} variant="h5">
                  {curso.nombre_curso}
                </Typography>
                <Typography className={classes.subtitle} variant="subtitle1">
                  {curso.descripcion}
                </Typography>
                <Divider />
                <Grid
                  item
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  style={{
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 24,
                    paddingBottom: 24,
                  }}
                  alignItems="center"
                >
                  {/* <Grid item md={3}>
                  <Typography variant="body1">Modulo de mantenedor de plataforma.</Typography>
                </Grid> */}
                  {curso.is_sence && (
                    <Grid item xs={6} md>
                      <Grid
                        item
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <strong>Código SENCE</strong>
                        </Grid>
                        <Grid item>
                          {curso?.codigo_sence ? curso?.codigo_sence : "--"}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {curso.is_sence && (
                    <Grid item xs={6} md>
                      <Grid
                        item
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <strong>Código Curso</strong>
                        </Grid>
                        <Grid item>
                          {curso?.codigo_curso ? curso?.codigo_curso : "--"}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={6} md>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <strong>Fecha Inicio</strong>
                      </Grid>
                      <Grid item>
                        {Moment(curso?.fecha_incio).format("LLL")}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <strong>Fecha Finalización </strong>
                      </Grid>
                      <Grid item>
                        {Moment(curso?.fecha_finalizacion).format("LLL")}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <strong>Curso SENCE</strong>
                      </Grid>
                      <Grid item>{generarViewIsSence(curso.is_sence)}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <strong>Estado</strong>
                      </Grid>
                      <Grid item>{generarViewEstado(curso)}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Typography
                  style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}
                  variant="subtitle1"
                >
                  Estados del Curso/Módulo
                </Typography>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#2e86de", borderRadius: 18 }}
                      color="primary"
                      onClick={() => publicarCurso()}
                    >
                      Publicar Curso/Modulo
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#10ac84", borderRadius: 18 }}
                      color="primary"
                      onClick={() => finalizarCurso()}
                    >
                      Finalizar Curso/Modulo
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#8395a7", borderRadius: 18 }}
                      color="primary"
                      onClick={() => deHabilitarCurso()}
                    >
                      Inabilitar Curso/Modulo
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 18 }}
                      color="primary"
                      onClick={() => ocultarCurso()}
                    >
                      Ocultar Curso/Modulo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={1}
            alignItems="flex-start"
          >
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                spacing={2}
                alignItems="flex-start"
              >
                <Grid item md={12}>
                  <Paper
                    elevation={3}
                    style={{ borderRadius: 10, marginTop: 15 }}
                  >
                    <TablaSesiones idCurso={id} reload={reloadSesion} />
                  </Paper>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Paper
                    elevation={3}
                    style={{ borderRadius: 10, marginTop: 15 }}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item className={classes.content}>
                        <Typography
                          className={classes.title}
                          style={{ margin: 0 }}
                          variant="h6"
                        >
                          Usuarios
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.action}
                        style={{ padding: 8 }}
                      >
                        <Tooltip title="Agregar Curso">
                          <IconButton onClick={() => console.log(true)}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TablaUsuarios
                        idCurso={id}
                        idPrograma={idPrograma}
                        reload={reloadSesion}
                        exportData={true}
                        exportInformacionUsuario={handlerExporDataUsaurio}
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}

      <div>

        <Dialog
          open={openDialogSesion}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Antes de continuar"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Para poder publicar el curso, se debe crear una sesion del curso.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Acpetar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
}

export default DetalleCurso;
