import Axios from "../axios";
import { auth, realTime } from "../../firebase";
import { getDatabase } from "firebase/database";
import {
  ref as fbRef,
  child,
  get,
  set,
  query,
  orderByChild,
  equalTo,
  remove,
  update,
  push,
  
} from "firebase/database";
var generator = require("generate-password");

const dbRef = fbRef(getDatabase());

export const getUsuariosPlataformaActivos = async (estado) => {
  return new Promise((resolve, reject) => {
    get(child(query(dbRef, orderByChild("is_activo", equalTo(estado))))).then(
      async (snapshot) => {
        console.log(snapshot.val());
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));
          resolve(registrosList);
        }
        resolve([]);
      }
    );
  });
};

export const getUsuarioDelPrograma = (idPrograma) => {
  return new Promise((resolve, reject) => {
    console.log("resolve ");
    get(child(dbRef, "programas/" + idPrograma + "/asistentes")).then(
      async (snapshot) => {
        console.log(snapshot.val());
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));
          resolve(registrosList);
        }
        resolve([]);
      }
    );
  });
};
export const getUsuarioSegunRol = async (IDrol) => {
  return new Promise((resolve, reject) => {
    console.log("resolve ");
    get(child(query(dbRef, orderByChild("rol"), equalTo(IDrol)))).then(
      async (snapshot) => {
        console.log(snapshot.val());
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));
          resolve(registrosList);
        }
        resolve([]);
      }
    );
  });
};

export const obtenerRoles = async () => {
  return new Promise((resolve, reject) => {
    console.log("resolve ");
    get(child(dbRef, "roles")).then(async (snapshot) => {
      console.log(snapshot.val());
      const registros = snapshot.val();
      if (registros) {
        const registrosList = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          uid: key,
          count: index + 1,
        }));
        resolve(registrosList);
      }
      resolve([]);
    });
  });
};

export const getUsuariosPorCursoID = async (idCurso, idPrograma) => {
  return new Promise((resolve, reject) => {
    get(child(dbRef, "cursos/" + idCurso + "/asistentes")).then(
      async (snapshot) => {
        let usuarios = await getUsuarioDelPrograma(idPrograma);

        console.log(usuarios);

        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));

          let merge = usuarios.map((user) => {
            let data = registrosList.find((u) => u.uid === user.uid);
            console.log(data);
            if (data) {
              return {
                ...user,
                codErrorInicio: data.codErrorInicio,
                fecha_error: data.fecha_error,
              };
            } else {
              return {
                ...user,
                codErrorInicio: null,
                fecha_error: null,
              };
            }
          });
          console.log(merge);
          resolve(merge);
        } else {
          resolve(usuarios);
        }

        resolve([]);
      }
    );
  });
};

export const getUsuario = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    console.log(`usuarios/${idUsuario}`);
    get(child(dbRef, `usuarios/${idUsuario}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const registros = snapshot.val();
          registros.uid = idUsuario;
          resolve(registros);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(null);
      });
  });
};
export const getCursosUsuario = async (idUsuario) => {
  console.log(idUsuario);
  return new Promise((resolve, reject) => {
    let path = "usuariosCurso/" + idUsuario;
    console.log(path);
    get(child(dbRef, path)).then( async (snapshot) => {
      console.log(snapshot.val());
      const registros = snapshot.val();
      if (registros) {
        let listaProgramas = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          uid: key,
        }));
        resolve(listaProgramas);
      }

      resolve([]);
    });
  });
};

export const addCursoUsuario = async (checked, userSelected) => {
  return new Promise(async (resolve, reject) => {
    console.log(checked, userSelected);

    let error = null;
    checked.forEach(async (element) => {
      delete userSelected["cursos"];
      console.log(userSelected);
      let path = "/cursos/" + element.uid + "/asistentes/" + userSelected.uid;
      set(fbRef(dbRef, path), userSelected).catch((error) => {
        console.log(error);
        error = error;
      });
    });

    var resultadoCursos = [];
    for (var i = 0; i < checked.length; i++) {
      var element = checked[i];
      delete element["asistentes"];
      resultadoCursos.push(element);
    }
    console.log("/usuarios/" + userSelected.uid + "/cursos", resultadoCursos);

    set(
      fbRef(dbRef, "/usuarios/" + userSelected.uid + "/cursos"),
      resultadoCursos
    ).catch((error) => {
      console.log(error);
      error = error;
    });

    if (error) {
      reject(error);
    }
    resolve(resultadoCursos);
  });
};

export const updateUsuario = async (usuario, idPrograma) => {
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuario.nombre,
      apellidos: usuario.apellidos,
      rol: usuario.rol,
      rut: usuario.rut,
      is_activo: true,
      pass: usuario.pass,
      email: usuario.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/updateUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status == 200) {
            let personasRef;
            let cursosRef;
            let personaRef;
         
            
            const db = getDatabase();
            let refAux = fbRef(
              db,
              "programas/" + idPrograma + "/asistentes/" + result.data.usuario
            );

            personasRef = await set(refAux, persona);

            personaRef = await set(
              fbRef(db, "usuarios/" + result.data.usuario),
              persona
            );

            cursosRef = await set(
              fbRef(
                db,
                "usuarios/" + result.data.usuario + "/programas/" + idPrograma
              ),
              { activo: true }
            );
            console.log(personasRef);
            resolve(personasRef);
          }
        } else {
          console.log(result);
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.log(error);
        reject({
          msj: error.toString(),
          data: persona,
        });
      });
  });
};

export const deleteUsuario = async (usuario, idPrograma) => {
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuario.nombre,
      apellidos: usuario.apellidos,
      rol: usuario.rol,
      rut: usuario.rut,
      is_activo: true,
      pass: usuario.pass,
      email: usuario.email,
    };

    let personasRef;
    let cursosRef;
    let personaRef;
    console.log(usuario.uid);
    const db = getDatabase();
    await remove(
      fbRef(db, "/programas/" + idPrograma + "/asistentes/" + usuario.uid)
    );
    await remove(fbRef(db, "/usuarios/" + usuario.uid));
    await remove(
      fbRef(db, "/usuarios/" + usuario.uid + "/programas/" + idPrograma)
    );

    console.log(personasRef);
    resolve(personasRef);

    // Axios.post(`https://us-central1-metodo-aula-v2.cloudfunctions.net/deleteUserAuth`, { usuario: persona }).then(async (result) => {
    //     console.log(result);
    //     if (result) {
    //         console.log(result.status);
    //         if (result.status === 200) {
    //             let personasRef;
    //             let cursosRef;
    //             let personaRef;
    //             console.log(result.data)
    //             personasRef = await realTime.ref("/programas/" + idPrograma + "/asistentes/" + result.data.usuario).remove().then();
    //             personaRef = await realTime.ref("/usuarios/" + result.data.usuario).remove().then();
    //             cursosRef = await realTime.ref("/usuarios/" + result.data.usuario + "/programas/" + idPrograma).remove().then();
    //             console.log(personasRef);
    //             resolve(personasRef);
    //         } else {
    //             reject({ msj: "problemas para ingresar usuario", data: null })
    //         }
    //     } else {
    //         console.error(result)
    //         reject({ msj: "Usuario ya exitente", data: result.data.usuario })
    //     }
    // }).catch(error => {
    //     console.error(error);
    //     reject({ msj: error.response?.data?.msj, data: error.response.data?.usuario })
    // });
  });
};

export const addUsuarioPrograma = async (usuario, idPrograma) => {
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuario.nombre,
      cargo: usuario.cargo,
      apellidos: usuario.apellidos,
      rol: usuario.rol,
      rut: usuario.rut,
      is_activo: true,
      pass: usuario.pass,
      email: usuario.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        console.log(result);
        if (result) {
          console.log(result.status);
          if (result.status === 200) {
            let personasRef;
            let cursosRef;
            let personaRef;
            console.log(result.data);

            if (result.data.msj === "usuario ya exite") {
              update(fbRef(dbRef, "/usuarios/" + result.data.usuario), persona);
            } else {
              update(fbRef(dbRef, "/usuarios/" + result.data.usuario), persona);
            }

            personasRef = await set(
              fbRef(
                dbRef,
                "/programas/" +
                  idPrograma +
                  "/asistentes/" +
                  result.data.usuario
              ),
              persona
            );
            
            cursosRef = await set(fbRef(dbRef,"/usuarios/" + result.data.usuario + "/programas/" + idPrograma),{activo: true})
            console.log(personasRef);
            resolve(personasRef);
          } else {
            reject({ msj: "problemas para ingresar usuario", data: null });
          }
        } else {
          console.error(result);
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.error(error);
        reject({
          msj: error.response?.data?.msj,
          data: error.response.data?.usuario,
        });
      });
  });
};

export const addUsuarioCurso = async (usuarioSuper, curso) => {
  throw new Error("depreciado");
  return new Promise(async (resolve, reject) => {
    reject({reason:"depreciado"});
    var persona = {
      nombre: usuarioSuper.nombre,
      apellidos: usuarioSuper.apellidos,
      rol: usuarioSuper.rol,
      rut: usuarioSuper.rut,
      is_activo: true,
      pass: usuarioSuper.pass,
      email: usuarioSuper.email,
    };
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status == 200) {
            let personasRef;
            let cursosRef;
            console.log(result.data.usuario);
            
            personasRef = await set(fbRef(dbRef, "/cursos/" + curso + "/asistentes/" + result.data.usuario), persona)
            let key  = push(child(dbRef,"/usuarios/" + result.data.usuario + "/cursos")).key
            let insert =[
              "/usuarios/" + result.data.usuario + "/cursos"+key
            ];

            cursosRef = await realTime
              .ref("/usuarios/" + result.data.usuario + "/cursos")
              .push()
              .set({ id: curso })
              .then();
            console.log(personasRef);
            resolve(personasRef);
          }
        } else {
          console.log(result);
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.log(error);
        reject({
          msj: error.response?.data?.msj,
          data: error.response.data?.usuario,
        });
      });
  });
};

export const addSuperUsuario = async (usuarioSuper) => {
  throw new Error("depreciado");
  return new Promise(async (resolve, reject) => {
    var persona = {
      nombre: usuarioSuper.nombre,
      apellidos: usuarioSuper.apellidos,
      rol: usuarioSuper.rol,
      rut: usuarioSuper.rut,
      is_activo: true,
      pass: usuarioSuper.pass,
      email: usuarioSuper.email,
    };
    console.log(persona);
    Axios.post(
      `https://us-central1-metodo-aula-v2.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    )
      .then(async (result) => {
        if (result) {
          if (result.status == 200) {
            let personasRef;
            personasRef = await realTime
              .ref("/usuarios/" + result.data.usuario)
              .set(persona)
              .then();
            console.log(personasRef);
            resolve(personasRef);
          }
        } else {
          console.log(result);
          reject({ msj: "Usuario ya exitente", data: result.data.usuario });
        }
      })
      .catch((error) => {
        console.log(error);
        reject({
          msj: error.response?.data?.msj,
          data: error.response.data?.usuario,
        });
      });
  });
};

export const deleteSuperUsuario = async (idUsuario) => {
  throw new Error("depreciado");
  return new Promise(async (resolve, reject) => {
    realTime
      .ref("/usuarios/" + idUsuario)
      .remove()
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPermisosSegunRol = async (rol) => {
  return new Promise(async (resolve, reject) => {
    var path = "/roles/" + rol;
    get(child(dbRef, path))
      .then((result) => {
        if (result.exists()) {
          resolve(result.val());
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * deprecate
 * @param {*} values
 */
export const addUsuario = async (values) => {
  return new Promise(async (resolve, reject) => {
    var password = values.rut;
    console.log(password);
    var empresa = await realTime
      .ref("empresas")
      .orderByChild("nombre")
      .equalTo(values.empresa)
      .once("value");
    const objEmpresa = Object.keys(empresa.val()).map((key, index) => ({
      ...empresa.val()[key],
      uid: key,
    }));
    var persona = {
      nombre: values.nombre,
      apellidos: values.apellidos,
      rol: values.rol,
      rut: values.rut,
      is_activo: true,
      password: password,
      email: values.email,
      empresa: objEmpresa[0],
    };

    let result = await Axios.post(
      `https://us-central1-ads-aula.cloudfunctions.net/initUserAuth`,
      { usuario: persona }
    ).catch((error) => {
      console.log(error);
    });
    if (result) {
      if (result.status == 200) {
        var personasRef = await realTime
          .ref("/usuarios/" + result.data.usuario)
          .set(persona)
          .then();
        console.log(personasRef);
        resolve(personasRef);
      }
    } else {
      reject("error usuario ya exitente");
    }
  });
};
