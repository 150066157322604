import React, { Fragment, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    CircularProgress,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import MaterialTable, { MTableAction, MTableBody } from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import FormEmpresa from "./formEmpresa/index"
import { Link as LinkIcon, CloudDownload } from '@material-ui/icons';

function Page(props) {
    const classes = style();
    const {
        listadoEmpresas,
        showForm,
        openForm,
        setOpenForm
    } = props;

    const handleCloseModal = ()=>{
        setOpenForm(false)
    }

    return (
        <Fragment>
            <Container >
                <Card style={{ width: "100%" }} className={classes.card}>
                    <CardHeader className={classes.title}
                        title="Empresas"
                        titleTypographyProps={{
                            align: "center",
                            variante: "h4"
                        }}
                        action={
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={showForm}
                                startIcon={<AddIcon />}>
                                Agregar Empresa
                            </Button>
                        }
                    />

                    <MaterialTable
                        title="Listado Usuarios"
                        options={{
                            filtering: true,
                            pageSizeOptions: [20, 30, 40, 50, 60],
                            pageSize: 20,
                            maxBodyHeight: 400,
                            grouping: true,
                            exportButton: false,
                        }}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Buscar",
                                exportName: "Exportar CSV"
                            },

                            pagination: {
                                labelRowsSelect: "Filas",
                                labelDisplayedRows: '{from}-{to} de {count}'
                            },
                            grouping: {
                                placeholder: " Arrastre los encabezados aquí para agrupar "
                            },
                            header: {
                                actions: "Acción"
                            }

                        }}
                        components={{
                            Action: props => {

                                if (props.action.isFreeAction) {
                                    return (
                                        <Tooltip title={props.action.tooltip} aria-label="add">
                                            <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                                                color="primary"
                                                variant="contained"
                                                style={{ textTransform: 'none' }}
                                                size="small">
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                } else {
                                    return (<MTableAction {...props} />);
                                }
                            },
                        }}

                        columns={[
                            {
                                title: 'Nobre',
                                field: 'nombre',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                    width: '3px',
                                },

                            },

                            {
                                title: 'Rut',
                                field: 'rut',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },
                            },
                           

                        ]}
                        data={listadoEmpresas}
                    />
                </Card>
            </Container>
            <Dialog
                open={openForm}
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Reporte Semanal</DialogTitle>
                <DialogContent>
                    <FormEmpresa handleCloseModal={handleCloseModal}></FormEmpresa>
                    
                </DialogContent>
            </Dialog>
        </Fragment >
    );
}

const style = makeStyles(theme => ({
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    // card: {
    //     height: "100%"
    // },
    title: {
        width: "100%",
        color: theme.palette.primary.main,
        backgroundColor: "#FFF"

    },
    cardContainer: {
        minHeight: "76px"
    },
    progress: {
        margin: theme.spacing(2),
    },
    fullwith: {
        width: "100%",
        height: "100%"
    },
    loadDetalles: {
        // height: "100%",
        // width: "100%",
        paddingLeft: "50%"
    },
    button: {
        marginLeft: 'auto',
    },
    appsHeader: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

export default Page;