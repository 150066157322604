import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardActionArea, CardMedia, Grid, Typography, CardContent, CardActions, Chip, Button, Avatar, Box, makeStyles, Divider } from '@material-ui/core';
import ImageFilter from 'react-image-filter';
import moment from 'moment'
import { useSesionActiva } from '../hooks';
import { getSesionActivaCurso } from '../../../../services/database/v3/cursos';
import { getRelator } from '../../../../services/database/v3/usuario';

import tech from '../../../../img/logo_2.jpg';
import { loadConfig } from '../../../../services/database/v3/config';

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: "#fff",
    backgroundColor: "#636e72",
  },

}));

function CursoView(props) {
  const {
    curso, user, idSesionSence,token,rutOtec,lineaCapacitacion
  } = props;

  const classes = useStyles();

  const [fechaFormateada, setFechaFormateada] = useState("")
  const [fechaFormateadaTermino, setFechaFormateadaTermino] = useState("")

  // const { sesionActiva, loadingSesionActiva } = useSesionActiva(curso.uid)
  const [sesionActiva, setSesionActiva] = useState(null)

  const [haIniciadoSesion, setHaIniciadoSesion] = useState(false)
  const [haCerradoSesion, setHaCerradoSesion] = useState(false)
  const [relator, setRelator] = useState(null)

  const [haFinalizadoCurso, setHaFinalizadoCurso] = useState(false)
  const [config,setConfig] = useState(null);

  useEffect(() => {
    const load = async() => {
      let resultConfig  =  await loadConfig()
      setConfig(resultConfig)
      getSesionActivaCurso(curso.uid).then(async sesion => {
        console.log(sesion);
        console.log(curso)
         setSesionActiva(sesion)
        
        if (curso[sesion.uid].cursoFinalizado) {
          setHaCerradoSesion(true);
        } else {
          if (typeof curso[sesion.uid].idSesionSence !== "undefined" && typeof curso[sesion.uid].idSesionSence !== "undefined") {
            setHaIniciadoSesion(true)
            console.log(curso)
            console.log(sesion)
            setSesionActiva(sesion)
          }
        }
      }).catch(error => {
        console.error(error)
      })
    }

    load()
    console.log(curso.url_zoom);
  }, [])

  useEffect(() => {

  }, [sesionActiva])

  useEffect(() => {
    console.log(curso)
    const loadRelator = async () => {
      let relator = await getRelator(curso.relator)
      setRelator(relator)
    }
    loadRelator()
    setFechaFormateada(moment(curso.fecha_inicio).format('LL'));
    setFechaFormateadaTermino(moment(curso.fecha_finalizacion).format('LL'));
  }, [curso])

  const onClickZoom = (result) => {
    console.log(result);
    console.log(sesionActiva)
    if(result.url_zoom!==""){
      window.open(result.url_zoom + "&uname=" + user.nombre + " " + user.apellidos, '_blank');
    }else{
      window.open(result.url_zoom + "&uname=" + user.nombre + " " + user.apellidos, '_blank');
    }
    
  }
  const onClickDeclaracion = () => {
    curso.is_sence ?
      window.location = "https://lce.sence.cl/CertificadoAsistencia/"
      :
      console.log("")
  }
  return (
    <Grid item md={4} >
      {relator &&
        <Card style={{ maxWidth: 345 }} elevation={curso.estado !== "inhabilitado" ? 8 : 1}>

          {curso.estado !== "inhabilitado" ?
            <CardMedia
              component="img"
              height="180"
              image={tech}
              style={{ background: "#007cba" }}
            />
            :
            <CardMedia
              component="img"
              height="180"
              image={tech}
              style={{ background: "#007cba", filter: "grayscale(100%)", opacity: 0.5 }}
            />
          }
          <CardContent>
            <Grid
              container
              direction="column"
              justify="flex-start"
              spacing={1}
              alignItems="stretch">
              <Grid item md={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  {curso.nombre_curso}
                </Typography>
              </Grid>
              <Divider />
              <Grid item md={12} >
                <Grid
                  item
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch">
                  <Grid item>
                    <div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
                      <Avatar alt="Remy Sharp" className={classes.small} >
                        {relator?.nombre[0]}
                      </Avatar>
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                      >
                        <Grid item>
                          <Box fontSize={13} m={1} style={{ marginBottom: 0, marginTop: 0 }}>
                            <Typography variant="caption" display="block" gutterBottom style={{ margin: 0 }}>
                              <strong>Relator</strong>
                            </Typography>

                          </Box>
                        </Grid>
                        <Grid item>
                          <Box fontSize={13} m={1} style={{ marginBottom: 0, marginTop: 0 }}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {relator?.nombre} {relator?.apellidos}
                            </Typography>

                          </Box>
                        </Grid>
                      </Grid>

                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item >
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch">
                  <Grid item >
                    <Typography variant="body2" color="textSecondary" component="p">Fecha Inicio </Typography>
                  </Grid>
                  {/* <Grid item md={1}>
                    <Typography variant="body2" color="textSecondary" component="p">:</Typography>
                  </Grid> */}
                  <Grid item >
                    <Typography variant="body2" color="textSecondary" component="p">{fechaFormateada}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item >
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch">
                  <Grid item >
                    <Typography variant="body2" color="textSecondary" component="p">Fecha Término</Typography>
                  </Grid>
                  {/* <Grid item md={1} alignItems="center">
                    <Typography variant="body2" color="textSecondary" component="p">:</Typography>
                  </Grid> */}
                  <Grid item md={6} container alignItems="center">
                    <Typography variant="body2" color="textSecondary" component="p">{fechaFormateadaTermino}</Typography>
                  </Grid>
                </Grid>
              </Grid>


              <form
                key={"form-logout" + curso.uid}
                id={"logout-sence-" + curso.uid}
                // action="https://sistemas.sence.cl/rcetest/Registro/CerrarSesion"
                action="https://sistemas.sence.cl/rce/Registro/CerrarSesion"
                method="post">
                <input type="hidden" name="RutOtec" value={rutOtec}  />
                <input type="hidden" name="Token" value={token} />
                <input type="hidden" name="CodSence" value={curso.codigo_sence} />
                <input type="hidden" name="CodigoCurso" value={curso.codigo_curso} />
                <input type="hidden" name="LineaCapacitacion" value={lineaCapacitacion} />
                <input type="hidden" name="IdSesionAlumno" value={user?.uid} />
                <input type="hidden" name="IdSesionSence" value={curso.idSesionSence} />
                {/* <input type="hidden" name="UrlError" value={"http://localhost:3000?url=" + window.location.href} /> */}
                <input type="hidden" name="UrlRetoma" value={"https://us-central1-metodo-aula-v2.cloudfunctions.net/redirectCierreSesionDos?url=" + window.location.href + "&id_curso=" + curso.uid} />
                <input type="hidden" name="UrlError" value={"https://us-central1-ads-aula.cloudfunctions.net/redirectCierreSesion?url=" + window.location.href} />
                <input type="hidden" name="RunAlumno" value={user?.rut} />
              </form>

              <form
                key={"form-login" + curso.uid}
                id={"login-sence-" + curso.uid}
                // action="https://sistemas.sence.cl/rcetest/Registro/IniciarSesion"
                action="https://sistemas.sence.cl/rce/Registro/IniciarSesion"
                method="post">
                <input type="hidden" name="RutOtec" value={rutOtec} />
                <input type="hidden" name="Token" value={token} />
                <input type="hidden" name="CodSence" value={curso.codigo_sence} />
                <input type="hidden" name="CodigoCurso" value={curso.codigo_curso} />
                <input type="hidden" name="LineaCapacitacion" value={lineaCapacitacion} />
                <input type="hidden" name="IdSesionAlumno" value={user?.uid} />
                <input type="hidden" name="UrlRetoma" value={"https://us-central1-metodo-aula-v2.cloudfunctions.net/redirectIncioSesion2?url=" + window.location.href + "&id_curso=" + curso.uid} />
                <input type="hidden" name="UrlError" value={"https://us-central1-metodo-aula-v2.cloudfunctions.net/redirectIncioSesionError2?id_curso=" + curso.uid} />
                <input type="hidden" name="RunAlumno" value={user?.rut} />
              </form>

              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch">
                {curso.estado === "finalizado" &&
                  <Grid item md={12} xl={12}>
                    <Button type="submit" style={{ borderRadius: 18, backgroundColor: "#F04E98", color: "#fff", marginBottom: 10 }} onClick={(e) => onClickDeclaracion()} fullWidth >
                      {curso.is_sence ?
                        "Generar declaracion Jurada" :
                        "Curso Finalizado"
                      }

                    </Button>
                  </Grid>
                }
                {curso.estado === "inhabilitado" &&
                  <Grid item md={12}>
                    <Button variant="outlined" style={{ marginBottom: 10 }} color="primary" fullWidth>
                      Módulo no disponible
                </Button>
                  </Grid>
                }
                {curso.estado === "en_curso" && !haIniciadoSesion && !haCerradoSesion && curso.is_sence &&
                  <Grid item md={12}>
                    <Button type="submit" form={"login-sence-" + curso.uid} style={{ borderRadius: 18, backgroundColor: "#00B0A5", color: "#fff", marginBottom: 10 }} target="_blank" color="primary" fullWidth>
                      Registrar Asistencia Con Clave única
                 </Button>
                  </Grid>
                }
                {curso.estado === "en_curso" && !haCerradoSesion && haIniciadoSesion && curso.estado && curso.is_sence &&
                  <Grid item>
                    <Button type="submit" form={"logout-sence-" + curso.uid} style={{ borderRadius: 18, backgroundColor: "#d35400", color: "#fff", marginBottom: 10 }} target="_blank" color="primary" fullWidth>
                      Registrar Salida con Clave única
                  </Button>
                  </Grid>
                }
                {curso.estado === "inhabilitado" || curso.estado === "finalizado" || curso.estado === "en_curso" &&
                  <Grid item md={12}>
                    <Button color="primary" style={{ borderRadius: 18 }} onClick={(e) => onClickZoom(sesionActiva)} variant="contained" color="primary" fullWidth>
                      Entrar a Salon del curso
                  </Button>
                  </Grid>
                }
                {
                  curso.is_sence &&
                  <Grid container item md={12} alignItems="center">
                    <Typography variant="caption" style={{ textAlign: "center" }} display="block" gutterBottom>
                      Cod. Curso :{curso.codigo_curso}
                    </Typography>
                  </Grid>
                }

              </Grid>

            </Grid>
          </CardContent>

          <CardActions>
          </CardActions>
        </Card>
      }
    </Grid>
  )
}
export default CursoView;