import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography,
    ListSubheader
} from '@material-ui/core/';
import {

    BarChart,
    ListAlt,
    Person,
    Business,
    LaptopChromebook,
    VpnKey
} from '@material-ui/icons/';
import * as PERMISSIONS from '../../constants/permissions';
import * as ROUTES from '../../constants/routes';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import * as URLS from '../../constants/urls';

const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: 26,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerMargin: {
        marginTop: "48px"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: "hidden",
        width: theme.spacing(0) + 1,
        // width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {

        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }
}));

export default function MainDrawer(props) {

    const { open, handleClose, usuarioSesion } = props;

    const [openCollapseLogs, setOpenCollapseLogs] = useState(false);
    const [openCollapsePrograma, setOpenCollapsePrograma] = useState(false);

    const classes = useStyles();

    return (
        <Fragment>
            <Drawer
                variant="permanent"
                anchor="left"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.drawerMargin,
                        {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                }}
                onClose={handleClose}
                open={open}>
                <Divider />
                <List>
                    <ListSubheader>Menu</ListSubheader>
                    <ListItem button component={Link} to={ROUTES.LANDING}  >
                        <ListItemIcon >
                            <LaptopChromebook />
                        </ListItemIcon>
                        <ListItemText primary="Mis Cursos" />
                    </ListItem>
                    <Divider />
                    {usuarioSesion && usuarioSesion.rol && usuarioSesion.rol === "admin" &&
                        <ListSubheader>Administración</ListSubheader>
                    }
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.AGREGAR_PERSONAS_CURSO] &&
                        <ListItem button component={Link} to={ROUTES.USUARIOS} onClick={handleClose}>
                            <ListItemIcon>
                                <Person></Person>
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" />
                        </ListItem>}
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.AGREGAR_EMPRESA] &&
                        <ListItem button component={Link} to={ROUTES.EMPRESAS} onClick={handleClose}>
                            <ListItemIcon>
                                <Business></Business>
                            </ListItemIcon>
                            <ListItemText primary="Empresas" />
                        </ListItem>}
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.AGREGAR_CREAR_CURSO] &&
                        <ListItem button component={Link} to={ROUTES.CURSOS} onClick={handleClose}>
                            <ListItemIcon>
                                <LaptopChromebook></LaptopChromebook>
                            </ListItemIcon>
                            <ListItemText primary="Cursos" />
                        </ListItem>}
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.AGREGAR_CREAR_CURSO] &&
                        <ListItem button component={Link} to={ROUTES.PERMISOS} onClick={handleClose}>
                            <ListItemIcon>
                                <VpnKey></VpnKey>
                            </ListItemIcon>
                            <ListItemText primary="Ver" />
                        </ListItem>

                    }



                </List>

            </Drawer>

        </Fragment>
    );
}