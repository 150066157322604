import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Container, Divider, Button, Tooltip, IconButton, Chip, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableAction } from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';
import Moment from 'react-moment';
import AdjustIcon from '@material-ui/icons/Adjust';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import MenuDropdown from '../../../components/menuDropDown';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));

const ITEM_HEIGHT = 48;
function TablaPrograma(props) {

  const {
    listadoProgramas,
    loading,
    showModalEliminar,
    showModalListadoUsuarios,
    showModalAddCurso,
    onCambiarEstadoPrograma,
    showModalListaCursos,
    showModalAsistencia
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const history = useHistory();

  const generaViewEstado = (estado) => {
    if (!estado) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center">
          <Grid item>
            <AdjustIcon style={{ color: "#95a5a6" }} />
          </Grid>
          <Grid item>
            En espera
          </Grid>

        </Grid>)

    } else {
      if (estado === "en_curso") {
        return (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item>
              <FiberManualRecordIcon style={{ color: "#2980b9" }} />
            </Grid>
            <Grid item>
              En Curso
            </Grid>

          </Grid>)

      } else if (estado === "finalizado") {
        return (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item>
              <CheckCircleIcon style={{ color: "#27ae60" }} />
            </Grid>
            <Grid item>
              Finalizado
            </Grid>
          </Grid>)

      } else if (estado === "cancelado") {
        return (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item>
              <ErrorIcon style={{ color: "#c0392b" }} />
            </Grid>
            <Grid item>
              Cancelado
            </Grid>
          </Grid>)
      }
    }
  }

  function generarFechaView(fecha_termino) {


    var fechaView = new Date(fecha_termino);
    console.log(fecha_termino)

    if (fecha_termino === "") {
      return "indefinido";
    } else {
      return <Moment format="DD/MM/YYYY">{fecha_termino}</Moment>
    }
    // if(fechaView.getFullYear)

  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);


  const navegarAPrograma=(idPrograma)=>{
    history.push("programas/"+idPrograma)
  }



  return (
    <Fragment>
      <Container >
        <MaterialTable
          title="Listado Cursos"
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
          options={{
            filtering: true,
            pageSizeOptions: [20, 30, 40, 50, 60],
            pageSize: 20,
            // maxBodyHeight: 400,
            showTitle: false,
            grouping: true,
            exportButton: false,
            
          }}
          localization={{
            toolbar: {
              searchPlaceholder: "Buscar",
              exportName: "Exportar CSV"
            },

            pagination: {
              labelRowsSelect: "Filas",
              labelDisplayedRows: '{from}-{to} de {count}'
            },
            grouping: {
              placeholder: " Arrastre los encabezados aquí para agrupar "
            },
            header: {
              actions: "Acción"
            }

          }}
        
          components={{
            Action: props => {

              if (props.action.isFreeAction) {
                return (
                  <Tooltip title={props.action.tooltip} aria-label="add">
                    <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                      color="primary"
                      variant="contained"
                      style={{ textTransform: 'none' }}
                      size="small">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                )
              } else {
                return (<MTableAction {...props} />);
              }
            },
          }}

          columns={[
            {
              title: 'Código',
              field: 'codigo',
              defaultSort:"desc",
              cellStyle: {
                // fontSize: '0.8em'
                width: '3px',
              },
              render: rowData => <Chip label={rowData.codigo} variant="outlined" />

            },

            {
              title: 'Nombre del programa',
              field: 'nombre_programa',
              cellStyle: {
                // fontSize: '0.8em'
              },
            },
            {
              title: 'Cantidad de Horas',
              field: 'nombre_programa',
              cellStyle: {
                // fontSize: '0.8em'
              },
            },
            {
              title: 'Fecha Inicio',
              field: 'fechaInicio',
              cellStyle: {
                // fontSize: '0.8em'
              },
              render: rowData => generarFechaView(rowData.fecha_inicio),

            },

            {
              title: 'Fecha término',
              field: 'fechaFinal',
              cellStyle: {
                // fontSize: '0.8em'
              },
              render: rowData => generarFechaView(rowData.fecha_finalización),
            },
            {
              title: 'Estado',
              field: 'estado',
              cellStyle: {
                // fontSize: '0.8em'

              },
              render: rowData => generaViewEstado(rowData.estado),
            },
            {
              title: 'Acción',
              filed: 'id',
              render: rowData => {
                const accionesConfiguracion = [];
                accionesConfiguracion.push({
                  content: 'Ver Programa',
                  onClick: (e) => navegarAPrograma(rowData.id)
                });
                accionesConfiguracion.push({
                  content: 'Eliminar Programa',
                  onClick: (e) => showModalEliminar(rowData.id)
                });
                accionesConfiguracion.push({
                  content: 'Ver Listado Cursos',
                  onClick: (e) => showModalListaCursos(rowData)
                });
                
               
                
                
                return (<div className={classes.tableActions}>
                  {accionesConfiguracion.length > 0 &&
                    <MenuDropdown
                      icon={
                        <MoreVertIcon />
                      }
                      tooltip="Modulos/Cursos"
                      items={accionesConfiguracion}
                    />
                  }
                </div>);
              }
            }

          ]}
          isLoading={loading}
          data={listadoProgramas ?? []}
        />
      </Container>
    </Fragment>
  );
}

export default TablaPrograma;
