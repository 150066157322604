export const ACCESS_ERROR = '/accessError';
export const CURSOS = '/cursos'
export const DETALLE_CURSOS = '/cursos/:id'
export const ENTRAR_A_CURSOS = '/entrar/:id'
export const CONFIG_CURSOS = '/cursosConf'

export const EMPRESAS = '/empresas'
export const DETALLE_EMPRESAS = '/empresas/:id'

export const USUARIOS = '/usuario'
export const DETALLE_USUARIOS = '/usuario/:id'

export const PERMISOS = "/permisos"

export const ASISTENCIA = "/asistencia/:idCurso/:idSesion"

export const LANDING = '/';
export const LOGIN = '/login';
export const LOGIN_ADMIN = '/admin';
export const POLITICAS = '/politicas'

export const CREAR_CURSO = '/crear-curso'
export const CREAR_PROGRAMA = '/programa/crear'
export const PROGRAMA = '/programa'




export const ERROR_SENCE = '/error/:codigo'


export const PDF = '/pdf';


//V3
export const PROGRAMAS = '/programas'
export const DETALLE_PROGRAMAS = '/programas/:idPrograma'
export const SESIONES_CURSO = '/cursos/sesiones/:id/:idPrograma'
export const DASH_METODO = '/dashmetodo'

