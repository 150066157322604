import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Button,
  Container,
  DialogActions,
  Grid,
  TextField,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useProgramas } from "./hooks";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
import {
  AddCursoPrograma,
  addSesionCurso,
} from "../../../services/database/v3/cursos";
import { useRelatores } from "../usuarios/hooks";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  main: {
    // marginBottom: '50px',
    // paddingLeft: theme.spacing(9),
    // paddingTop: theme.spacing(11)
  },
  formControl: {
    // paddingTop: theme.spacing(1)
  },
  dialogPaper: {
    overflow: "visible",
  },
  hidden: {
    display: "none",
  },
  textField: {},
  buttonSuccess: {
    backgroundColor: "#223440",
    "&:hover": {
      backgroundColor: "#223440",
    },
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: "#223440",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
  },
  subtitle: {
    paddingBottom: 10,
  },
}));

function FormularioSesiones(props) {
  const classes = useStyles();

  const { onSaveSesion, idCurso } = props;

  const [loadingSave, setLoadingSave] = React.useState(false);
  const notisnack = useSnackbar();
  const [formik] = React.useState(null);

  const handleChange = (e, { name, value }) => {
    formik.setFieldValue(name, value);
    e.persist();
  };
  const dateChange = (e) => {
    formik.setFieldValue(e);
    e.persist();
  };
  const values = {
    nombre: "",
    isActiva: false,
    fecha_inicio: Moment(),
    fecha_finalizacion: Moment(),
    url_zoom: "",
  };
  const validationSchemaSesion = Yup.object({});

  const handleOnSubmit = async (values, formikBag) => {
    setLoadingSave(true);
    console.log(values);
    addSesionCurso(values, idCurso)
      .then((result) => {
        onSaveSesion(result);
        setLoadingSave(false);
      })
      .catch((error) => {
        setLoadingSave(false);
        console.error(error);
      });
  };

  const buttonClassname = clsx({
    // [classes.buttonSuccess]: success,
  });

  return (
    <Fragment>
      <Container fixed>
        <Grid container alignContent="flex-start" alignItems="flex-start">
          <Grid item lg style={{ paddingBottom: 30 }}>
            <Typography className={classes.title} variant="h5">
              Agregar Sesion
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Ingrese la información requeridad en caso de no tener informacion
              solo omita el campo
            </Typography>
            <Divider />
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              style={{ paddingRight: 5, paddingLeft: 5, paddingTop: 24 }}
              alignItems="center"
            >
              <Grid item>
                <Formik
                  onSubmit={handleOnSubmit}
                  initialValues={values}
                  validationSchema={validationSchemaSesion}
                  onChange={handleChange}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    /* and other goodies */
                  }) => (
                    <Grid container spacing={3}>
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          spacing={1}
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} lg={12}>
                            <TextField
                              name="nombre"
                              label="Nombre Curso"
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputLabel,
                                  shrink: "shrink",
                                },
                              }}
                              className={classes.textField}
                              value={values.nombre}
                              error={touched.nombre && Boolean(errors.nombre)}
                              margin="normal"
                              variant="outlined"
                              fullWidth
                              multiline
                              size="small"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <TextField
                              name="url_zoom"
                              label="Url Zoom"
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputLabel,
                                  shrink: "shrink",
                                },
                              }}
                              className={classes.textField}
                              value={values.url_zoom}
                              error={
                                touched.url_zoom && Boolean(errors.url_zoom)
                              }
                              margin="normal"
                              variant="outlined"
                              fullWidth
                              multiline
                              size="small"
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={6} lg={6}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <DateTimePicker
                                id="fecha_inicio"
                                name={`fecha_inicio`}
                                label="Fecha de Inicio"
                                autoOk
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    root: classes.inputLabel,
                                    shrink: "shrink",
                                  },
                                }}
                                margin="normal"
                                className={classes.textField}
                                helperText={
                                  touched.fecha_inicio && errors.fecha_inicio
                                    ? errors.fecha_inicio
                                    : ""
                                }
                                error={Boolean(errors.fecha_inicio)}
                                value={values.fecha_inicio}
                                inputVariant="outlined"
                                size="small"
                                format="dd/MM/yyyy HH:mm"
                                // InputAdornmentProps={{ position: "start" }}
                                onChange={(e) => {
                                  setFieldValue("fecha_inicio", e.toString()); // Access it from props
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <DateTimePicker
                                id="fecha_finalizacion"
                                name={`fecha_finalizacion`}
                                label="Fecha de finalización"
                                autoOk
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    root: classes.inputLabel,
                                    shrink: "shrink",
                                  },
                                }}
                                margin="normal"
                                className={classes.textField}
                                helperText={
                                  touched.fecha_finalizacion &&
                                  errors.fecha_finalizacion
                                    ? errors.fecha_finalizacion
                                    : ""
                                }
                                error={Boolean(errors.fecha_finalizacion)}
                                value={values.fecha_finalizacion}
                                inputVariant="outlined"
                                size="small"
                                format="dd/MM/yyyy HH:mm"
                                // InputAdornmentProps={{ position: "start" }}
                                onChange={(e) => {
                                  setFieldValue(
                                    "fecha_finalizacion",
                                    e.toString()
                                  ); // Access it from props
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>

                        <Grid>
                          <div className={classes.wrapper}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={buttonClassname}
                              disabled={!isValid || loadingSave}
                            >
                              Agregar Sesion
                            </Button>
                            {loadingSave && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>
                        </Grid>
                      </form>
                    </Grid>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default FormularioSesiones;
