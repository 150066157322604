import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { auth, onAuthStateChange } from './services/firebase';

import { accionSetUsuario } from './redux/actions/dataUsuario/index';

import Error401 from './containers/401';
import LoadPage from './containers/loadPage';
import LoginPage from './containers/loginPage';
import LoginPageAdmin from './containers/loginPageAdmin';

import RedirectExternal from './containers/redirectExternal';
import Home2 from './containers/home2'

import Usuarios from './containers/usuario'
import Empresas from './containers/empresas'
import CursosDos from './containers/cursos2'
import ErrorSence from './containers/errorSence'
import Asistencia from './containers/v3/usuarios/asistencia'

import MainDrawer from './components/mainDrawer';
import AppBar from './components/appBar';
import DialogoMensaje from './components/dialogoMensaje';
import Politicas from './containers/politicas'
import CrearCurso from './containers/cursos2/agregarCurso'

import CrearPrograma from './containers/programa/agregarPrograma'
import Programa from './containers/programa'

//v3
import Programas from './containers/v3/programa'
import DetallePrograma from './containers/v3/programa/detalle'
import DetalleCurso from './containers/v3/cursos'
import DashBoardMetodo from './containers/v3/usuarios'


import * as ROUTES from './constants/routes';
import * as PERMISSIONS from './constants/permissions';
import { getCurrentUser, getCanPermisos, getPermisos } from './services/auth'

import { CssBaseline } from '@material-ui/core';
import { getPermisosSegunRol, getUsuario } from './services/database/usuario';




const drawerWidth = 300;
const appbarHeight = 18;
const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  main: {
    display: 'flex',
    // width: "100%"
  },
  root: {
    display: 'flex',
  },
  content: {
    // display: 'flex',
    padding: theme.spacing(11, 1, 3, 1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#dddddd",
    minHeight: `calc(100vh - ${appbarHeight}px)`,
    width: "100vw",
  },
  contentUnShift: {
    width: "100vw",
  },
  contentShift: {
    width: `calc(100vw - ${drawerWidth}px)`,
  },
  // drawerHeader: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     padding: '0 8px',
  //     ...theme.mixins.toolbar,
  //     justifyContent: 'flex-end',
  // }
}));

export default function App(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usuarioSesion } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion,
  }));

  const [loading, setLoading] = useState(true);
  const [accessError, setAccessError] = useState(false);
  const [login, setLogin] = useState(false);
  const [openMainDrawer, setOpenMainDrawer] = useState(false);

  const privateRoute = ({ component: Component, permission, path, ...rest }) => {

    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          if (accessError) return (<Redirect to='/accessError' />);
          if (!accessError && path === ROUTES.ACCESS_ERROR) return (<Redirect to='/' />);
          if (login) return (<Redirect to='/login' />);
          if ((permission && usuarioSesion && (!usuarioSesion.permisos || !usuarioSesion.permisos.permisos[permission]))) {
            return withTemplate(<Redirect to='/' />);
          }
          return withTemplate(<Component {...props} />);
        }}
      />

    );
  }

  const handleOpenMainDrawer = () => {
    setOpenMainDrawer(true);
  }

  const handleCloseMainDrawer = () => {
    setOpenMainDrawer(false);
  }

  const checkSession = async (isLogged) => {
    try {
       console.log("isLogged", isLogged)
      if (isLogged.loggedIn) {
        const user = auth.currentUser;
        const cliam = await getCurrentUser();
        console.log(cliam.claims);
        // const permisos = await getPermisos(cliam.claims.rol);
        const rolUsuario = await getUsuario(cliam.claims.user_id)
        const permisos = await getPermisosSegunRol(cliam.claims.rol);
        dispatch(accionSetUsuario({
          ref: user._id,
          photoUrl: user.photoURL,
          nombre: user.displayName,
          email: user.email,
          rol: cliam.claims.rol,
          permisos: permisos
        }));
        setLoading(false);

      } else {
        setLogin(true);
      }
    } catch (error) {
       console.log(error);
      setLogin(true);
    }
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChange(checkSession);

  }, []);





  const withTemplate = (component) => (
    <div className={classes.root}>
      {!loading &&
        <main className={classes.main}>
          {(!login && !accessError) && <AppBar
            nombreModulo="Aula Virtual"
            usuarioSesion={usuarioSesion}
            openMainDrawer={openMainDrawer}
            handleOpenMainDrawer={handleOpenMainDrawer}
            handleCloseMainDrawer={handleCloseMainDrawer}
          />}
          {(!login && !accessError) && <MainDrawer
            usuarioSesion={usuarioSesion}
            open={openMainDrawer}
            handleClose={handleCloseMainDrawer}
          />}
          <div name="Content"
            className={clsx(
              {
                [classes.content]: true,
                [classes.contentUnShift]: !openMainDrawer,
                [classes.contentShift]: openMainDrawer,
              })}>
            {component}
          </div>
        </main>
      }
      {loading &&
        <LoadPage />
      }
    </div>
  );
  const mainApp = (
    <div>
      <CssBaseline />
      <Switch>
        <Route exact={true} path={ROUTES.LOGIN} component={() => <LoginPage setLogin={setLogin} login={login} />} />
        <Route exact={true} path={ROUTES.LOGIN_ADMIN} component={() => <LoginPageAdmin setLogin={setLogin} login={login} />} />
        <Route exact={true} path={ROUTES.POLITICAS} component={() => <Politicas />} />


        {/* v3 */}
        {privateRoute({
          component: Programas,
          path: ROUTES.PROGRAMAS,
          exact: true
        })}
        {privateRoute({
          component: DetallePrograma,
          path: ROUTES.DETALLE_PROGRAMAS,
          exact: true
        })}
        {privateRoute({
          component: DetalleCurso,
          path: ROUTES.SESIONES_CURSO,
          exact: true
        })}
        {privateRoute({
          component: DashBoardMetodo,
          path: ROUTES.DASH_METODO,
          exact: true
        })}


        
        {/* fin v3 */}
        {privateRoute({
          component: Error401,
          path: ROUTES.ACCESS_ERROR,
          exact: true
        })}
        {privateRoute({
          component: Home2,
          path: ROUTES.LANDING,
          exact: true
        })}
        {privateRoute({
          component: CrearCurso,
          path: ROUTES.CREAR_CURSO,
          exact: true
        })}
        {privateRoute({
          component: CrearPrograma,
          path: ROUTES.CREAR_PROGRAMA,
          exact: true
        })}
        {privateRoute({
          component: Programa,
          path: ROUTES.PROGRAMA,
          exact: true
        })}
        {privateRoute({
          component: CursosDos,
          path: ROUTES.CURSOS,
          exact: true
        })}
        {privateRoute({
          component: Usuarios,
          permission: PERMISSIONS.AGREGAR_PERSONAS,
          path: ROUTES.USUARIOS,
          exact: true
        })}
        {privateRoute({
          component: Empresas,
          path: ROUTES.EMPRESAS,
          exact: true
        })}
        {privateRoute({
          component: Asistencia,
          path: ROUTES.ASISTENCIA,
          exact: true
        })}
        {privateRoute({
          component: ErrorSence,
          path: ROUTES.ERROR_SENCE,
          exact: true
        })}



      </Switch>
      <DialogoMensaje />
    </div>

  );
  return (mainApp);

}