import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button, Dialog, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import FormularioCurso from '../../cursos/FormularioCurso';
import TablaCursos from '../../cursos/TablaCursos';
import { usePrograma } from '../hooks';
import TablaUsuarios from '../../usuarios/TablaUsuarios';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  },
  action: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: -8,
    marginRight: -8,
  },
  content: {
    flex: '1 1 auto',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ color: "#fff" }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon color="#fff" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function DetallePrograma(props) {
  const classes = useStyles();
  const history = useHistory();
  const [openAgregarCurso, setOpenAgregarCurso] = React.useState(false);

  const [openShowListaUsuario, setOpenShowListaUsuario] = React.useState(false);
  const { idPrograma } = useParams();
  const [programaId, setProgramaId] = React.useState(null);
  const [curosId, setCursoID] = React.useState(null);

  const { programa } = usePrograma(idPrograma)
  const handlerCloseDialogAgregarCurso = (event) => {
    setOpenAgregarCurso(false);
  }

  useEffect(() => {
    if (idPrograma === "metodo") {

    }
    setProgramaId(idPrograma)

  }, [])

  const handlerSaveCurso = (id) => {
    // history.push("/programas/" + id)
    setProgramaId(null)
    setOpenAgregarCurso(false)
    setProgramaId(idPrograma)
  }

  const handleClose = () => {
    setOpenShowListaUsuario(false);
    // setReload(!reload)

  };

  const handleclickAddUsuarios = (idCurso) => {
    setCursoID(idCurso)
    setOpenShowListaUsuario(true);
  }

  const handleRedirectSesiones = (idCurso) => {
    history.push("/cursos/sesiones/" + idCurso + "/" + idPrograma)
  }

  const getEstadoPrograma = (estado) => {
    if (estado === "en_espera") {
      return "En espera"
    }
  }

  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 10 }} xs={12} md={12}>
          <Grid container alignContent="center" alignItems="center">
            <Grid item style={{ paddingBottom: 30 }} xs={12} md={12}>
              {idPrograma === "metodo" ? <div>
                <Typography className={classes.title} variant="h5">Cursos de Metodo</Typography>
                <Typography className={classes.subtitle} variant="subtitle1">Listado de cursos creados en Metodo</Typography>
              </div>
                :
                <div>
                  <Typography className={classes.title} variant="h5">{programa?.codigo}</Typography>
                  <Typography className={classes.subtitle} variant="subtitle1">{programa?.nombre_programa}</Typography>
                </div>
              }


              <Divider />
              {idPrograma !== "metodo" &&
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                  alignItems="center">
                  {/* <Grid item md={3}>
                  <Typography variant="body1">Modulo de mantenedor de plataforma.</Typography>
                </Grid> */}
                  <Grid item md={3} xs={6}>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start">
                      <Grid item ><strong>Descripción</strong></Grid>
                      <Grid item >{programa?.descripcion_programa}</Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={2} xs={6}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start">
                      <Grid item ><strong>Cliente</strong></Grid>
                      <Grid item >{programa?.cliente}</Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={2} xs={6}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start">
                      <Grid item ><strong>Fecha Inicio</strong></Grid>
                      <Grid item >{Moment(programa?.fecha_incio).format('LLL')}</Grid>
                    </Grid>

                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start">
                      <Grid item ><strong>Fecha Finalización </strong></Grid>
                      <Grid item >{Moment(programa?.fecha_finalizacion).format('LLL')}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start">
                      <Grid item ><strong>Cantidad Horas</strong></Grid>
                      <Grid item >{programa?.cantidad_horas}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={1} xs={6}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start">
                      <Grid item ><strong>Estado</strong></Grid>
                      <Grid item >{getEstadoPrograma(programa?.estado)}</Grid>
                    </Grid>
                  </Grid>

                </Grid>
              }
              <Grid
                item
                xs={12}
                md={12}
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                {/* <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => console.log("hola mundo")}>
                    Agregar Usuario
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => setOpenAgregarCurso(true)}>
                    Agregar Curso
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => setOpenAgregarCurso(true)}>
                    Agregar Grupos de paricipantes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={3} style={{ borderRadius: 10, marginTop: 15 }} >
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid item className={classes.content}>
              <Typography className={classes.title} style={{ margin: 0 }} variant="h6">Cursos</Typography>
            </Grid>
            <Grid item className={classes.action} style={{ padding: 8 }}  >
              <Tooltip title="Agregar Curso">
                <IconButton onClick={() => setOpenAgregarCurso(true)} >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TablaCursos onClickAddUsuarios={handleclickAddUsuarios} onClickRedirectSesiones={handleRedirectSesiones} idPrograma={idPrograma}></TablaCursos>
          </Grid>
        </Paper>


      </Container>

      <Dialog
        fullWidth={true}
        onClose={handlerCloseDialogAgregarCurso}
        style={{ borderRadius: 15 }}
        maxWidth={"md"}
        PaperProps={{ style: { borderRadius: 15 } }}
        open={openAgregarCurso}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handlerCloseDialogAgregarCurso} style={{ background: '#253746' }}>
          Crear Programa
        </DialogTitle>
        <DialogContent style={{ background: "none!importantb", boxShadow: "none" }}>
          <FormularioCurso onSaveCurso={handlerSaveCurso} idPrograma={idPrograma}></FormularioCurso>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth={true} onClose={handleClose} style={{ borderRadius: 15 }} maxWidth={"md"} open={openShowListaUsuario} aria-labelledby="form-dialog-title">
        <DialogContent style={{ background: "none!importantb", borderRadius: 15, boxShadow: "none" }}>
          <TablaUsuarios idPrograma={idPrograma} idCurso={curosId} />
        </DialogContent>
      </Dialog>
    </Fragment >
  );
}

export default DetallePrograma;
