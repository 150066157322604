import React, { useState, useEffect } from 'react';
import Page from './page';
import { useSnackbar } from 'notistack';
import { getUsuariosPlataformaActivos } from '../../services/database/usuario'

export default function Usuario(props) {

    const notisnack = useSnackbar();

    const [openForm, setOpenForm] = useState(false);
    const [openAsignarUsuario, setOpenAsignarUsuario] = useState(false);
    const [openEditarUsuario, setOpenEditarUsuario] = useState(false);
    const [openDeleteUsuario, setOpenDeleteUsuario] = useState(false);
    const [listadoUsuariosActivos, setListadoUsuariosActivos] = useState([]);

    const loadData = async () => {
        let listadoUsuario = await getUsuariosPlataformaActivos(true);
        console.log(listadoUsuario)
        setListadoUsuariosActivos(listadoUsuario)
    }
    useEffect(() => {
        loadData();
    }, []);
    const showForm = () => {
        setOpenForm(true);
    }
    useEffect(() => {
        loadData();
    }, [openForm,openAsignarUsuario,openEditarUsuario,openDeleteUsuario]);

    const onOpenAsignarUsuario = (data) => {
        //console.log(data)
    }

    
    return (
        <Page
            listadoUsuario={listadoUsuariosActivos}
            showForm={showForm}
            setOpenForm={setOpenForm}
            setOpenAsignarUsuario={setOpenAsignarUsuario}
            openAsignarUsuario={openAsignarUsuario}
            onOpenAsignarUsuario={onOpenAsignarUsuario}
            openForm={openForm}
            openEditarUsuario={openEditarUsuario}
            setOpenEditarUsuario={setOpenEditarUsuario}
            openDeleteUsuario={openDeleteUsuario}
            setOpenDeleteUsuario={setOpenDeleteUsuario}
        />
    );
}
