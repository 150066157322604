import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Container, Divider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const logo = require('../../img/logo.png');
const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));
function SuperAdminDash(props) {
  const classes = useStyles();
  const history = useHistory();
  const [openAgregarSuper, setOpenAgregarSuper] = React.useState(false);

  const handlerCloseDialogAgregarSuper = (event) => {
    setOpenAgregarSuper(false);
  }
  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid container alignContent="center" alignItems="center">
            <Grid item lg style={{ paddingBottom: 30 }} >
              <Typography className={classes.title} variant="h5">Listado de Cur</Typography>
              <Typography className={classes.subtitle} variant="subtitle1">Modulo de mantenedor de plataforma, uso exlusivo para super usuario</Typography>
              <Divider />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => setOpenAgregarSuper(true)}>
                    Asignar Usuario sistema
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => history.push("/programa")}>
                    Agregar Programa
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => history.push("/crear-curso")}>
                    Crear Curso
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Agregar Relator
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Paper>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid container alignContent="center" alignItems="center">
            {/* <LitadoProgramas /> */}

          </Grid>
        </Paper>
      </Container>
    </Fragment>
  );
}

export default SuperAdminDash;
