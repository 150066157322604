import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormularioCurso from '../cursos/FormularioCurso'
import { withStyles } from '@material-ui/core/styles';
import TablaPrograma from './TablaPrograma';
import { useProgramas } from './hooks';
import FormularioPrograma from './FormularioPrograma';
import FormularioSuperUsuario from '../usuarios/FormularioSuperUsuario';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ color: "#fff" }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon color="#fff" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
function Programas(props) {
  const classes = useStyles();
  const history = useHistory();
  const [openAgregarPrograma, setOpenAgregarPrograma] = React.useState(false);
  const [openAgregarSuper, setOpenAgregarSuper] = React.useState(false);

  const [reload, setReload] = React.useState(false)
  const [idProgramaSelect, setIdProgramaSelect] = React.useState(null)
  const [programasParaTabla, setProgramasParaTabla] = React.useState(null)

  const { loadingPrograma, programas } = useProgramas(reload);

  const handlerCloseDialogAgregarPrograma = (event) => {
    setOpenAgregarPrograma(false);
  }


  useEffect(() => {

    if (programas != null) {
      setProgramasParaTabla(programas)
    }
  }, [programas])

  const showModalAddPrograma = (data) => {
    setOpenAgregarPrograma(true);

  }

  const handlerCloseDialogAgregarSuper = (id) => {
    setOpenAgregarSuper(false)
  }

  const handlerSavePrograma = (id) => {
    // history.push("/programas/"+id)
    setOpenAgregarPrograma(false)
    setReload(!reload)
  }

  const cambiarEstadoProgramaHandle = (estado, id) => {
    // cambiarEstadoPrograma(estado, id).then(result => {
    //   setReload(!reload)
    // })

  }
  const handleShowModalDelete = (id) => {

  }



  return (
    <Fragment>
      <Container spacing={1}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}>

          <Grid item md={12}>
            <Paper item elevation={3} style={{ borderRadius: 15 }}>
              <Grid container alignContent="center" alignItems="center">
                <Grid  style={{ paddingBottom: 30 }} >
                  <Typography className={classes.title} variant="h5">Bienvenido a Aula Método</Typography>
                  
                  <Divider />
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                    alignItems="center">
                    <Grid item>
                      <Button variant="outlined" color="primary" onClick={() => showModalAddPrograma()}>
                        Crear Programa
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" onClick={() => setOpenAgregarSuper(true)}>
                        Agregar Relator
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Paper item elevation={3} style={{ borderRadius: 15 }}>
              <Grid container alignContent="center" alignItems="center">
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={1}>
                  <Typography className={classes.title} variant="h5">Listado de Programas</Typography>
                  <Divider />
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                    alignItems="center">

                    <TablaPrograma listadoProgramas={programasParaTabla} loading={loadingPrograma}
                      showModalEliminar={handleShowModalDelete}
                      // showModalListadoUsuarios={showModalListadoUsuarios}
                      // showModalAddCurso={showModalAddCurso}
                      // showModalListaCursos={showModalListaCursos}
                      // showModalAsistencia={showModalAsistencia}
                      onCambiarEstadoPrograma={cambiarEstadoProgramaHandle} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        fullWidth={true}
        onClose={handlerCloseDialogAgregarPrograma}
        style={{ borderRadius: 15 }}
        maxWidth={"md"}
        PaperProps={{ style: { borderRadius: 15 } }}
        open={openAgregarPrograma}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handlerCloseDialogAgregarPrograma} style={{ background: '#253746' }}>
          Crear Curso
        </DialogTitle>
        <DialogContent style={{ background: "none!importantb", boxShadow: "none" }}>
          <FormularioPrograma onSavePrograma={handlerSavePrograma} />
        </DialogContent>
      </Dialog>

      <FormularioSuperUsuario openAgregarSuper={openAgregarSuper} onCloseModal={handlerCloseDialogAgregarSuper} />
    </Fragment >
  );
}

export default Programas;
