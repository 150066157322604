import { auth, realTime } from "../../firebase";
import uuid from "react-uuid";
import moment from "moment";
import { getRelator } from "./usuario";
import Moment from "react-moment";
import {
  ref,
  child,
  get,
  set,
  update,
  query,
  orderByChild,
  equalTo,
  limitToFirst,
  getDatabase,
} from "firebase/database";

/**
 * @param {usuario id } idUsuario
 */
export const getCurso = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    get(child(ref(db), "cursos/" + idUsuario)).then(async (snapshot) => {
      const registros = snapshot.val();
      if (registros) {
        registros.uid = idUsuario;
        resolve(registros);
      }
      resolve([]);
    });
  });
};

export const getSesionActivaCurso = async (idCurso) => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    get(
      query(
        ref(db, "cursosSesiones/" + idCurso),
        orderByChild("isActiva"),
        equalTo(true),
        limitToFirst(1)
      )
    ).then((sesion) => {
      if (sesion.val()) {
        const registrosList = Object.keys(sesion.val()).map((key, index) => ({
          ...sesion.val()[key],
          uid: key,
          count: index + 1,
        }));
        
        if (registrosList.length > 0) {
          resolve(registrosList[0]);
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  });
};

export const AddCursoPrograma = async (values, idPrograma) => {
  return new Promise(async (resolve, reject) => {
    values.fecha_finalizacion = moment(values.fecha_finalizacion).format();
    values.fecha_inicio = moment(values.fecha_inicio).format();
    const db = getDatabase();
    let programa = await get(child(ref(db), "programas/" + idPrograma));

    let id = uuid();
    await set(child(ref(db), "cursos/" + id), values);
    await set(
      child(ref(db), "programasCursos/" + idPrograma + "/" + id),
      values
    );
    await set(
      child(ref(db), "cursosPrograma/" + id + "/" + idPrograma),
      programa.val()
    );

    resolve(id);
  });
};

export const addSesionCurso = async (sesion, idCurso) => {
  return new Promise(async (resolve, reject) => {
    let id = uuid();
    sesion.fecha_inicio = moment(sesion.fecha_inicio).format();
    sesion.fecha_finalizacion = moment(sesion.fecha_finalizacion).format();

    let pathSesion = "sesiones/" + id;
    let pathCursoSesion = "cursosSesiones/" + idCurso + "/" + id;

    await set(ref(getDatabase(), pathSesion), sesion);
    await set(ref(getDatabase(), pathCursoSesion), sesion);

    resolve(id);
  });
};

export const deleteSesionCurso = async (idSesion, idCurso) => {
  return new Promise(async (resolve, reject) => {
    let pathSesion = "sesiones/" + idSesion;
    let pathCursoSesion = "cursosSesiones/" + idCurso + "/" + idSesion;
    const db = getDatabase();
    let updatedCursoData = {};
    updatedCursoData[pathSesion] = null;
    updatedCursoData[pathCursoSesion] = null;
    update(ref(db), updatedCursoData);

    resolve(true);
  });
};

export const getUsuariosDeUnCurso = (idCurso) => {
  return new Promise(async (resolve, reject) => {
    let path = `cursosUsuarios/${idCurso}`;
    const db = getDatabase();
    get(child(ref(db), path))
      .then((snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));
          resolve(registrosList);
        }
        resolve([]);
      })
      .catch((error) => {
        reject([]);
      });
  });
};

export const getSesionesCurso = (idCurso) => {
  return new Promise(async (resolve, reject) => {
    let path = `cursosSesiones/${idCurso}`;
    const db = getDatabase();
    get(child(ref(db), path)).then(async (snapshot) => {
      const registros = snapshot.val();
      if (registros) {
        const registrosList = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          uid: key,
          count: index + 1,
        }));
        resolve(registrosList);
      }
      resolve([]);
    });
  });
};

export const updateSesionCurso = (activa, paraActivar, idCurso) => {
  return new Promise(async (resolve, reject) => {
    console.log("para activar", activa);
    const db = getDatabase();
    if (activa !== null) {
      let pathsesion = "sesiones/" + activa + "/isActiva";
      let pathcursoSesion =
        "cursosSesiones/" + idCurso + "/" + activa + "/isActiva";

      set(ref(db, pathsesion), false)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });

      set(ref(db, pathcursoSesion), false)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    }

    let pathSesion = "sesiones/" + paraActivar + "/isActiva";
    set(ref(db, pathSesion), true)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });

    let pathCursoSesion =
      "cursosSesiones/" + idCurso + "/" + paraActivar + "/isActiva";
    set(ref(db, pathCursoSesion), true)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
    resolve();
  });
};
export const getCursosDeUnPrograma = async (idPrograma) => {
  return new Promise(async (resolve, reject) => {
    let path = `programasCursos/${idPrograma}`;
    console.log(path);
    const db = getDatabase();

    get(child(ref(db), path)).then(async (snapshot) => {
      const registros = snapshot.val();
      if (registros) {
        const registrosList = Object.keys(registros).map((key, index) => ({
          ...registros[key],
          id: key,
          count: index + 1,
        }));
        let mapeoDeResultados = [];
        registrosList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.fecha_inicio) - new Date(a.fecha_inicio);
        });
        for (var i = 0; i < registrosList.length; i++) {
          let item = registrosList[i];
          let relator = await getRelator(item.relator);
          relator.nombre_completo = relator.nombre + " " + relator.apellidos;
          item.relator = relator;
          mapeoDeResultados.push(item);
        }
        resolve(mapeoDeResultados);
      }
      resolve([]);
    });
  });
};

export const deleteCurso = (data_p, idPrograma) => {
  return new Promise(async (resolve, reject) => {
    var updatedCursoData = {};
    updatedCursoData["cursos/" + data_p.id] = null;
    updatedCursoData["programasCursos/" + idPrograma + "/" + data_p.id] = null;
    updatedCursoData["cursosPrograma/" + data_p.id + "/" + idPrograma] = null;
    const db = getDatabase();
    get(child(ref(db), "usuariosCurso")).then((snap) => {
      let usuariosKey = Object.keys(snap.val());
      console.log(usuariosKey);
      usuariosKey.forEach((key) => {
        updatedCursoData[`usuariosCurso/${key}/${data_p.id}`] = null;
      });
      update(ref(db), updatedCursoData);
    });

    resolve();
  });
};

export const updateCursoEstado = (estado, idPrograma, idCurso) => {
  return new Promise(async (resolve, reject) => {
    var updatedCursoData = {};
    updatedCursoData["cursos/" + idCurso + "/estado"] = estado;
    updatedCursoData[
      "programasCursos/" + idPrograma + "/" + idCurso + "/estado"
    ] = estado;
    updatedCursoData[
      "cursosPrograma/" + idCurso + "/" + idPrograma + "/estado"
    ] = estado;
    const db = getDatabase();
    get(child(ref(db), "cursosUsuarios/" + idCurso)).then((cursos) => {
      if (cursos.val()) {
        let usuariosKey = Object.keys(cursos.val());
        usuariosKey.forEach((key) => {
          updatedCursoData[`usuariosCurso/${key}/${idCurso}/estado`] = estado;
        });
      }
      update(ref(db), updatedCursoData);
      resolve();
    });
  });
};

export const updateCurso = (data_p, idPrograma, idCurso) => {
  let dataUpdate = {
    codigo_curso: data_p.codigo_curso,
    codigo_sence: data_p.codigo_sence,
    descripcion: data_p.descripcion,
    fecha_finalizacion:
      data_p.fecha_finalizacion ?? Moment(data_p?.fecha_finalizacion).format(),
    fecha_inicio: data_p.fecha_inicio ?? Moment(data_p?.fecha_inicio).format(),
    is_sence: data_p.is_sence,
    nombre_curso: data_p.nombre_curso,
    relator: data_p.relator.id,
    url_zoom: data_p.url_zoom,
    estado: data_p.estado,
  };

  return new Promise(async (resolve, reject) => {
    var updatedCursoData = {};
    updatedCursoData["cursos/" + data_p.id] = dataUpdate;
    updatedCursoData["programasCursos/" + idPrograma + "/" + data_p.id] =
      dataUpdate;
    updatedCursoData["cursosPrograma/" + data_p.id + "/" + idPrograma] =
      dataUpdate;
    const db = getDatabase();
    get(child(ref(db), "cursosUsuarios/" + data_p.id)).then((cursos) => {
      if (cursos.val()) {
        let usuariosKey = Object.keys(cursos.val());
        usuariosKey.forEach((key) => {
          updatedCursoData[`usuariosCurso/${key}/${data_p.id}`] = dataUpdate;
        });
      }
      update(ref(db), updatedCursoData);
      resolve();
    });
  });
};
