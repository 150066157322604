import { combineReducers } from 'redux';

import reducerDialogoMensaje from './reducerDialogoMensaje';
import usuarioSesion from './reducerDataUsuario';

const rootReducer = combineReducers({
    dialogoMensaje: reducerDialogoMensaje,
    usuarioSesion: usuarioSesion,
    
});


export default rootReducer;