import React, { Fragment, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import MaterialTable, { MTableAction, MTableBody } from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Link as LinkIcon, CloudDownload } from '@material-ui/icons';

function Page(props) {
  const classes = style();
  const {
    listadoUsuario,
    idSesion,
    idCurso

  } = props;
  const getMensajeError = (cod) => {
    let listado =
      [
        {
          codigo: "100", msj: "Contraseña incorrecta o el usuario no tiene Clave SENCE."
        },
        {
          codigo: "200", msj: "El POST tiene uno o más parámetros mandatorios sin información. Esto también ocurre cuando un parámetro está mal escrito(por ejemplo, RutAlumno en lugar de RunAlumno), o cuando se ingresan sólo espacios en blanco en un parámetro obligatorio."

        },
        {
          codigo: "201", msj: " La URL de Retoma y/o URL de Error no tienen información. Ambos parámetros son obligatorios en todos los POST."
        },
        {
          codigo: "202", msj: "La URL de Retoma tiene formato incorrecto."
        },
        {
          codigo: "203", msj: "La URL de Error tiene formato incorrecto."
        },
        {
          codigo: "204", msj: " El Código SENCE tiene menos de 10 caracteres y/o no es código válido."
        },
        {
          codigo: "205", msj: "El Código Curso tiene menos de 7 caracteres y/o no es código válido."
        },
        {
          codigo: "206", msj: "La línea de capacitación es incorrecta."
        },
        {
          codigo: "207", msj: "El Run Alumno tiene formato incorrecto, o tiene el dígito verificador incorrecto."
        },
        {
          codigo: "208", msj: "El Run Alumno no está autorizado para realizar el curso."
        },
        {
          codigo: "209", msj: "El Rut OTEC tiene formato incorrecto, o tiene el dígito verificador incorrecto."
        },
        {
          codigo: "210", msj: "Expiró el tiempo disponible para el ingreso de RUT y Contraseña. El tiempo disponible es de tres minutos."
        },
        {
          codigo: "211", msj: "El Token no pertenece al OTEC."
        },
        {
          codigo: "212", msj: "El Token no está vigente."
        },
        {
          codigo: "300", msj: "Error interno no clasificado, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles."
        },
        {
          codigo: "301", msj: "No se pudo registrar el ingreso o cierre de sesión. Esto ocurre cuando la Línea de Capacitación es incorrecta, o el Código de Curso es incorrecto."
        },
        {
          codigo: "302", msj: "No se pudo validar la información del Organismo, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles"
        },
        {
          codigo: "303", msj: "El Token no existe, o su formato es incorrecto."
        },
        {
          codigo: "304", msj: "No se pudieron verificar los datos enviados, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles."
        },
        {
          codigo: "305", msj: " No se pudo registrar la información, se debe reportar al SENCE con la mayor cantidad de antecedentes disponibles."
        },
        {
          codigo: "306", msj: "El Código Curso no corresponde al Código SENCE."
        },
        {
          codigo: "307", msj: "El Código Curso no tiene modalidad E-Learning"
        },
        {
          codigo: "308", msj: "El Código Curso no corresponde al RUT OTEC."
        },
        {
          codigo: "309", msj: "Las fechas de ejecución comunicadas para el Código Curso no corresponden a la fecha actual"
        },
        {
          codigo: "310", msj: "El Código Curso está en estado Terminado o Anulado."
        },
        {
          codigo: "311", msj: "Run ingresado en el Login de Clave Única no corresponde con Run alumno informadopor el ejecutor."
        }

      ]
    console.log(cod)
    let mensaje = listado.find(e => {
      return e.codigo === cod;
    });
    console.log(mensaje)

    return mensaje.msj;
  }




  const [userSelected, setUserSelected] = useState(null);
  return (
    <Fragment>
      <Container >
        <Card style={{ width: "100%" }} className={classes.card}>
          <CardHeader className={classes.title}
            title=""
            titleTypographyProps={{
              align: "center",
              variante: "h4"
            }}
          />

          <MaterialTable
            title="Registro de Asistencia"
            options={{
              filtering: true,
              pageSizeOptions: [20, 30, 40, 50, 60],
              pageSize: 20,
              grouping: false,
              exportButton: false,
              actionsColumnIndex: -1,
            }}


            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
                exportName: "Exportar CSV"
              },

              pagination: {
                labelRowsSelect: "Filas",
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              grouping: {
                placeholder: " Arrastre los encabezados aquí para agrupar "
              },
              header: {
                actions: "Acción"
              }

            }}
            components={{
              Action: props => {

                if (props.action.isFreeAction) {
                  return (
                    <Tooltip title={props.action.tooltip} aria-label="add">
                      <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                        color="primary"
                        variant="contained"
                        style={{ textTransform: 'none' }}
                        size="small">
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )
                } else {
                  return (<MTableAction {...props} />);
                }
              },
            }}

            columns={[
              {
                title: 'Mombre',
                field: 'nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  width: '3px',
                },

              },

              {
                title: 'Apellidos',
                field: 'apellidos',
                cellStyle: {
                  // fontSize: '0.8em'
                },
              },
              {
                title: 'Email',
                field: 'email',
                cellStyle: {
                  // fontSize: '0.8em'
                },

              },
              {
                title: 'RUT',
                field: 'rut',
                cellStyle: {
                  // fontSize: '0.8em'
                },

              },

              {
                title: 'Inicio Sesión SENCE',
                field: 'empresa.nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  textAlign: "center"
                },
                render: rowData => {
                  if (rowData[`${idSesion}`]?.idSesionSence) {
                    return (
                      <DoneIcon style={{ color: "#27ae60" }}></DoneIcon>
                    )
                  } else {
                    return (
                      <ClearIcon style={{ color: "#e74c3c" }} />
                    )
                  }

                }

              },
              {
                title: 'Cierre Sesión SENCE',
                field: 'empresa.nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  textAlign: "center"
                },
                render: rowData => {
                  if (rowData[`${idSesion}`]?.cursoFinalizado) {
                    return (
                      <DoneIcon style={{ color: "#27ae60" }}></DoneIcon>
                    )
                  } else {
                    return (
                      <ClearIcon style={{ color: "#e74c3c" }} />
                    )
                  }

                }
              },
              {
                title: 'Código Error SENCE',
                field: 'empresa.nombre',
                cellStyle: {
                  // fontSize: '0.8em'
                  textAlign: "center"
                },
                render: rowData => {
                  if (rowData[`${idSesion}`]?.codErrorInicio) {
                    return getMensajeError(rowData[`${idSesion}`]?.codErrorInicio)
                  } else {
                    return "--"
                  }
                }
              },

            ]}
            data={listadoUsuario}
          />
        </Card>
      </Container>

    </Fragment >
  );
}

const style = makeStyles(theme => ({
  main: {
    overflow: "auto",
    maxWidth: "95vw"
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  // card: {
  //     height: "100%"
  // },
  title: {
    width: "100%",
    color: theme.palette.primary.main,
    backgroundColor: "#FFF"

  },
  cardContainer: {
    minHeight: "76px"
  },
  progress: {
    margin: theme.spacing(2),
  },
  fullwith: {
    width: "100%",
    height: "100%"
  },
  loadDetalles: {
    // height: "100%",
    // width: "100%",
    paddingLeft: "50%"
  },
  button: {
    marginLeft: 'auto',
  },
  appsHeader: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main
  },
  card: {
    marginBottom: theme.spacing(2)
  }
}));

export default Page;