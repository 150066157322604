import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  linearProgress: {
    marginLeft: '60vh', marginRight: '60vh'
  },
  title: {
    margin: theme.spacing(3, 0, 0, 0),
    paddingRight: 24,
    paddingLeft: 24
  },
  subtitle: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 10
  }
}));
function AgregarSesiones(props) {

  const {
    onSaveNumSessiones
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openAgregarSuper, setOpenAgregarSuper] = React.useState(false);

  const handlerCloseDialogAgregarSuper = (event) => {
    setOpenAgregarSuper(false);
  }

  const saveSesiones = () => {
    onSaveNumSessiones()
  }
  return (
    <Fragment>
      <Container fixed>
        <Paper elevation={3} style={{ borderRadius: 15 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">

          </Grid>
          <Grid container alignContent="center" alignItems="center">
            <Grid item lg style={{ paddingBottom: 30 }} >
              <Typography className={classes.title} variant="h5">Agregar Sesiones</Typography>
              <Typography className={classes.subtitle} variant="subtitle1">Ingrese el numero de sesiones que tendra el curso</Typography>
              <Divider />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                style={{ paddingRight: 24, paddingLeft: 24, paddingTop: 24 }}
                alignItems="center">
                <Grid item>
                  <TextField
                    id="outlined-number"
                    label="Number"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={saveSesiones}>
                    Guradar Sesiones
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Container>
    </Fragment>
  );
}

export default AgregarSesiones;
