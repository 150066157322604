import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
import { getCursos } from '../../../services/database/cursos'
import { addCursoUsuario } from '../../../services/database/usuario'

import moment from 'moment'

import {
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    FormHelperText,
    DialogActions,
    Grid,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
    InputLabel
} from '@material-ui/core';


import 'moment/locale/es';
moment.locale('es');

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CheckboxListSecondary(props) {
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [listadoCursos, setListadoCursos] = useState([]);
    const notisnack = useSnackbar();
    const {
        userSelected,
        handleCloseModalAsignar
    } = props;

    const loadData = async () => {
        let listadoCursos = await getCursos(true);
        console.log(listadoCursos)

        let check = [];
        listadoCursos.map((value) => {
            let cursoSelect = userSelected?.cursos?.find(cursos => cursos.uid === value.uid);
            let select = (typeof cursoSelect !== "undefined") ? true : false;
            console.log(select);
            if (select) {
                const currentIndex = checked.indexOf(value);
                const newChecked = [...checked];
                if (currentIndex === -1) {
                    newChecked.push(value);
                } else {
                    newChecked.splice(currentIndex, 1);
                }
                check.push(value);
                //setChecked(newChecked);
            }
        })
        console.log(check)
        console.log(checked)

        setChecked(check);
        setListadoCursos(listadoCursos)

    }
    useEffect(() => {
        loadData();
    }, []);



    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const asignarCursoUsuario = () => {
        addCursoUsuario(checked, userSelected).then(() => {
            
            handleCloseModalAsignar();
        }).catch((error) => {
            const key = notisnack.enqueueSnackbar("Problemas para hacer la asignación", {
                persist: false,
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                }
            });
            handleCloseModalAsignar();
        });
    }

    return (
        <Container maxWidth="xl" className={classes.main}>
            <List dense className={classes.root}>
                {listadoCursos.map((value,index) => {
                    const labelId = `checkbox-list-secondary-label-${value.uid}`;
                    moment.locale('es');
                    let fechaFormateada =  moment(value.fechaInicio).format('LLL');
                    return (
                        <ListItem key={index} button>
                            <ListItemText id={labelId} primary={value.nombre} secondary={fechaFormateada}/>
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(value)}
                                    checked={checked.indexOf(value) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
            <DialogActions>

                <Button
                    color="primary"
                    onClick={handleCloseModalAsignar}>
                    Cancelar
                    </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => asignarCursoUsuario()}
                // disabled={!isValid}
                >
                    Asignar
                </Button>

            </DialogActions>
        </Container>
    );
}