import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormularioPrograma from './FormularioPrograma'
import { Container, Grid } from '@material-ui/core';
import AgregarUsuarios from './AgregarUsuarios';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Ingresar Información del Programa', 'Agregar Participantes del programa'];
}


export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [idPrograma, setIdPrograma] = React.useState(null);
  const steps = getSteps();
  


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handlerSavePrograma = (id) => {
    console.log("cursoAgregados")
    setIdPrograma(id)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
  const handleSaveSesiones = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const getStepContent = (stepIndex) => {
    console.log(idPrograma);
    switch (stepIndex) {
      case 0:
        return <FormularioPrograma onSavePrograma={handlerSavePrograma} />;
      case 1:
        return <AgregarUsuarios idPrograma={idPrograma} />
      case 2:
        return "paso tres"

      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
            <Container fixed>
              {getStepContent(activeStep, idPrograma)}
              <Grid
                container
                style={{ paddingRight: 15 }}
                direction="row"
                justify="flex-end"
                alignItems="center">
                <Grid item>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}>
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Continuar'}
                    </Button>
                  </div>
                </Grid>
              </Grid>

            </Container>
          )}
      </div>
    </div>
  );
}
