import Axios from "../axios";
import { auth, realTime } from '../../firebase';
import uuid from 'react-uuid'



export const getEmpresas = async (estado) => {
    return new Promise((resolve, reject) => {
        console.log("resolve ");
        realTime.ref("empresas").orderByChild("is_activo").equalTo(estado).once("value", async snapshot => {
            console.log(snapshot.val());
            const registros = snapshot.val();
            if (registros) {
                const registrosList = Object.keys(registros).map((key, index) => ({
                    ...registros[key],
                    uid: key,
                    count: (index + 1)
                }))
                resolve(registrosList);
            }
            resolve([]);

        });
    });
}

export const addEmpresa = async (values) => {
    return new Promise(async (resolve, reject) => {

        var empresa = {
            nombre: values.nombre,
            rut: values.rut,
            is_activo: true,
        }
        let id = uuid()
        var empresaRef = await realTime.ref("/empresas/" + id).set(empresa).then();
        console.log(empresaRef);
        resolve(empresaRef);


    });
}
