import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Paper, Container, Divider, Button, Avatar, Box, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { useCursosDelPrograma } from './hooks';
import MenuDropdown from '../../../components/menuDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { updateUsuario } from '../../../services/database/usuario';
import { updateCurso, deleteCurso, updateCursoEstado, getCursosDeUnPrograma } from '../../../services/database/v3/cursos';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AdjustIcon from '@material-ui/icons/Adjust';
import * as XLSX from 'xlsx'

function TablaCursos(props) {
  const {
    idPrograma,
    onClickAddUsuarios,
    onClickRedirectSesiones
  } = props;

  const [reload, setReload] = React.useState(false);
  const { loadingCursos, cursos } = useCursosDelPrograma(idPrograma, reload);
  console.log(cursos)
  const history = useHistory();

  const [data, setData] = React.useState([]);

  useEffect(() => {
    setData(cursos)
  }, [cursos, reload])

  const loadCursos = () => {
    getCursosDeUnPrograma(idPrograma).then(result => {
      setData(result)
    }).catch(error => {
      console.log(error);
    })
  }

  const publicarCurso = async (idCurso) => {
    let result = await updateCursoEstado("en_curso", idPrograma, idCurso)
    loadCursos()
  }
  const finalizarCurso = async (idCurso) => {
    let result = await updateCursoEstado("finalizado", idPrograma, idCurso)
    loadCursos()
  }
  const ocultarCurso = async (idCurso) => {
    let result = await updateCursoEstado("no_visible", idPrograma, idCurso)
    loadCursos()
  }

  const deHabilitarCurso = async (idCurso) => {
    let result = await updateCursoEstado("inhabilitado", idPrograma, idCurso)
    loadCursos()
  }






  const [columns, setColumns] = React.useState([
    {
      title: 'Menu',
      filed: 'id',
      render: rowData => {
        const accionesConfiguracion = [];
        accionesConfiguracion.push({
          content: 'Ver participantes',
          onClick: (e) => onClickAddUsuarios(rowData.id)
        });
        accionesConfiguracion.push({
          content: 'Ver detalles del Curso/Módulo',
          onClick: (e) => onClickRedirectSesiones(rowData.id)
        });

        return (<div >
          {accionesConfiguracion.length > 0 &&
            <MenuDropdown
              icon={
                <MoreVertIcon />
              }
              tooltip="Modulos/Cursos"
              items={accionesConfiguracion}
            />
          }
        </div>);
      }
    },
    { title: 'Nombre Curso', field: 'nombre_curso' },
    { title: 'Descripción', field: 'descripcion' },
    {
      title: 'Relator', field: 'relator.nombre_completo', editable: "onAdd", render: rowData => {
        return (
          <div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
            <Avatar alt="Remy Sharp"  >
              {rowData.relator.nombre_completo[0]}
            </Avatar>
            <Box fontSize={13} m={1}>
              {rowData.relator.nombre_completo}
            </Box>
          </div>
        )
      }
    },
    // {
    //   title: 'Link Zoom', field: 'url_zoom', render: rowData => {
    //     console.log(rowData.url_zoom)
    //     if (rowData.url_zoom === "") {
    //       return (
    //         <Chip label="No existe link" component="a" color="primary"  disabled />
    //       )
    //     } else {
    //       return (
    //         <Chip label="Link Zoom" component="a" color="primary" href={rowData.url_zoom} clickable />
    //       )
    //     }
    //   }
    // },
    { title: 'Fecha Inicio', field: 'fecha_inicio', type: 'datetime' },
    { title: 'Fecha Finalización', field: 'fecha_finalizacion', type: 'datetime' },
    { title: 'Es Curso SENCE', field: 'is_sence', lookup: { true: 'Si', false: 'No' } },
    {
      title: 'Estado', field: 'estado', editable: "onAdd",
      render: rowData => {
        console.log(rowData)
        if (rowData.estado === "en_curso") {
          return <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item>
              <FiberManualRecordIcon style={{ color: "#2980b9" }} />
            </Grid>
            <Grid item>
              En Curso
          </Grid>
          </Grid>
        } else if (rowData.estado === "finalizado") {
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <CheckCircleIcon style={{ color: "#27ae60" }} />
              </Grid>
              <Grid item>
                Finalizado
              </Grid>
            </Grid>)

        } else if (rowData.estado === "no_visible") {
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <VisibilityOffIcon />
              </Grid>
              <Grid item>
                <Typography style={{ textAlign: "center" }}>No visible para el usuario</Typography>
              </Grid>
            </Grid>)

        }
        else if (rowData.estado === "inhabilitado") {
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <CheckCircleIcon style={{ color: "#f1c40f" }} />
              </Grid>
              <Grid item>
                <Typography style={{ textAlign: "center" }}>Curso visible pero no habilitado</Typography>
              </Grid>
            </Grid>)

        } else if (!rowData.estado) {
          return <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item>
              <AdjustIcon style={{ color: "#95a5a6" }} />
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center" }}>no publicado</Typography>
            </Grid>
          </Grid>
        }
      }
    },
    { title: 'Codigo Curso SENCE', field: 'codigo_curso' },
    { title: 'Codigo SENCE', field: 'codigo_sence' },
   
   
  ]);



  useEffect(() => {
    setData(cursos)
  }, [cursos])


  const deleteCursoRow = (oldData) => {

    return new Promise(async (resolve, reject) => {
      let result = await deleteCurso(oldData, idPrograma)
      const dataDelete = [...data];
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      setData([...dataDelete]);

      resolve();

    })
  }

  const updateCursoRow = (data_p, oldData) => {
    console.log(data_p, oldData)
    return new Promise(async (resolve, reject) => {
      let result = await updateCurso(data_p, idPrograma, oldData.id)
      const dataUpdate = [...data];
      const index = oldData.tableData.id;
      dataUpdate[index] = data_p;
      console.log(dataUpdate)
      setData([...dataUpdate]);
      // setReload(!reload)
      resolve(data_p);


    })
  }


  const verAsistencia = (id) => {
    history.push("asistencia/" + idPrograma + "/" + id)
  }
  return <Fragment>
    <div className="container">
      <MaterialTable
        title=""
        components={{
          Container: props => <Paper {...props} elevation={0} />
        }}
        options={{
          paging: false,
          actionsColumnIndex: 0,
          doubleHorizontalScroll: true,
          maxBodyHeight: "100%"

        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Buscar",
            exportName: "Exportar CSV"
          },
          pagination: {
            labelRowsSelect: "Filas",
            labelDisplayedRows: '{from}-{to} de {count}'
          },
          grouping: {
            placeholder: " Arrastre los encabezados aquí para agrupar "
          },
          header: {
            actions: "Acción"
          },
          body: { editRow: { deleteText: "Realmente quiere eliminar al usuario" } }

        }}
        editable={{
          onRowUpdate: updateCursoRow,
          onRowDelete: deleteCursoRow
        }}
        isLoading={loadingCursos}
        columns={columns}
        data={data ?? []}
      />
    </div>

  </Fragment>
}
export default TablaCursos;