import React, { useState, useEffect } from 'react';


import Page from "./page"
import * as Yup from "yup";
import { Formik } from 'formik';
import { Container, Breadcrumbs, Link, Typography, Button } from '@material-ui/core';
import { addEmpresa } from '../../../services/database/empresas'
import {
    Close as CloseIcon,
    NavigateNext as NavigateNextIcon
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/styles';
const style = makeStyles(theme => ({

    main: {
        paddingLeft: theme.spacing(2),


    }
}))
export default function FormEmpresas(props) {

    const {
        handleCloseModal
    } = props;

    const classes = style();
    const [formik, setFormik] = useState(null);
    const notisnack = useSnackbar();
    const validationSchema = Yup.object({
        nombre: Yup.string().required("Nombre Requerido").typeError("Nombre Requerido"),
        rut: Yup.string().required("Rut Requerido").typeError("Rut Requerido"),


    });
    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };


    const handleOnSubmit = async (values, formikBag) => {

        addEmpresa(values).then(() => {
            formikBag.resetForm({
                nombre:"",
                rut: "",
                is_activo:true
            });
            handleCloseModal();
        }).catch((error) => {
            const key = notisnack.enqueueSnackbar("Empresa Ya existe", {
                persist: false,
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                }
            });
            console.log(error);
        });

    }
    const values = {
        nombre:"",
        rut: "",
    };
    const limpiarFormulario = () => {
        if (formik) {
            formik.resetForm(values);
        }
    }


    return (
        <Container maxWidth="xl" className={classes.main}>
            <React.Fragment>
                <Formik
                    ref={(ref) => setFormik(ref)}
                    onSubmit={handleOnSubmit}
                    initialValues={values}
                    validationSchema={validationSchema}
                    onChange={handleChange}
                    render={props =>
                        <Page
                            {...props}
                            limpiarFormulario={limpiarFormulario}
                            handleCloseModal={handleCloseModal}
                            titulo="Ingresar Carta"
                        // validationSchema={validationSchema}
                        />}
                />
            </React.Fragment>
        </Container>

    );
}